import { Col, Image, Nav, NavDropdown, Navbar } from "react-bootstrap"
import { Link, NavLink, useNavigate } from "react-router-dom"
import { NavItems } from "./NavItems"
import "./sidebar.scss"
import Logo from "../../../assets/images/logo-white.svg"

import LogoSmall from "../../../assets/images/mylo-logo-icon.png"
import { nameBasedProtectedRoutes } from "../../../router/protected"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"

/**
 * Header Sidebar & Toggle Manager
 * @date 
 *
 * @param {{ isActiveSidebar: any; }} {
  isActiveSidebar
}
 * @returns {*}
 */
export const Sidebar = ({ isActiveSidebar }) => {

  const rolesData = useSelector((state) => state?.rolesData?.masterPermissionResponseDTOList)

  const navigate = useNavigate()
  const [openSubMenuId, setOpenSubMenuId] = useState(null);
  const [finalNavData, setFinalNavData] = useState(NavItems ?? [])


  const toggleSubMenu = (id) => {
    // const newSubMenuId = openSubMenuId == id ? null : id;
    if (openSubMenuId === id || id == null) {
      setOpenSubMenuId(null);
      localStorage.removeItem('openSubMenuId');
    }
    else {
      setOpenSubMenuId(id);
      localStorage.setItem('openSubMenuId', id);
    }
  };

  useEffect(() => {
    const storedSubMenuId = localStorage.getItem('openSubMenuId');
    if (storedSubMenuId) {
      setOpenSubMenuId(parseInt(storedSubMenuId));
    }
  }, [navigate]);

  const filterNavItems = (permissions, navItems) => {
    return navItems.map(item => {
      if (item?.key) {
        // Filter permissions for children of this item
        const childPermissions = permissions.filter(perm => perm.parentPermissions.key === item.key);
  
        // Check if any child permission has status true
        const isValid = childPermissions.some(permission => permission?.childPermissions?.some(child => child?.status));
  
        if (isValid) {
          // If the current item is "Reports" and it has valid permissions, create its submenu items based on child permissions
          if (item?.menuName === "Reports") {
            const reportPermissions = permissions.find(perm => perm.parentPermissions.key === "reports");
            if (reportPermissions) {
              // Generate submenu items based on child permissions of the "Reports" menu
              const subMenuArr =  item.submenu.map((subItem)=>{
                const isActive = reportPermissions.childPermissions.some((element)=>element?.status ===  true && element?.key === subItem?.key)
                if(isActive){
                  return subItem
                }
              }).filter(element => element!== undefined) // DO NOT RETURN UNDEFINED IN ARRAY
              return { ...item, submenu: subMenuArr }; // Include submenu items in the returned item
            }
          }
          // If item has submenu, filter submenu as well
          if (item.submenu) {
            item.submenu = item.submenu.filter(subItem => {
              // Find permission for this submenu item
              const subPermission = permissions.find(perm => perm.parentPermissions.key === subItem.key);
              // Return true if permission exists and is valid
              return subPermission && subPermission?.parentPermissions?.status;
            });
          }
          return item;
        }
      }
      return null;
    }).filter(Boolean);
  };
  // Filter NavItems based on permissions
  useEffect(() => {
    let navData = NavItems
    if (rolesData) {
      const filteredNavData = filterNavItems(rolesData, navData);
      navData = [...filteredNavData]
    }
    setFinalNavData(navData)
  }, [rolesData])

  return (
    <Col
      xs="auto"
      className={`sidebarMenu h-100 ${isActiveSidebar ? "sidebarAction" : ""}`}
    >
      <Navbar bg="dark" variant="dark" expand="xxl" className="w-100 h-100 p-0">
        <div className="d-flex flex-column w-100 h-100">
          <div className="d-flex py-2 px-4 align-items-center justify-content-center sidebarLogo border-0 border-opacity-25">
            <Link to={nameBasedProtectedRoutes?.userDashboard?.path} className="d-inline-block py-2">
              <Image
                className="img-fluid showInSidebar logoImgSidebar "
                src={Logo}
                alt="Logo"
                width={122}
                height={46}
              />
              <Image
                className="img-fluid showInSmallSidebar"
                src={LogoSmall}
                alt="Logo"
                width={33}
                height={33}
              />
            </Link>
          </div>
          <div className="overflow-x-hidden overflow-y-auto sidebarList">
            <Nav defaultActiveKey="/dashboard" as="ul" className="flex-column">
              {finalNavData.map(elem => {
                const { id, menuName, menuIcon, path, submenu } = elem;
                const isOpen = openSubMenuId == id;
                if (submenu) {
                  return (
                    <NavDropdown as="li" title={
                      <>
                        <span className="py-1 text-center min-w-48 sidebarIcon d-inline-block">{menuIcon}</span>
                        <span className="mr-2 hideInSmallSidebar">{menuName}</span>
                      </>
                    } id={`nav-dropdown-${id}`} key={id} className="navSubmenu" show={isOpen}
                      onToggle={() => toggleSubMenu(id)}
                      autoClose={false}
                    >
                      {submenu.map((subItem,index) => (
                        <NavDropdown.Item key={subItem?.id} as={NavLink} to={subItem.path}>
                          {subItem.menuName}
                        </NavDropdown.Item>
                      ))}
                    </NavDropdown>
                  );
                } else {
                  return (
                    <Nav.Item as="li" key={id}>
                      <Nav.Link
                        as={NavLink}
                        to={path}
                        className="align-items-center d-flex px-0 sidebarLink text-nowrap"
                        onClick={() => { toggleSubMenu(null) }}
                      >
                        <span className="py-1 text-center min-w-48 sidebarIcon">{menuIcon}</span>
                        <span className="hideInSmallSidebar">{menuName}</span>
                      </Nav.Link>
                    </Nav.Item>
                  );
                }
              })}
            </Nav>
          </div>
        </div>
      </Navbar>
    </Col>
  )
}
