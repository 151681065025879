import { Col, Modal, Row } from 'antd';
import React from 'react'
import { Form, Formik } from 'formik';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { AgencyAccountApproveDisapprove } from '../../services/agencyService';
import { rejectFormSchema } from '../../validations/ValidationSchema';
import UserTextArea from '../common/UserTextArea';
import { useNavigate } from 'react-router-dom';
import { nameBasedRoutes } from '../../pages/Agency/routes';
function DisapproveModal({ agencyId, isModalOpen, setModalOpen }) {

    const navigate = useNavigate()
    // THIS IS SUBMIT FOR AGENCY DISAPPROVE WITH REASON
    const handleSubmit = (value) => {
        const data = {
            agencyId: agencyId ? parseInt(agencyId) : null,
            status: "REJECTED",
            reason: value?.reason
        }
        AgencyAccountApproveDisapprove(data).then((response) => {
            setModalOpen(false)
            navigate(nameBasedRoutes?.agency?.path)
            toast.success(response?.message ?? "Agency account approved successfully.")
        }).catch((error) => {
            if(error?.status == 500){
                toast.error('Unauthorized or server issue. Please refresh or try later. ')
            }else{
                 toast.error(
          error?.error_description
            ? error?.error_description
            : error?.details ? error?.details : error?.message
        );
            }
        })
    }
    // HANDLE CANCEL EVENT OF MODAL
    const handleCancel = () => {
        setModalOpen(false);
    };
    return (
        <div>
            <Modal title="Reason for Disapprove" open={isModalOpen} onCancel={handleCancel} footer={false}>
                <div className='mt-1'>
                    <Formik
                        initialValues={{ reason: "" }}
                        validationSchema={rejectFormSchema}
                        onSubmit={(values, actions) => {
                            actions.setSubmitting(false)
                            handleSubmit(values)
                        }}>
                        {({
                            handleSubmit,
                            handleChange,
                            handleBlur,
                            values,
                            setFieldValue,
                            touched,
                            isValid,
                            errors
                        }) => (
                            <Form>
                                <Row>
                                    <Col>
                                        <UserTextArea
                                            controlId="reason"
                                            // label="Reason"
                                            placeholder="Enter Your Reason"
                                            value={values?.reason}
                                            name="reason"
                                            errorsField={errors?.reason}
                                            touched={touched?.reason}
                                            handleChange={(event) => { setFieldValue("reason", event?.target?.value) }}
                                            handleBlur={handleBlur}
                                            rows={4}
                                            cols={60}
                                            className={"form-control shadow-none w-100"}
                                        />
                                    </Col>
                                </Row>
                                <Row className='mt-3'>
                                    <Col>
                                        <Button type="submit" className='btn btn-primary' onClick={handleSubmit}>Submit</Button>
                                        {/* CLOSE MODAL */}
                                        <Button className='btn btn-secondary ms-2' onClick={handleCancel}>Cancel</Button>
                                    </Col>
                                </Row>
                            </Form>
                        )}
                    </Formik>
                </div>

            </Modal>
        </div>
    )
}

export default DisapproveModal
