import { TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { Card, Stack, Row, Col } from 'react-bootstrap';
import { MdClear } from "react-icons/md";
import { IoIosBusiness } from "react-icons/io";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { MatDataGridWithPagination } from '../../../components/common/dataGrid/DataGridWithPagination';
import { ActionCell } from '../../../components/common/dataGrid/ActionCell';
import { toast } from 'react-toastify';
import LoaderMu from '../../../components/common/LoaderMu';
import { fetchAgencyList, markTrustedAgency } from '../../../services/agencyService';
import { nameBasedRoutes } from '../routes';
import { Switch } from 'antd';
import { useSelector } from 'react-redux';
import NotAuthorized from '../../../components/common/NotAuthorized';
function ApprovedAgency() {


  // MANAGING USER PERMISSIONS HERE

  const roleDetails = useSelector((state) => state?.rolesData?.masterPermissionResponseDTOList)

  const [isAuthorized, setIsAuthorized] = useState(false)
  const [authPermissions, setAuthPermissions] = useState(null)

  useEffect(() => {

    if (roleDetails) {
      const permissions = roleDetails.find((detail) => {
        return detail.childPermissions.find((childPerm) => (
          childPerm?.key === "agency_list" && childPerm?.status === true
        ))
      })
      if (permissions) {
        setIsAuthorized(true)
        // Create an object from childPermissions
        const permissionsObject = {};
        permissions?.childPermissions.forEach(permission => {
          permissionsObject[permission.title.toLowerCase()] = permission.status;
        });
        setAuthPermissions(permissionsObject)
      } else {
        setIsAuthorized(false)
      }
    } else {
      setIsAuthorized(true)
    }

  }, [roleDetails])


  // FOR MANAGING PAGINATION USING URL
  const location = useLocation();

  const params = new URLSearchParams(location?.search)

  const currentPage = params.get("page") ?? 0; // PAGE VALUE FROM URL
  const search = params.get("search") ?? '' // SEARCH VALUE FROM URL

  const navigate = useNavigate()

  const [loading, setLoading] = useState(true)
  const [agencyList, setAgencyList] = useState([])
  const [searchValue, setSearchValue] = useState(search ?? '');
  const [pageSize, setPageSize] = useState(10)
  const [totalResults, setTotalResults] = useState(0);
  const [newAgencyCount, setNewAgencyCount] = useState(0) // FOR NEW REGISTERED AGENCY COUNT
  const [sortingData, setSortingData] = useState({ field: "id", sort: "ASC" });

  // THIS IS FOR SEARCHING
  const handleValueChange = (value) => {
    setSearchValue(value);
    if (value !== "") {
      navigate(`${nameBasedRoutes?.agency?.path}?page=${currentPage}&search=${encodeURIComponent(value)}`)
    } else {
      navigate(`${nameBasedRoutes?.agency?.path}?page=${currentPage}`)
    }
  };
  // THIS IS FOR PAGE CHANGE
  const handlePageChange = (value) => {
    navigate(`${nameBasedRoutes?.agency?.path}?page=${value?.page}${search !== '' ? '&search=' + search : ''}`)
    setPageSize(value?.pageSize)
  }
  // HANDLE SORT MODAL CHANGE
  const handleSortModelChange = (data) => {
    setSortingData(data?.length > 0 ? data[0] : {})
  }

  // CLEAR SEARCH INPUT BOX
  const handleClear = () => {
    setSearchValue("");
    if (search !== "") {
      navigate(`${nameBasedRoutes?.agency?.path}?page=${currentPage}`)
    }
  };

  // HANDLE TRUSTED AGENCIES TOGGLE 
  const handleTrustedToggle = (value, id) => {
    setLoading(true)
    markTrustedAgency(id, value ?? false).then(response => {
      setLoading(false)

      toast.success(response?.message ?? 'Status updated!')
      fetchAgencyData()
    }).catch((error) => {
      setLoading(false)
       if(error?.status == 500){
                toast.error('Unauthorized or server issue. Please refresh or try later. ')
            }else{
                 toast.error(
          error?.error_description
            ? error?.error_description
            : error?.details ? error?.details : error?.message
        );
            }
    })

  }

  // COLUMNS FOR DATA TABLE
  const columns = [
    {
      field: "id",
      headerName: "S. No.",
      editable: false,
      flex: 0.05,
      minWidth: 100,
      sortable: false,
      renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1 + ((currentPage * pageSize))
    },
    {
      field: "name",
      headerName: "Agency Name",
      editable: false,
      flex: 0.6,
      minWidth: 160,
      sortable: true
    },
    {
      field: "contactName",
      headerName: "Contact Name",
      editable: false,
      flex: 0.6,
      minWidth: 160,
      sortable: true
    },
    {
      field: "companyNationalId",
      headerName: "National Id",
      editable: false,
      flex: 0.6,
      minWidth: 150,
      sortable: true
    },
    {
      field: "email",
      headerName: "Email",
      editable: false,
      flex: 0.6,
      minWidth: 220,
      sortable: true
    },
    {
      field: "trusted",
      headerName: "Trusted",
      editable: false,
      flex: 0.6,
      minWidth: 150,
      sortable: false,
      renderCell: (props) => {

        return <Switch checked={props?.row?.trusted ?? false} checkedChildren={"TRUSTED"} unCheckedChildren={"TRUSTED"}
          className='fw-bold' onChange={(value) => { handleTrustedToggle(value, props?.row?.id) }} />
      }
    },

    {
      field: "status",
      headerName: "Status",
      editable: false,
      flex: 0.6,
      minWidth: 150,
      sortable: false
    },
    {
      field: "action",
      headerName: "Action",
      editable: false,
      flex: 0.2,
      minWidth: 120,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (props) => {
        return (
          <ActionCell
            controlId="app/agency" //agency_edit
            isViewEnable={authPermissions === null ? true : authPermissions?.detail}
            isEditEnable={authPermissions === null ? true : authPermissions?.edit}
            redirectID={props.row.id}
            rowData={props.row}
            pageNumber={currentPage}
            searchedValue={searchValue}
          />
        );
      },
    },]


  // GET USERS DATA LIST FOR TABLE
  const fetchAgencyData = () => {
    setLoading(true)
    // ?search=&page=0&size=10&sort=createdDate,DESC
    const params = {
      page: currentPage ?? 0,
      size: pageSize ?? 10,
      search: searchValue ?? '',

    }
    if (sortingData && Object.keys(sortingData).length !== 0) {
      params["sort"] = `${sortingData?.field},${sortingData?.sort?.toUpperCase()}`
    }
    fetchAgencyList(params).then(response => {
      setLoading(false)
      setTotalResults(response?.headers["x-total-count"])
      setAgencyList(response?.data)
    }).catch((error) => {
      setLoading(false)
       if(error?.status == 500){
                toast.error('Unauthorized or server issue. Please refresh or try later. ')
            }else{
                 toast.error(
          error?.error_description
            ? error?.error_description
            : error?.details ? error?.details : error?.message
        );
            }
    })
  }


  // GET AGENCY DATA LIST FOR TABLE
  const fetchNewAgencyCount = () => {
    setLoading(true)
    // ?search=&page=0&size=10&sort=createdDate,DESC
    const params = {
      status: "INACTIVE",
      page: 0,
      size: 20,
    }
    fetchAgencyList(params).then(response => {
      setLoading(false)
      setNewAgencyCount(response?.headers["x-total-count"])
    }).catch((error) => {
      setLoading(false)
       if(error?.status == 500){
                toast.error('Unauthorized or server issue. Please refresh or try later. ')
            }else{
                 toast.error(
          error?.error_description
            ? error?.error_description
            : error?.details ? error?.details : error?.message
        );
            }
    })
  }

  useEffect(() => {
    // CALLING FUNCTION FOR FETCH DATA
    if (isAuthorized) {
      fetchAgencyData()
    } else {
      setLoading(false)
    }
  }, [currentPage, pageSize, searchValue, sortingData, isAuthorized])

  // WILL BE DEPLOYED IN PHASE 2
  // useEffect(() => {
  //   fetchNewAgencyCount()
  // }, [])


  return (
    <div className="d-flex flex-column pageContainer p-3 h-100">
      <LoaderMu loading={loading} />
      <div className="flex-grow-1 h-100 pageContent position-relative pt-4 overflow-auto">
       {/* IF USERS IS AUTHORIZED THEN ONLY SHOW PAGE DATA */}
        {
          !isAuthorized && !loading ?
            <NotAuthorized /> :
            <Card className="h-100 bg-white shadow border-0 theme-card-cover">
              <div className="theme-card-header px-1">
                <Stack
                  direction="horizontal"
                  gap={2}
                  className="flex-wrap justify-content-between px-3 pt-3 pb-2 align-items-start"
                >
                  <h5 className="mb-0 position-relative fs-18 text-black fw-medium">
                    <div className="align-items-center bg-primary d-inline-flex h-60 justify-content-center  position-absolute rounded start-0 text-white theme-icon-box w-60 z-1">
                      <IoIosBusiness size={40} />
                    </div>
                    Manage Agency
                  </h5>
                  <div>
                    <Link className='btn btn-primary me-2' to={nameBasedRoutes?.newAgenciesList?.path} variant="primary">New Agencies
                      {/* THIS WILL BE DEPLOYED IN PHASE 2 */}
                      {/* {newAgencyCount > 0 ? <span className="badge bgdarkgreen">{newAgencyCount}</span> : ""} */}
                    </Link>

                  </div>
                </Stack>
              </div>
              <div className="flex-grow-1 d-flex flex-column px-3 h-100 overflow-auto">
                <div className="theme-data-table-filter px-1">
                  <Row className="justify-content-end">
                  {/* SEARCH INPUT */}
                    <Col sm={5} lg={2} className="mb-3 pb-1">
                      <TextField
                        id="search-filter"
                        label="Search"
                        variant="standard"
                        fullWidth
                        value={searchValue}
                        onChange={(e) => {
                          handleValueChange(e.target.value);
                        }}
                      />
                      <MdClear
                        className="clear-postion"
                        onClick={handleClear}
                      ></MdClear>
                    </Col>
                  </Row>
                </div>
                {/* RENDER TABLE COMPONENT HERE */}
                <MatDataGridWithPagination
                  controlId={"id"}
                  columns={columns}
                  data={agencyList ?? []}
                  onPaginationModelChange={handlePageChange}
                  pageSize={pageSize}
                  totalResults={totalResults}
                  currentPage={currentPage}
                  handleSortModelChange={handleSortModelChange}
                />
              </div>
            </Card>
        }
      </div>
    </div>
  )
}

export default ApprovedAgency


