import { Autocomplete, TextField } from '@mui/material'
import React from 'react'

function SelectWithSearch({ label, options, value, onChange, minWidth, name, variant,errorsField, touched, disabled, isHtmlLabel ,inputValue, onInputChange}) {

    return (
        <Autocomplete
            options={options}
            disablePortal
            value={value}
            onChange={onChange}
            label={label}
            fullWidth
            name={name}
            getOptionKey={(option)=> option?.value}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            error={errorsField && touched}
            // helperText={errorsField && touched ? errorsField : ""}
            disabled={disabled ? true : false}
            autoComplete={false}
            inputValue={inputValue}
            onInputChange={onInputChange}
            renderInput={(params) => <TextField {...params} autoComplete='false' label={label} variant={variant ?? "standard"} />}
        />
    )
}

export default SelectWithSearch
