import React, { useEffect, useState } from 'react'
import { Button, Card, Col, FormLabel, Row, Stack } from 'react-bootstrap'
import { PiFilesLight } from 'react-icons/pi'
import { DatePicker } from 'antd'
import { systemUserReportApi } from '../../services/reportService'
import { toast } from 'react-toastify'
import moment from 'moment'
import { Form, Formik } from 'formik'
import dayjs from 'dayjs'
import { useSelector } from 'react-redux'
import NotAuthorized from '../../components/common/NotAuthorized'

const { RangePicker } = DatePicker;

const SystemUserReport = () => {
	// MANAGING USER PERMISSIONS HERE
	const roleDetails = useSelector((state) => state?.rolesData?.masterPermissionResponseDTOList)
	const [isAuthorized, setIsAuthorized] = useState(false)
	useEffect(() => {
		if (roleDetails) {
			const permissions = roleDetails.find((detail) => {
				return detail.childPermissions.find((childPerm) => (
					childPerm?.key === "reports_system_user" && childPerm?.status === true
				))
			})
			if (permissions) {
				setIsAuthorized(true)
			} else {
				setIsAuthorized(false)
			}
			setLoading(false)
		} else {
			setIsAuthorized(true)
			setLoading(false)
		}
	}, [roleDetails])
	const dateFormat = "YYYY-MM-DD";
	// First date of the year
	const firstDateOfYear = moment().startOf('year').format(dateFormat);

	// Last date of the year
	const lastDateOfYear = moment().endOf('year').format(dateFormat);

	const [loading, setLoading] = useState(true)

	// HANDLE DOWNLOAD REPORT BUTTON HERE 
	const handleReportDownload = (values) => {

		setLoading(true)
		const params = {
			startDate: values?.dateRange[0] ? dayjs(values?.dateRange[0]).format(dateFormat) : "",
			endDate: values?.dateRange[1] ? dayjs(values?.dateRange[1]).format(dateFormat) : "",
		}

		// CALL API HERE TO GET FILE DATA
		systemUserReportApi(params).then((response) => {
			// console.log(response?.headers.get('Content-Disposition'))
			if (response?.data) {
				const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
				const blobUrl = window.URL.createObjectURL(blob);

				const tempLink = document.createElement('a');
				tempLink.href = blobUrl;
				tempLink.setAttribute('download', 'System-user.xlsx');

				// Append the link to the document body before clicking it
				document.body.appendChild(tempLink);

				tempLink.click();

				// Clean up by revoking the Blob URL
				window.URL.revokeObjectURL(blobUrl);

				// Remove the link from the document body after clicking
				document.body.removeChild(tempLink);
			} else {
				throw new Error('Response data is empty.');
			}
		}).catch((error) => {
			 if(error?.status == 500){
                toast.error('Unauthorized or server issue. Please refresh or try later. ')
            }else{
                 toast.error(
          error?.error_description
            ? error?.error_description
            : error?.details ? error?.details : error?.message
        );
            }
		}).finally(() => {
			setLoading(false)
		})
	}
	return (
		<div className="d-flex flex-column pageContainer p-3 h-100">
			<div className="flex-grow-1 h-100 pageContent position-relative pt-4 overflow-auto">
				{
					!loading && !isAuthorized ? <NotAuthorized /> :
						<Card className=" bg-white shadow border-0 theme-card-cover">
							<div className="theme-card-header px-1">
								<Stack
									direction="horizontal"
									gap={2}
									className="flex-wrap justify-content-between px-3 pt-3 pb-2 align-items-start"
								>
									<h5 className="mb-0 position-relative fs-18 text-black fw-medium">
										<div className="align-items-center bg-primary d-inline-flex h-60 justify-content-center  position-absolute rounded start-0 text-white theme-icon-box w-60 z-1">
											<PiFilesLight size={40} />
										</div>
										System User Report
									</h5>
								</Stack>
							</div>
							<div className="flex-grow-1 d-flex flex-column px-1">
								<div className="p-3 d-flex flex-column h-100">
									<Formik
										enableReinitialize={true}
										initialValues={{ dateRange: [dayjs(firstDateOfYear, dateFormat), dayjs(lastDateOfYear, dateFormat)] }}
										onSubmit={(values, actions) => {
											actions.setSubmitting(false)
											handleReportDownload(values)
										}}
									>
										{({
											handleSubmit,
											handleChange,
											handleBlur,
											values,
											setFieldValue,
											touched,
											isValid,
											errors
										}) => (
											<Form className="d-flex flex-column flex-grow-1 theme-from">
												<Row>
													<Col sm={6} md={6} lg={6} className="mb-4 pb-1">
														<FormLabel className='d-block'>
															Select Date
														</FormLabel>
														<RangePicker
															controlId="dateRange"
															name="dateRange"
															value={values?.dateRange}
															className={`form-rangepicker antd-rangePicker`}
															// defaultValue={[dayjs(startDate, dateFormat), dayjs(endDate, dateFormat)]}
															format={dateFormat}
															onChange={(date, dateString) => {
																setFieldValue("dateRange", date)
															}}
															footer={true}
															allowClear={false}
														/>
													</Col>
												</Row>
												<Stack direction="horizontal" gap={3} className="my-3">
													<Button
														variant="primary"
														onClick={handleSubmit}
														className="rounded-xs fs-15"
														type="submit"
														disabled={loading ?? false}
													>Download Report</Button>
												</Stack>
											</Form>
										)}
									</Formik>
								</div>
							</div>
						</Card>}
			</div>
		</div>
	)
}

export default SystemUserReport