import { Backdrop, CircularProgress } from '@mui/material'
import React from 'react'

function LoaderMu({loading}) {

  return (
    <div>
       <div>
       <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading ?? false}
      
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
    </div>
  )
}

export default LoaderMu
