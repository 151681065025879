import React from 'react';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';

const SunTextEditor = ({ id, name, errorsField, touched, content, handleChange, handleBlur,dir }) => {
  

    return (
        <div>
            <SunEditor
                setOptions={{
                    buttonList: [
                        ['undo', 'redo'],
                        ['font', 'fontSize', 'formatBlock'],
                        ['bold', 'underline', 'italic', 'strike', 'removeFormat'],
                        ['fontColor', 'hiliteColor', 'textStyle'],
                        ['outdent', 'indent'],
                        ['align', 'horizontalRule', 'list', 'table'],
                        ['link', 'image'],
                        ['fullScreen', 'showBlocks', 'codeView'],
                        ['preview', 'print']
                        // ['save', 'template']
                    ],
                    defaultStyle: {
                        'font-size': '14px', // Set your desired default font size
                        'font-family': 'Arial, sans-serif', // Set your desired default font family
                        // Add other default styles as needed
                    },
                    rtl: dir ==="rtl" ? true : false
                }}
              
                onBlur={handleBlur}
                onChange={handleChange}
                setContents={content}
            />

            {/* {
                errorsField || touched ? <span className='text-danger'>
                    {errorsField}
                </span> : ""
            } */}

            {/* <button onClick={handleSubmit}>Submit Data</button> */}
        </div>

    );
}

export default SunTextEditor;
