import { Form, Formik } from 'formik'
import React, { useState } from 'react'
import { Button, Card, Col, Row, Stack } from 'react-bootstrap'
import InputText from '../../components/common/InputText'
import { IoMdNotifications } from "react-icons/io";
import { pushNotificationApi } from '../../services/notificationService';
import { toast } from 'react-toastify';
import LoaderMu from '../../components/common/LoaderMu';
import { pushNotificationSchema } from '../../validations/ValidationSchema';
const Notification = () => {
  const [loading, setLoading] = useState(false)
  const handleSubmit = (values, actions) => {
    setLoading(true)
    const formData = {
      title: values?.title ?? null,
      description: values?.description ?? null
    }
    pushNotificationApi(formData).then((response) => {
      toast.success(response?.message)
      setLoading(false)
      actions.resetForm()
    }).catch((error) => {
      if(error?.status == 500){
                toast.error('Unauthorized or server issue. Please refresh or try later. ')
            }else{
                 toast.error(
          error?.error_description
            ? error?.error_description
            : error?.details ? error?.details : error?.message
        );
            }
      setLoading(false)
    })
  }
  return (
    <div className="d-flex flex-column pageContainer p-3 h-100 overflow-auto">
    <LoaderMu loading={loading}/>
      <div className="flex-grow-1 pageContent position-relative pt-4">
        {/* {JSON.stringify(data)} */}
        <Card className=" bg-white shadow border-0 theme-card-cover">
          <div className="theme-card-header px-1">
            <Stack direction="horizontal" gap={2} className="flex-wrap justify-content-between px-3 pt-3 pb-2 align-items-start">
              <h5 className="mb-0 position-relative fw-medium">
                <div className="align-items-center bg-primary d-inline-flex h-60 justify-content-center  position-absolute rounded start-0 text-white theme-icon-box w-60 z-1">
                  <span className="theme-icon-box-inner"><IoMdNotifications size={36} /></span>
                </div>
                Notification
              </h5>
            </Stack>
          </div>
          <div className="flex-grow-1 d-flex flex-column px-1">
            <div className="p-3 d-flex flex-column h-100">
              <Formik
                initialValues={
                  {
                    title: "",
                    description: "",
                  }
                }
                validationSchema={pushNotificationSchema}
                onSubmit={(values, actions) => {
                  actions.setSubmitting(false)
                  handleSubmit(values, actions)
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  setFieldValue,
                  touched,
                  isValid,
                  errors
                }) => (
                  <Form className="d-flex flex-column flex-grow-1 theme-from">
                    <Row className="gx-xl-5">
                      <Col sm={12} md={12} lg={12} className="mb-4 pb-1">
                        <InputText
                          controlId="title"
                          label="Title"
                          value={values?.title}
                          name="firstName"
                          maxLength="70"
                          errorsField={errors?.title}
                          touched={touched?.title}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          className={"w-100 form-control"}
                          variant="outlined"
                        />
                      </Col>

                    </Row>

                    <Row className="gx-xl-5">
                      <Col sm={12} md={12} lg={12} className="mb-4 pb-1">
                        <InputText
                          controlId="description"
                          label="Description"
                          value={values?.description}
                          name="description"
                          maxLength="70"
                          errorsField={errors?.description}
                          touched={touched?.description}
                          handleChange={handleChange}
                          className={'h-auto w-100'}
                          multiline={true}
                          rows={4}
                          handleBlur={handleBlur}
                          variant="outlined" />
                      </Col>
                    </Row>
                    <Stack direction="horizontal" gap={3} className="my-3">
                      <Button
                        variant="primary"
                        onClick={handleSubmit}
                        className="rounded-xs fs-15"
                        type="submit"
                      >Send</Button>
                      {/* <Link to={"/"} className="btn btn-outline-secondary rounded-xs fs-15">Cancel</Link> */}
                    </Stack>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </Card>
      </div>

    </div>
  )
}

export default Notification
