import { BLOG_LIST_API, BLOG_EDIT_API, BLOG_DETAILS_API, BLOG_ADD_API,UPDATE_BLOG_STATUS } from "../constants/paths"
import { axios } from "./axios"

// ADD BLOG
export const addBlogApi = async (data) => {
  const response = await axios.post(BLOG_ADD_API, data)
  return response?.data
}

// FETCH BLOG LIST DATA
export const blogListApi = async (params) => {
  const response = await axios.get(BLOG_LIST_API, { params })
  return response
}

// EDIT BLOG
export const editBlogApi = async (data) => {
  const response = await axios.put(`${BLOG_EDIT_API}`,data)
  return response?.data
}

// FETCH BLOG
export const getBlogApi = async (id) => {
  const response = await axios.get(`${BLOG_DETAILS_API}/${id}`)
  return response?.data
}

// UPDATE STATUS

export const updateBlogStatusApi = async(id , status)=>{
  const response = await axios.get(`${UPDATE_BLOG_STATUS}/${id}?status=${status}`)
  return response?.data
}

