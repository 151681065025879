import React, { useEffect, useState } from 'react'
import { Card, Col, Row, Stack } from 'react-bootstrap'
import { MdOutlinePeopleAlt, MdClear } from "react-icons/md"
import { fetchAgentList } from '../../services/agencyService'
import { toast } from 'react-toastify'
import { TextField } from '@mui/material'
import { MatDataGridWithPagination } from '../common/dataGrid/DataGridWithPagination'
import { useParams } from 'react-router-dom'
import LoaderMu from '../common/LoaderMu'

function AgentListing() {
  const [loading, setLoading] = useState(false)
  const [agentList, setAgentList] = useState([])
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [totalResults, setTotalResults] = useState(0);
  const [sortingData, setSortingData] = useState({ field: "id", sort: "ASC" });

  const { id } = useParams()
  // THIS IS FOR SEARCHING
  const handleValueChange = (value) => {
    setSearchValue(value);
  };
  // THIS IS FOR PAGE CHANGE
  const handlePageChange = (value) => {
    setCurrentPage(value?.page)
    setPageSize(value?.pageSize)
  }
  // HANDLE SORT MODAL CHANGE
  const handleSortModelChange = (data) => {
    setSortingData(data?.length > 0 ? data[0] : {})
  }

  // CLEAR SEARCH INPUT BOX
  const handleClear = (value) => {
    setSearchValue("");
  };

  // COLUMNS FOR DATA TABLE
  const columns = [
    {
      field: "id",
      headerName: "S. No.",
      editable: false,
      flex: 0.05,
      minWidth: 100,
      sortable: false,
    },
    {
      field: "firstName",
      headerName: "Agent Name",
      editable: false,
      sortable:true,
      flex: 0.6,
      minWidth: 150,
      renderCell: (params) => (<span>{`${params?.row?.firstName ?? ''} ${params?.row?.lastName && params?.row?.lastName !==null ? params?.row?.lastName : '' }`}</span>)
    },
    {
      field: "email",
      headerName: "Email",
      editable: false,
      flex: 0.6,
      minWidth: 220,
    },
    {
      field: "mobile",
      headerName: "Mobile No.",
      editable: false,
      flex: 0.6,
      minWidth: 150,
      sortable :false
    },
    {
      field: "address",
      headerName: "Address",
      editable: false,
      flex: 0.6,
      minWidth: 180,
      sortable :false
    },

    {
      field: "status",
      headerName: "Status",
      editable: false,
      flex: 0.6,
      minWidth: 150,
    },
  ]
  // GET AGENCY DATA LIST FOR TABLE
  const fetchAgentListData = () => {
    setLoading(true)
    // ?search=&page=0&size=10&sort=createdDate,DESC
    const params = {
      agencyId: id,
      page: currentPage ?? 0,
      size: pageSize ?? 10,
      search: searchValue ?? '',

    }
    if (sortingData && Object.keys(sortingData).length !== 0) {
      params["sort"] = `${sortingData?.field},${sortingData?.sort?.toUpperCase()}`
    }
    fetchAgentList(params).then(response => {
      setLoading(false)
      setTotalResults(response?.headers["x-total-count"])
      setAgentList(response?.data)
    }).catch((error) => {
      setLoading(false)
       if(error?.status == 500){
                toast.error('Unauthorized or server issue. Please refresh or try later. ')
            }else{
                 toast.error(
          error?.error_description
            ? error?.error_description
            : error?.details ? error?.details : error?.message
        );
            }
    })
  }
  useEffect(() => {
    // CALLING FUNCTION FOR FETCH DATA
    fetchAgentListData()
  }, [currentPage, pageSize, searchValue, sortingData])

  return (
    <Card className="bg-white shadow border-0 theme-card-cover mt-5 h-75">
    <LoaderMu loading={loading}/>
      <div className="theme-card-header px-1 ">
        <Stack
          direction="horizontal"
          gap={2}
          className="flex-wrap justify-content-between px-3 pt-3 pb-2 align-items-start"
        >
          <h5 className="mb-0 position-relative fs-18 text-black fw-medium">
            <div className="align-items-center bg-primary d-inline-flex h-60 justify-content-center  position-absolute rounded start-0 text-white theme-icon-box w-60 z-1">
              <MdOutlinePeopleAlt size={40} />
            </div>
            Agents
          </h5>
        </Stack>
      </div>
      <div className="flex-grow-1 d-flex flex-column px-3 h-100 overflow-auto">
        <div className="theme-data-table-filter px-1">
          <Row className="justify-content-end">
          {/* SEARCH INPUT */}
            <Col sm={5} lg={2} className="mb-3 pb-1">
              <TextField
                id="search-filter"
                label="Search"
                variant="standard"
                fullWidth
                value={searchValue}
                onChange={(e) => {
                  handleValueChange(e.target.value);
                }}
              />
              {/* CLEAR ICON FOR SEARCH INPUT */}
              <MdClear
                className="clear-postion"
                onClick={handleClear}
              ></MdClear>
            </Col>
          </Row>
        </div>
        {/* RENDERING TABLE COMPONENTE HERE */}
        <MatDataGridWithPagination
          controlId={"id"}
          columns={columns}
          data={agentList ?? []}
          onPaginationModelChange={handlePageChange}
          pageSize={pageSize}
          totalResults={totalResults}
          currentPage={currentPage}
          handleSortModelChange={handleSortModelChange}
        />
      </div>
    </Card>
  )
}

export default AgentListing
