import React, { useEffect, useState } from 'react'
import { Button, Card, Col, FormLabel, Row, Stack } from 'react-bootstrap'
import { PiFilesLight } from 'react-icons/pi'
import { agencySearchApi, propertyReportApi, villageSearchApi } from '../../services/reportService'
import { toast } from 'react-toastify'
import { Form, Formik } from 'formik'
import dayjs from 'dayjs'
import { DatePicker } from 'antd'
import moment from 'moment'
import SelectWithSearch from '../../components/common/SelectWithSearch'
import { getGovernorateListAPI } from '../../services/governorateServices'
import NotAuthorized from '../../components/common/NotAuthorized'
import { useSelector } from 'react-redux'
const { RangePicker } = DatePicker;
const PropertyReport = () => {

	// MANAGING USER PERMISSIONS HERE
	const roleDetails = useSelector((state) => state?.rolesData?.masterPermissionResponseDTOList)
	const [isAuthorized, setIsAuthorized] = useState(false)
	useEffect(() => {
		if (roleDetails) {
			const permissions = roleDetails.find((detail) => {
				return detail.childPermissions.find((childPerm) => (
					childPerm?.key === "reports_property" && childPerm?.status === true
				))
			})
			if (permissions) {
				setIsAuthorized(true)
			} else {
				setIsAuthorized(false)
			}
			setLoading(false)
		} else {
			setIsAuthorized(true)
			setLoading(false)
		}
	}, [roleDetails])

	const [loading, setLoading] = useState(true)
	const [governorateList, setGovernorateList] = useState([])
	const [villageList, setVillageList] = useState([])
	const [selectedGovId, setSelectedGovId] = useState("")
	const [villageSearchQry, setVillageSearchQry] = useState("")
	const [agencySearchQry, setAgencySearchQry] = useState("")
	const [agencyList, setAgencyList] = useState([])


	const dateFormat = "YYYY-MM-DD";

	// First date of the year
	const firstDateOfYear = moment().startOf('year').format(dateFormat);

	// Last date of the year
	const lastDateOfYear = moment().endOf('year').format(dateFormat);


	const [startDate, setStartDate] = useState(firstDateOfYear)
	const [endDate, setEndDate] = useState(lastDateOfYear)


	const handleReportDownload = (values, actions) => {

		setLoading(true)

		// CALL API HERE TO GET FILE DATA

		const params = {
			startDate: values?.dateRange[0] ? dayjs(values?.dateRange[0]).format(dateFormat) : "",
			endDate: values?.dateRange[1] ? dayjs(values?.dateRange[1]).format(dateFormat) : "",


			// deviceTypeList : values?.deviceType
		}
		if (values?.agency && values?.agency !== '') {
			params.agencyId = values?.agency?.value
		}
		if (values?.governorate && values?.governorate !== '') {
			params.governorateId = values?.governorate?.value
		}
		if (values?.village && values?.village !== '') {
			params.villageId = values?.village?.value
		}

		propertyReportApi(params).then((response) => {
			if (response?.data) {
				const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
				const blobUrl = window.URL.createObjectURL(blob);

				const tempLink = document.createElement('a');
				tempLink.href = blobUrl;
				tempLink.setAttribute('download', 'Property.xlsx');

				// Append the link to the document body before clicking it
				document.body.appendChild(tempLink);

				tempLink.click();

				// Clean up by revoking the Blob URL
				window.URL.revokeObjectURL(blobUrl);

				// Remove the link from the document body after clicking
				document.body.removeChild(tempLink);
			} else {
				throw new Error('Response data is empty.');
			}
		}).catch((error) => {
			 if(error?.status == 500){
                toast.error('Unauthorized or server issue. Please refresh or try later. ')
            }else{
                 toast.error(
          error?.error_description
            ? error?.error_description
            : error?.details ? error?.details : error?.message
        );
            }
		}).finally(() => {
			setLoading(false)
		})
	}


	// GET GOVERNORATE LIST 
	const getGovernoratedList = () => {
		setLoading(true)
		getGovernorateListAPI().then(response => {
			const gov_list = response?.data.map((gov) => {
				return { value: gov?.id, label: gov?.governorate }
			})

			setGovernorateList(gov_list)
		}).catch((error) => {
			 if(error?.status == 500){
                toast.error('Unauthorized or server issue. Please refresh or try later. ')
            }else{
                 toast.error(
          error?.error_description
            ? error?.error_description
            : error?.details ? error?.details : error?.message
        );
            }
		}).finally(() => {
			setLoading(false)
		})
	}

	// GET VILLAGE LIST 
	const getVillageList = (searchQuery) => {
		setLoading(true)

		const params = {
			search: searchQuery
		}
		if (selectedGovId && selectedGovId !== "") {
			params.governorateId = selectedGovId
		}

		villageSearchApi(params).then(response => {
			const village_list = response?.data.map((village) => {
				return { value: village?.id, label: village?.village }
			})

			setVillageList(village_list)
		}).catch((error) => {
			 if(error?.status == 500){
                toast.error('Unauthorized or server issue. Please refresh or try later. ')
            }else{
                 toast.error(
          error?.error_description
            ? error?.error_description
            : error?.details ? error?.details : error?.message
        );
            }
		}).finally(() => {
			setLoading(false)
		})
	}

	// GET AGENCY LIST 
	const getAgencyList = (searchQuery) => {
		setLoading(true)
		const params = {
			search: searchQuery
		}
		agencySearchApi(params).then(response => {
			const agency_list = response?.data.map((agency) => {
				return { value: agency?.id, label: agency?.name }
			})

			setAgencyList(agency_list)
		}).catch((error) => {
			 if(error?.status == 500){
                toast.error('Unauthorized or server issue. Please refresh or try later. ')
            }else{
                 toast.error(
          error?.error_description
            ? error?.error_description
            : error?.details ? error?.details : error?.message
        );
            }
		}).finally(() => {
			setLoading(false)
		})
	}
	useEffect(() => {
		if (isAuthorized) {
			getGovernoratedList()
		} else {
			setLoading(false)
		}
	}, [isAuthorized])
	return (
		<div className="d-flex flex-column pageContainer p-3 h-100">
			<div className="flex-grow-1 h-100 pageContent position-relative pt-4 overflow-auto">
				{
					!isAuthorized && !loading ? <NotAuthorized /> :
						<Card className="h-100 bg-white shadow border-0 theme-card-cover">
							<div className="theme-card-header px-1">
								<Stack
									direction="horizontal"
									gap={2}
									className="flex-wrap justify-content-between px-3 pt-3 pb-2 align-items-start"
								>
									<h5 className="mb-0 position-relative fs-18 text-black fw-medium">
										<div className="align-items-center bg-primary d-inline-flex h-60 justify-content-center  position-absolute rounded start-0 text-white theme-icon-box w-60 z-1">
											<PiFilesLight size={40} />
										</div>
										Property Report
									</h5>
								</Stack>
							</div>
							<div className="flex-grow-1 d-flex flex-column px-1">
								<div className="p-3 d-flex flex-column h-100">
									<Formik
										enableReinitialize={true}
										initialValues={{
											governorate: null,
											dateRange: [dayjs(startDate, dateFormat), dayjs(endDate, dateFormat)],
											village: null,
											agency: null
										}}
										onSubmit={(values, actions) => {
											actions.setSubmitting(false)
											handleReportDownload(values, actions)
										}}
									>
										{({
											handleSubmit,
											handleChange,
											handleBlur,
											values,
											setFieldValue,
											touched,
											isValid,
											errors
										}) => (
											<Form className="d-flex flex-column flex-grow-1 theme-from">
												<Row>
													{/* GOVERNORATE SELECT */}
													<Col sm={6} md={6} lg={4} className="mb-4 pb-1">
														<SelectWithSearch
															options={governorateList ?? []}
															controlId="governorate"
															label="Governorate"
															value={values?.governorate}
															name="governorate"
															variant="outlined"
															errorsField={errors?.governorate}
															touched={touched?.governorate}
															onChange={(event, newValue) => {
																setFieldValue("governorate", newValue)
																setFieldValue("village", null)
																setSelectedGovId(newValue?.value)
															}}
															handleBlur={handleBlur}
														/>
													</Col>
													{/* VILLAGE SELECT */}
													<Col sm={6} md={6} lg={4} className="mb-4 pb-1">
														<SelectWithSearch
															options={villageList ?? []}
															controlId="village"
															label="Village"
															value={values?.village}
															name="village"
															variant="outlined"
															errorsField={errors?.village}
															touched={touched?.village}
															inputValue={villageSearchQry}
															onInputChange={(event, newInputValue) => {
																setVillageSearchQry(newInputValue);
																getVillageList(newInputValue)
															}}
															onChange={(event, newValue) => {
																setFieldValue("village", newValue)
															}}
															handleBlur={handleBlur}
														/>
													</Col>
													<Col sm={6} md={6} lg={4} className="mb-4 pb-1">
														<SelectWithSearch
															options={agencyList ?? []}
															controlId="agency"
															label="Agency"
															value={values?.agency}
															name="agency"
															variant="outlined"
															errorsField={errors?.agency}
															touched={touched?.agency}
															inputValue={agencySearchQry}
															onInputChange={(event, newInputValue) => {
																setAgencySearchQry(newInputValue);
																getAgencyList(newInputValue)
															}}
															onChange={(event, newValue) => {
																setFieldValue("agency", newValue)
															}}
															handleBlur={handleBlur}
														/>
													</Col>
												</Row>
												<Row>
													<Col sm={6} md={6} lg={4} className="mb-4 pb-1">
														<FormLabel className='d-block'>
															Select Date
														</FormLabel>
														<RangePicker
															controlId="dateRange"
															name="dateRange"
															value={values?.dateRange}
															className={`form-rangepicker antd-rangePicker`}
															format={dateFormat}
															onChange={(date, dateString) => {
																setFieldValue("dateRange", date)
															}}
															footer={true}
															allowClear={false}
														/>
													</Col>
												</Row>
												<Stack direction="horizontal" gap={3} className="my-3">
													<Button
														variant="primary"
														onClick={handleSubmit}
														className="rounded-xs fs-15"
														type="submit"
														disabled={loading ?? false}
													>Download Report</Button>
												</Stack>
											</Form>
										)}
									</Formik>
								</div>
							</div>
						</Card>
				}
			</div>
		</div>
	)
}

export default PropertyReport