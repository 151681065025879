import { Form, Formik, getIn } from 'formik'
import React, { useEffect, useState } from 'react'
import { Button, Card, Col, FormLabel, Row, Stack } from 'react-bootstrap'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { PiFilesLight } from "react-icons/pi";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { editCMSDataApi, getCMSDataById } from '../../../services/cmsService';
import { toast } from 'react-toastify';
import { EditCmsSchema } from '../../../validations/ValidationSchema';
import LoaderMu from '../../../components/common/LoaderMu';
import { useSelector } from 'react-redux';
import NotAuthorized from '../../../components/common/NotAuthorized';
import InputText from '../../../components/common/InputText';
import TagsInput from '../../../components/common/TagsInput';
function EditCms() {
	// MANAGING PERMISSIONS HERE

	const roleDetails = useSelector((state) => state?.rolesData?.masterPermissionResponseDTOList)

	const [isAuthorized, setIsAuthorized] = useState(false)

	useEffect(() => {

		if (roleDetails) {
			const permissions = roleDetails.find((detail) => {
				return detail.childPermissions.find((childPerm) => (
					childPerm?.key === "content_management_edit" && childPerm?.status === true
				))
			})
			if (permissions) {
				setIsAuthorized(true)
			} else {
				setIsAuthorized(false)
			}
		} else {
			setIsAuthorized(true)
		}

	}, [roleDetails])

	const { id } = useParams()

	const navigate = useNavigate()

	const [loading, setLoading] = useState(true)
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [pageTitle, setPageTitle] = useState('')

	const [initialValues, setInitialValues] = useState({
		content_en: "",
		content_ar: "",
		metaTitle_en: "",
		metaTitle_ar: "",
		keywords_en: [],
		keywords_ar: [],
		metaDescription_en: "",
		metaDescription_ar: ""
	})

	const handleSubmit = (values, actions) => {

		setIsSubmitting(true)

		var keywordsEn = ""
		var keywordsAr = ""

		if (values?.keywords_en && values?.keywords_en !== '') {
			keywordsEn = (values?.keywords_en).join(",")
		}
		if (values?.keywords_ar && values?.keywords_ar !== '') {
			keywordsAr = (values?.keywords_ar).join(",")
		}

		const formData = {
			id: parseInt(id),
			content: { en: values?.content_en, ar: values?.content_ar },
			metaTitle: { en: values?.metaTitle_en, ar: values?.metaTitle_ar },
			metaDescription: { en: values?.metaDescription_en, ar: values?.metaDescription_ar },
			metaKeywords: { en: keywordsEn, ar: keywordsAr }
		}
		// CALLING API FOR UPDATE CMS
		editCMSDataApi(formData).then((response) => {
			navigate(-1)
			toast.success(response?.message ?? "CMS Updated.")
		}).catch((error) => {
			if (error?.status == 500) {
				toast.error('Unauthorized or server issue. Please refresh or try later. ')
			} else {
				toast.error(
					error?.error_description
						? error?.error_description
						: error?.details ? error?.details : error?.message
				);
			}
		}).finally(() => {
			setIsSubmitting(false)
		})
	}
	// FETCH CMS DATA FROM API
	const fetchCmsData = () => {
		setLoading(true)
		getCMSDataById(id).then(response => {
			setInitialValues({
				content_en: response?.content?.en,
				content_ar: response?.content?.ar,
				metaTitle_en: response?.metaTitle?.en,
				metaTitle_ar: response?.metaTitle?.ar,
				keywords_en: response?.metaKeywords?.en && response?.metaKeywords?.en !== "" ? (response?.metaKeywords?.en).split(',') : [],
				keywords_ar: response?.metaKeywords?.ar && response?.metaKeywords?.ar !== "" ? (response?.metaKeywords?.ar).split(',') : [],
				metaDescription_en: response?.metaDescription?.en,
				metaDescription_ar: response?.metaDescription?.ar
			})
			setPageTitle(response?.title?.en)
		}).catch((error) => {
			if (error?.status == 500) {
				toast.error('Unauthorized or server issue. Please refresh or try later. ')
			} else {
				toast.error(
					error?.error_description
						? error?.error_description
						: error?.details ? error?.details : error?.message
				);
			}
		}).finally(() => {
			setLoading(false)
		})
	}
	useEffect(() => {
		if (isAuthorized) {
			fetchCmsData()
		} else {
			setLoading(false)
		}

	}, [id, isAuthorized])
	// TOOLBAR MODULES FOR TEXT EDITOR
	const modules = {
		toolbar: [
			//   [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
			[{ 'size': [] }],
			['bold', 'italic', 'underline', 'strike'],
			[{ 'list': 'ordered' }, { 'list': 'bullet' },
			{ 'indent': '-1' }, { 'indent': '+1' }],
			//   ['link', 'image',],
			['link'],
			[{ 'align': '' }, { 'align': 'center' }, { 'align': 'right' }, { 'align': 'justify' }],
			[{ 'script': 'sub' }, { 'script': 'super' }], // Add super and sub options here
			['clean'], ,
		],

		clipboard: {
			// toggle to add extra line breaks when pasting HTML:
			matchVisual: false,
		},
	};
	const formats = [
		// 'header', 'font', 'size',
		'bold', 'italic', 'underline', 'strike',
		'list', 'bullet', 'indent',
		// 'link', 'image', 'video',
		'link',
		'script', // Include the script format
		'align'
	];
	return (
		<div className="d-flex flex-column pageContainer p-3 h-100 overflow-auto">
			<LoaderMu loading={loading} />
			<div className="flex-grow-1 pageContent position-relative pt-4">
				{
					!isAuthorized && !loading ?
						<NotAuthorized /> :
						<Card className="h-100 bg-white shadow border-0 theme-card-cover">
							<div className="theme-card-header px-1">
								<Stack direction="horizontal" gap={2} className="flex-wrap justify-content-between px-3 pt-3 pb-2 align-items-start">
									<h5 className="mb-0 position-relative fw-medium">
										<div className="align-items-center bg-primary d-inline-flex h-60 justify-content-center  position-absolute rounded start-0 text-white theme-icon-box w-60 z-1">
											<span className="theme-icon-box-inner"><PiFilesLight size={36} /></span>
										</div>
										Edit {pageTitle ?? 'CMS'}
									</h5>
								</Stack>
							</div>
							<div className="flex-grow-1 d-flex flex-column px-1">
								<div className="p-3 d-flex flex-column h-100">
									<Formik
										enableReinitialize={true}
										validationSchema={EditCmsSchema}
										initialValues={initialValues}
										onSubmit={(values, actions) => {
											actions.setSubmitting(false)
											handleSubmit(values, actions)
										}}
									>
										{({
											handleSubmit,
											handleChange,
											handleBlur,
											values,
											setFieldValue,
											touched,
											isValid,
											errors
										}) => (
											<Form className="d-flex flex-column flex-grow-1 theme-from">
												{/* META TITLE */}
												<Row className="gx-xl-5">
													<Col sm={6} md={6} lg={6} className="mb-4 pb-1">
														<InputText
															controlId="metaTitle_en"
															label="Meta Title (English)"
															value={values?.metaTitle_en}
															name="metaTitle_en"
															maxLength="70"
															errorsField={errors?.metaTitle_en}
															touched={touched?.metaTitle_en}
															handleChange={handleChange}
															handleBlur={handleBlur}
															className={"w-100 form-control"}
															variant="outlined"
														/>
													</Col>
													<Col sm={6} md={6} lg={6} className="mb-4 pb-1">
														<InputText
															dir="rtl"
															controlId="metaTitle_ar"
															label="Meta Title (Arabic)"
															value={values?.metaTitle_ar}
															name="metaTitle_ar"
															maxLength="70"
															errorsField={errors?.metaTitle_ar}
															touched={touched?.metaTitle_ar}
															handleChange={handleChange}
															handleBlur={handleBlur}
															className={"w-100 form-control"}
															variant="outlined"
														/>
													</Col>
												</Row>
												<Row className='gx-xl-5'>
													<Col sm={6} md={6} lg={6} className="mb-4 pb-1">
														<TagsInput
															label="Keywords(English)"
															placeholder="Keywords(English)"
															controlId="keywords_en"
															tags={values?.keywords_en ?? []}
															handleChange={(e, newValue) => setFieldValue("keywords_en", newValue)}
															errorsField={errors?.keywords_en}
															touched={touched?.keywords_en}
														/>
													</Col>
													<Col sm={6} md={6} lg={6} className="mb-4 pb-1">
														<TagsInput
															label="Keywords(Arabic)"
															placeholder="Keywords(Arabic)"
															controlId="keywords_ar"
															dir="rtl"
															tags={values?.keywords_ar ?? []}
															handleChange={(e, newValue) => setFieldValue("keywords_ar", newValue)}
															errorsField={errors?.keywords_ar}
															touched={touched?.keywords_ar}
														/>
													</Col>
												</Row>
												<Row className="gx-xl-5">
													<Col sm={6} md={6} lg={6} className="mb-4 pb-1">
														<InputText
															controlId="metaDescription_en"
															label="Meta Description (English)"
															value={values?.metaDescription_en}
															name="metaDescription_en"
															multiline={true}
															rows={4}
															maxLength="70"
															errorsField={errors?.metaDescription_en}
															touched={touched?.metaDescription_en}
															handleChange={handleChange}
															handleBlur={handleBlur}
															className={"w-100 form-control"}
															variant="outlined"
														/>
													</Col>
													<Col sm={6} md={6} lg={6} className="mb-4 pb-1">
														<InputText
															dir="rtl"
															controlId="metaDescription_ar"
															label="Meta Description (Arabic)"
															value={values?.metaDescription_ar}
															name="metaDescription_ar"
															multiline={true}
															rows={4}
															maxLength="70"
															errorsField={errors?.metaDescription_ar}
															touched={touched?.metaDescription_ar}
															handleChange={handleChange}
															handleBlur={handleBlur}
															className={"w-100 form-control"}
															variant="outlined"
														/>
													</Col>
												</Row>
												{/* CONTENT */}
												<Row className="gx-xl-5">
													<Col lg={12} className="mb-4 pb-1">
														<FormLabel className="mb-1 labelColor fs-16 fw-500">Content (English)</FormLabel>
														<ReactQuill
															modules={modules}
															formats={formats}
															name="content_en"
															theme="snow"
															value={values?.content_en}
															onChange={(value) => {
																if (value === "<p><br></p>") {
																	setFieldValue("content_en", '')
																} else {
																	setFieldValue("content_en", value)
																}
															}}
														/>
														<span className='text-danger answer-type-error fs-11'>{getIn(errors, `content_en`)}</span>
													</Col>
													<Col lg={12} dir="rtl" className="mb-4 pb-1">
														<FormLabel className="mb-1 labelColor fs-16 fw-500">Content (Arabic)</FormLabel>
														<ReactQuill
															dir="rtl"
															modules={modules}
															formats={formats}
															name="content_ar"
															theme="snow"
															className='rtl-textEditor'
															value={values.content_ar}
															onChange={(value) => {
																if (value === "<p><br></p>") {
																	setFieldValue("content_ar", '')
																} else {
																	setFieldValue("content_ar", value)
																}
															}}
														/>
														<span className='text-danger answer-type-error fs-11'>{getIn(errors, `content_ar`)}</span>
													</Col>
												</Row>
												<Stack direction="horizontal" gap={3} className="my-3">
													<Button
														variant="primary"
														onClick={handleSubmit}
														className="rounded-xs fs-15"
														type="submit"
														disabled={isSubmitting}
													>Update</Button>
													<Link onClick={() => navigate(-1)} className="btn btn-outline-secondary rounded-xs fs-15">Cancel</Link>
												</Stack>
											</Form>
										)}
									</Formik>
								</div>
							</div>
						</Card>
				}
			</div>
		</div>
	)
}

export default EditCms
