import React from 'react'
import {  Result } from 'antd';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { nameBasedProtectedRoutes } from '../../router/protected';

const NotAuthorized = () => {
  return (
    <Result
    status="403"
    title="403"
    subTitle="Sorry, you are not authorized to access this page."
    extra={<Link to={nameBasedProtectedRoutes?.dashboard?.path} className='btn btn-theme'>Back Home</Link>}
  />
  )
}

export default NotAuthorized