import { Modal } from 'antd'
import React from 'react'

const DetailsModal = ({ isOpen, setIsOpen, complaintDetails, setComplaintDetails }) => {

    // HANDLING MODAL CLOSE HERE
    const handleCancel = () => {
        setIsOpen(false)
        setComplaintDetails({})
    }
    return (
        <Modal title={`Complaint From ${complaintDetails?.name}`} open={isOpen} onCancel={handleCancel} footer={false} width={"600px"}>
            <div>
                <div className="row mt-2">
                    <div className="col-sm-12">
                        <p className='mb-2'><span className='fw-500'>IP Address   </span>{complaintDetails?.ipAddress ?? 'N/A'}</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <p className='mb-2'><span className='fw-500'>Complaint Reason   </span>{complaintDetails?.complaintReason ?? 'N/A'}</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <p><span className='fw-500'>Complaint Message   </span>{complaintDetails?.message ?? 'N/A'}</p>
                    </div>
                </div>


                <div>
                    <div className="row">
                        <div className="col-md-12">
                            <p className='fw-bold'>User Details</p>
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-sm-6">
                           <p className='mb-2 fw-bold text-secondary'>Name   </p> 
                           {
                            complaintDetails?.name ?  <p>{complaintDetails?.name} </p> :<p>{complaintDetails?.user?.firstName ?? 'N/A'} {complaintDetails?.user?.lastName ?? ''}</p>
                           }
                          
                        </div>
                        <div className="col-sm-6">
                           <p className='mb-2 fw-bold text-secondary'>Email   </p> 
                           {
                            complaintDetails?.email ?  <p>{complaintDetails?.email ?? 'N/A'} </p> : <p>{complaintDetails?.user?.email ?? 'N/A'} </p>
                           }
                          
                        </div>
                    </div>
                </div>

                <div>
                    <div className="row">
                        <div className="col-md-12">
                            <p className='fw-bold'>Property Details</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                           <p className='mb-2 fw-bold text-secondary'>Title   </p> 
                           <p>{complaintDetails?.property?.title?.en ?? 'N/A'}</p>
                        </div>
                        <div className="col">
                           <p className='mb-2 fw-bold text-secondary'>Amaken Id   </p> 
                           <p>{complaintDetails?.property?.amakenId ?? 'N/A'} </p>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="row">
                        <div className="col-md-12">
                            <p className='fw-bold'>Agent Details</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                           <p className='mb-2 fw-bold text-secondary'>Name   </p> 
                           <p>{complaintDetails?.property?.agentVM?.firstName ?? 'N/A'} {complaintDetails?.property?.agentVM?.lastName ?? 'N/A'}</p>
                        </div>
                        <div className="col">
                           <p className='mb-2 fw-bold text-secondary'>Email   </p> 
                           <p>{complaintDetails?.property?.agentVM?.email ?? 'N/A'} </p>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="row">
                        <div className="col-md-12">
                            <p className='fw-bold'>Agency Details</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                           <p className='mb-2 fw-bold text-secondary'>Agency Name   </p> 
                           <p>{complaintDetails?.property?.agency?.name ?? 'N/A'}</p>
                        </div>
                        <div className="col">
                           <p className='mb-2 fw-bold text-secondary'>Contact Name   </p> 
                           <p>{complaintDetails?.property?.agency?.contactName ?? 'N/A'} </p>
                        </div>
                        <div className="col">
                           <p className='mb-2 fw-bold text-secondary'>Email   </p> 
                           <p>{complaintDetails?.property?.agency?.email ?? 'N/A'} </p>
                        </div>
                    </div>
                </div>

             
            </div>
        </Modal>
    )
}

export default DetailsModal