import React from "react";
import RolesList from "./List/RolesList";
import EditRoles from "./edit/EditRoles";
import { Route, Routes } from "react-router-dom";
import { Error404 } from "../Error404";

export const Roles_Permissions = () => {
  return (
    <Routes>
      <Route path="/" element={<RolesList />} />
      <Route path="/edit/:role_id" element={<EditRoles />} />
      <Route path="*" element={<Error404/>}/>
    </Routes>
  );
}

export const nameBasedRoutes = {
  roles : {
    name : "RoleList",
    path : "/app/roles"
  },
  roleEdit : {
    name : "RoleEdit",
    path : "/app/roles/edit"
  }
}

