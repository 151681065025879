import { axios } from "./axios"
import {
  FORGOT_PASSWORD_API,
  LOGIN_API,
  LOGOUT,
  RESET_PASSWORD_API,
  VERIFY_ACCOUNT,
} from "../constants/paths"

/**
 * Login API Call
 * @param {LoginCredentialsDTO} data
 * @returns {Promise<any>}
 */
// AMAKEN LOGIN
export const login = async(data) => {
  const response =  await axios.post(LOGIN_API, data)
  return response?.data
}
// AMAKEN FORGOT PASSWORD
export const forgotPassword = async (data) => {
  const response = await axios.post(FORGOT_PASSWORD_API, data)
  return response?.data
}
// AMAKEN RESET PASSWORD
export const resetPassword =async (data) => {
  const response = await axios.post(RESET_PASSWORD_API,data)
  return response?.data
}

// AMAKEN VERIFY ACCOUNT
export const verifyAccount = async (data) => {
  const response = await axios.post(VERIFY_ACCOUNT,data)
  return response?.data
}

// export const logout = async(data) => {
//   const response = axios.post(LOGOUT, data)
//   return response?.data
// }

/**
 * Forgot Password API Call
 * @param  data
 * @returns {Promise<any>}
 */

/**
 * Reset Password API Call
 *
 * @param {ResetPasswordDTO} data
 * @returns {Promise<any>}
 */


/**
 * Logout API Call
 * @param {LogoutDTO} data
 * @returns {Promise<any>}
 */


/**
 * Resend MFA Code API Call
 *
 * @returns {Promise<any>}
 */
// export const resendMFACode = data => {
//   return axios.post(RESEND_CODE, data)
// }

/**
 * Refresh Token API
 *
 * @param {LogoutDTO} data
 * @returns {Promise<any>}
 */
// export const refreshTokenCALL = data => {
//   return axios.post(REFRESH_TOKEN, data)
// }
