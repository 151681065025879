import React, { useEffect, useState } from 'react'
import { Card, Col, Row, Stack } from 'react-bootstrap'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { FaUserEdit } from "react-icons/fa";
import { getWebsiteUsersById, updateWebsiteUsersAPI } from '../../../services/userService'
import { toast } from 'react-toastify'
import LoaderMu from '../../../components/common/LoaderMu';
import DefaultImg from "../../../assets/images/default-image.jpg";
import { Switch } from 'antd';
import { useSelector } from 'react-redux';
import NotAuthorized from '../../../components/common/NotAuthorized';

function View() {

  // MANAGING USER PERMISSIONS HERE

  const roleDetails = useSelector((state) => state?.rolesData?.masterPermissionResponseDTOList)

  const [isAuthorized, setIsAuthorized] = useState(false)

  useEffect(() => {

    if (roleDetails) {
      const permissions = roleDetails.find((detail) => {
        return detail.childPermissions.find((childPerm) => (
          childPerm?.key === "platform_users_management_detail" && childPerm?.status === true
        ))
      })
      if (permissions) {
        setIsAuthorized(true)
        // Create an object from childPermissions

      } else {
        setIsAuthorized(false)
      }
    } else {
      setIsAuthorized(true)
    }

  }, [roleDetails])

  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [userDetailData, setUserDetailData] = useState([])
  const [mutation, setMutation] = useState(true);

  const { id } = useParams()

  // FETCH USER DATA FOR INITIAL FORM FILL
  const getUserData = () => {
    setLoading(true)
    // GETTING DATA FROM API
    let params = {
      'userId': id
    }
    getWebsiteUsersById(params).then(response => {
      setUserDetailData(response?.data)
      setLoading(false)
    }).catch((error) => {
       if(error?.status == 500){
                toast.error('Unauthorized or server issue. Please refresh or try later. ')
            }else{
                 toast.error(
          error?.error_description
            ? error?.error_description
            : error?.details ? error?.details : error?.message
        );
            }
    }).finally(() => {
      setLoading(false)

    })
  }

  // HANDLE USER STATUS TOGGLE
  const statusToggle = (value, id) => {
    setLoading(true)
    const formData = {
      id: id,
      status: value
    }
    // CALLING API FOR CREATE PLATFORM USER
    updateWebsiteUsersAPI(formData).then(response => {
      setLoading(false)
      //fetchUsersData()
      toast.success(response?.message ?? 'Status updated!')
      setMutation(!mutation)
    }).catch((error) => {
      setLoading(false)
       if(error?.status == 500){
                toast.error('Unauthorized or server issue. Please refresh or try later. ')
            }else{
                 toast.error(
          error?.error_description
            ? error?.error_description
            : error?.details ? error?.details : error?.message
        );
            }
    })

  }

  useEffect(() => {
    if (isAuthorized) {
      getUserData()
    } else {
      setLoading(false)
    }
  }, [mutation, isAuthorized])

  return (
    <div>
      <LoaderMu loading={loading} />
      {/* IF USER IS AUTHORIZED THEN ONLY SHOW PAGE CONTENT */}
      {
        !isAuthorized && !loading ?
          <NotAuthorized /> :
          <Card className="bg-white shadow border-0 theme-card-cover pt-20 p-50">
            <div className="theme-card-header px-1 border-bottom border-light ">
              <Stack
                direction="horizontal"
                gap={2}
                className="flex-wrap justify-content-between px-3 pt-3 pb-2 align-items-start"
              >
                <h5 className="mb-0 position-relative fs-18 text-black fw-medium">
                  <div className="align-items-center bg-primary d-inline-flex h-60 justify-content-center  position-absolute rounded start-0 text-white theme-icon-box w-60 z-1">
                    <FaUserEdit size={40} />
                  </div>
                  User Details
                </h5>
                <div>
                  <Link className='btn btn-secondary text-white fw-bold my-1' onClick={() => navigate(-1)}>Back</Link>
                </div>
              </Stack>
            </div>
            <div className="flex-grow-1 d-flex flex-column px-3 py-3 h-100 overflow-auto">
              <Row>
                <Col sm={5} lg={2}>
                  {
                    userDetailData?.imageUrl && userDetailData?.imageUrl !== null ?
                      <img src={userDetailData?.imageUrl} className='img-fluid' /> : <img src={DefaultImg} className='img-fluid' />
                  }
                </Col>
                <Col sm={12} lg={10}>
                  <Row>
                    <Col sm={5} lg={4}>
                      <p className='fs-14 mb-3'>First Name</p>
                      <p className="fw-500 fs-16">{userDetailData?.firstName ?? <span className='text-danger'>NA</span>}</p>
                    </Col>
                    <Col sm={5} lg={4}>
                      <p className='fs-14 mb-3'>Last Name</p>
                      <p className="fw-500 fs-16">{userDetailData?.lastName ?? <span className='text-danger'>NA</span>}</p>
                    </Col>
                    <Col sm={5} lg={4}>
                      <p className='fs-14 mb-3'>Email</p>
                      <p className="fw-500 fs-16">{userDetailData?.email ?? <span className='text-danger'>NA</span>}</p>
                    </Col>
                  </Row>
                  <Row className='pt-2'>
                    <Col sm={5} lg={4}>
                      <p className='fs-14 mb-3'>Address</p>
                      <p className="fw-500 fs-16">{userDetailData?.address ?? <span className='text-danger'>NA</span>}</p>
                    </Col>
                    <Col sm={5} lg={4}>
                      <p className='fs-14 mb-3'>Mobile No</p>
                      <p className="fw-500 fs-16">{userDetailData?.mobileNumber ?? <span className='text-danger'>NA</span>}</p>
                    </Col>
                    {/* USER STATUS TOGGLE */}
                    <Col sm={5} lg={4}>
                      <p className='fs-14 mb-3'>Status</p>
                      <p className="fw-500 fs-16">
                        <Switch checked={userDetailData?.status == 'ENABLED' ? true : false} checkedChildren={userDetailData?.status} unCheckedChildren={userDetailData?.status}
                          className='fw-bold' onChange={(value) => { statusToggle(value == true ? 'ENABLED' : 'DISABLED', userDetailData?.id) }} /></p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Card>}
    </div>
  )
}

export default View

