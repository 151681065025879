import React, { useEffect, useState } from "react";
import { Button, Card, Stack } from "react-bootstrap";
import { MdSupervisorAccount } from "react-icons/md";
import {  useNavigate, useParams } from "react-router-dom";
import { Checkbox, Empty } from "antd";
import { amenityListApi, propertyDetailsApi, saveAmenitiesApi } from "../../../services/propertyType";
import LoaderMu from "../../../components/common/LoaderMu";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import NotAuthorized from "../../../components/common/NotAuthorized";
export const AmenitiesConfig = () => {

  // MANAGING PERMISSIONS HERE

  const roleDetails = useSelector((state) => state?.rolesData?.masterPermissionResponseDTOList)

  const [isAuthorized, setIsAuthorized] = useState(false)

  useEffect(() => {
    if (roleDetails) {
      const permissions = roleDetails.find((detail) => {
        return detail.childPermissions.find((childPerm) => (
          childPerm?.key === "property_type_amenities" && childPerm?.status === true
        ))
      })
      if (permissions) {
        setIsAuthorized(true)
      } else {
        setIsAuthorized(false)
      }
    } else {
      setIsAuthorized(true)
    }

  }, [roleDetails])

  const navigate = useNavigate()
  const [amenityList, setAmenityList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [checkAmenities, setCheckAmenities] = useState([]);

  const { id } = useParams();
  // FUNCTION FOR FETCH ROLE DATA 
  const fetchAmenityList = () => {
    setLoading(true)
    // CALLING API SERVICE FOR ROLE DATA
    amenityListApi()
      .then(async (response) => {
        // SET DATA IN STATE
        if (response?.length > 0) {
          const enabled_amenities = response?.filter((element) => element?.status === true)
          setAmenityList(enabled_amenities);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  // FUNCTION FOR FETCH PROPERTY DETAILS FOR FIND SELECTED AMENITIES 
  const fetchPropertyDetails = () => {
    setLoading(true)
    // CALLING API SERVICE FOR ROLE DATA
    propertyDetailsApi(id)
      .then(async (response) => {
        const amenities = response?.amenityList?.map((amenity) => (amenity?.id))

        setCheckAmenities(amenities ?? [])
        // SET DATA IN STATE
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  // CALLING FUNCTION FETCH ROLE DATA
  useEffect(() => {
    if (isAuthorized) {
      fetchAmenityList();
      fetchPropertyDetails()
    } else {
      setLoading(false)
    }
  }, [isAuthorized]);

  //FUNCTION FOR HANDLE PERMISSION TOGGLE
  const handlePropertyTypeToggle = (event, p_type) => {
    // IF PERMISSION CHECKED FALSE THEN MARK AS UNCHECKED AND REMOVE FROM STATE
    if (event.target.checked === false) {
      const filterArray = checkAmenities.filter(
        (element) => element !== p_type
      );
      setCheckAmenities(filterArray);
    } else {
      // IF NEW PERMISSION CHECKED THEN ADD IN STATE
      if (checkAmenities?.length > 0) {
        setCheckAmenities((prev) => Array.from(new Set([...prev, p_type])));
      } else {
        setCheckAmenities([p_type])
      }

    }
  };

  // CALLING FUNCTION ON SAVE BUTTON CLICK
  const handleUpdate = () => {
    setLoading(true)
    // CALLING API SERVICE FOR UPDATE ROLES AND PERMISSION
    saveAmenitiesApi(id, checkAmenities)
      .then((response) => {
        setLoading(false)
        // DISPLAY MESSAGE AFTER SUCCESSFUL UPDATE
        // navigate(nameBasedRoutes?.List?.path)
        navigate(-1)
        toast.success(response?.message);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  return (
    <div className="d-flex flex-column pageContainer p-3 h-100">
      <LoaderMu loading={loading} />
      <div className="flex-grow-1 h-100 pageContent position-relative pt-4 overflow-auto">
        {
          !isAuthorized && !loading ? <NotAuthorized /> :
            <Card className="h-100 bg-white shadow border-0 theme-card-cover">
              <div className="theme-card-header px-1">
                <Stack
                  direction="horizontal"
                  gap={2}
                  className="flex-wrap justify-content-between px-3 pt-3 pb-2 align-items-start"
                >
                  <h5 className="mb-0 position-relative fs-18 text-black fw-medium">
                    <div className="align-items-center bg-primary d-inline-flex h-60 justify-content-center  position-absolute rounded start-0 text-white theme-icon-box w-60 z-1">
                      <MdSupervisorAccount size={40} />
                    </div>
                    Amenities Config
                  </h5>
                </Stack>
              </div>
              <div className="flex-grow-1 d-flex flex-column px-3 h-100 overflow-auto mt-4">
                <div className="row bg-light-custom border-bottom border-light">
                  <div className="col-md-12">
                    <div className="p-3 ">
                      <h5>Amenities</h5>
                    </div>
                  </div>
                </div>
                {amenityList && amenityList?.length > 0 ? (
                  <div className="row">
                    {amenityList.map((element, index) => {
                      return (
                        <div key={index + 1} className="col-sm-4 col-md-3 my-3">
                          <Checkbox
                            checked={checkAmenities?.includes(element?.id)}
                            onChange={(event) =>
                              handlePropertyTypeToggle(
                                event,
                                element?.id
                              )
                            }
                          >
                            <span className="fw-500" >{element?.amenityName}</span>
                          </Checkbox>
                        </div>
                      );
                    })}
                    <div className="mt-4">
                      <Button
                        variant="primary"
                        onClick={handleUpdate}
                        className="rounded-xs fs-15 me-2"
                        type="submit"
                      >
                        Save
                      </Button>
                      {/* <Link to={nameBasedRoutes?.List?.path} className="btn btn-outline-secondary rounded-xs fs-15">Cancel</Link> */}

                      <Button variant="outline-secondary"
                        onClick={() => { navigate(-1) }}
                        className="rounded-xs fs-15">Cancel</Button>
                    </div>
                  </div>
                ) : (
                  <Empty /> // COMPONENT IF NO DATA AVAILABLE TO DISPLAY
                )}
              </div>
            </Card>}
      </div>
    </div>
  );
}

