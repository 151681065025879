import { MAIN_STATS_API, PROPERTY_CHART_API, PROPERTY_TYPE_CHART_API, USERS_CHART_API, USERS_COUNT_CHART_API } from "../constants/paths";
import { axios } from "./axios";

// MAIN STATS API
export const mainStatsApi = async (params) => {
  const response = await axios.get(MAIN_STATS_API, { params })
  return response?.data
}

// PROPERTY CHART API
export const propertyChartApi = async () => {
  const response = await axios.get(PROPERTY_CHART_API)
  return response?.data
}

// USERS BY YEAR CHART API
export const usersChartApi = async () => {
  const response = await axios.get(USERS_CHART_API)
  return response?.data
}

// PROPERTY TYPE CHART API
export const propertyTypeChartApi = async (params) => {
  const response = await axios.get(PROPERTY_TYPE_CHART_API, { params })
  return response?.data
}

// USERS PIE CHART API
export const usersCountChart = async (params) => {
  const response = await axios.get(USERS_COUNT_CHART_API, { params })
  return response?.data
}


// COMPONENT SERVICE
export const capitalizeAndSeparate = (header) =>{
  // Separate words where uppercase letters are found
  const separated = header.replace(/([a-z])([A-Z])/g, '$1 $2');
  // Capitalize each word
  const converted =  separated.split(" ").map(part => part.charAt(0).toUpperCase() + part.slice(1)).join(" ");
 return converted
}

// CREATE CSV DATA FORMAT
export const convertToCSV = (data) => {
  if (!data || data.length === 0) {
    return '';
  }

  // Extracting keys from the first object to use as CSV headers
  // const headers = Object.keys(data[0]);

  const headers = Object.keys(data[0]).map(header => capitalizeAndSeparate(header));
  // console.log(headers)

  const dataHeaders = Object.keys(data[0]);

  // Generating rows for each object
  const rows = data.map(obj => {
    return dataHeaders.map(key => {
      // If the value is an object, convert it to a string
      if (typeof obj[key] === 'object') {
        return obj[key].en || obj[key].ar || '';
      }
      return obj[key];
    }).join(',');
  });

  // console.log(headers)

  // Joining headers and rows into a CSV string
  return [headers.join(','), ...rows].join('\n');
};
