import { Form, Formik } from 'formik'
import React, { useState } from 'react'
import { Button, Card, Col, Row, Stack } from 'react-bootstrap'
import InputText from '../../../components/common/InputText'
import { useNavigate, useParams } from 'react-router-dom'
import { FaLock } from 'react-icons/fa';
import { changePasswordPlatformUser } from '../../../services/systemUserService'
import { toast } from 'react-toastify'
import { changePasswordSystemUserchema } from '../../../validations/ValidationSchema'

function ChangePassword() {
    const { id } = useParams()
    const navigate = useNavigate()
    const [isSubmitting, setIsSubmitting] = useState(false)
    // CHANGE PASSWORD FORM SUBMIT
    const handleSubmit = (values, actions) => {
        setIsSubmitting(true)
        const formData = {
            id: id,
            password: values?.password,
        }
        // CALLING API FOR CREATE PLATFORM USER
        changePasswordPlatformUser(formData).then((response) => {
            // navigate(nameBasedRoutes?.platformUser?.path)
            navigate(-1) // TO REDIRECT ON PREVIOUS PAGE
            actions.resetForm()
            toast.success(response?.message ?? "Password updated succesfully.")
        }).catch((error) => {
            if(error?.status == 500){
                toast.error('Unauthorized or server issue. Please refresh or try later. ')
            }else{
                 toast.error(
          error?.error_description
            ? error?.error_description
            : error?.details ? error?.details : error?.message
        );
            }
        }).finally(() => {
            setIsSubmitting(false)
        })
    }
    return (
        <Card className='bg-white shadow border-0 theme-card-cover mt-5'>
            <div className="theme-card-header px-1">
                <Stack direction="horizontal" gap={2} className="flex-wrap justify-content-between px-3 pt-3 pb-2 align-items-start">
                    <h5 className="mb-0 position-relative fw-medium">
                        <div className="align-items-center bg-primary d-inline-flex h-60 justify-content-center  position-absolute rounded start-0 text-white theme-icon-box w-60 z-1">
                            <span className="theme-icon-box-inner"><FaLock size={36} /></span>
                        </div>
                        Change Password
                    </h5>
                </Stack>
            </div>
            <div className="flex-grow-1 d-flex flex-column px-1">
                <div className="p-3 d-flex flex-column h-100">
                    <Formik
                        validationSchema={changePasswordSystemUserchema}
                        initialValues={{
                            password: "",
                            confirm_password: ""
                        }}
                        onSubmit={(values, actions) => {
                            actions.setSubmitting(false)
                            handleSubmit(values, actions)
                        }}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            handleBlur,
                            values,
                            setFieldValue,
                            touched,
                            isValid,
                            errors
                        }) => (
                            <Form>
                                <Row className="gx-xl-5">
                                    {/* CREATE PASSWORD */}
                                    <Col sm={6} md={6} lg={4} className="mb-4 pb-1">
                                        <InputText
                                            type="password"
                                            controlId="password"
                                            label="Password"
                                            value={values?.password ?? ''}
                                            name="password"
                                            maxLength="70"
                                            errorsField={errors?.password}
                                            touched={touched?.password}
                                            handleChange={handleChange}
                                            handleBlur={handleBlur}
                                            className={`w-100 form-control ${touched?.confirm_password && errors?.confirm_password
                                                ? "error"
                                                : ""
                                                }`}
                                            variant="outlined"
                                        />
                                    </Col>
                                    {/* CONFIRM PASSWORD */}
                                    <Col sm={6} md={6} lg={4} className="mb-4 pb-1">
                                        <InputText
                                            type="password"
                                            controlId="confirm_password"
                                            label="Confirm Password"
                                            value={values?.confirm_password ?? ''}
                                            name="confirm_password"
                                            errorsField={errors?.confirm_password}
                                            touched={touched?.confirm_password}
                                            handleChange={handleChange}
                                            handleBlur={handleBlur}
                                            className={`w-100 form-control ${touched?.confirm_password && errors?.confirm_password
                                                ? "error"
                                                : ""
                                                }`}
                                            variant="outlined"
                                        />
                                    </Col>
                                </Row>
                                <Stack direction="horizontal" gap={3} className="my-3">
                                    <Button
                                        variant="primary"
                                        onClick={handleSubmit}
                                        className="rounded-xs fs-15"
                                        type="submit"
                                        disabled={isSubmitting ?? false}
                                    >Save</Button>
                                    <Button variant="outline-secondary"
                                        onClick={() => { navigate(-1) }}
                                        className="rounded-xs fs-15">Cancel</Button>
                                </Stack>
                            </Form>)}
                    </Formik>
                </div>
            </div>
        </Card>
    )
}

export default ChangePassword
