import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { List } from './List'

export const Audit = () => {
  return (
    <Routes>
        <Route path="/audit" element={<List/>} />
    </Routes>
  )
}

