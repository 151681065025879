import React, { useEffect, useState } from 'react'
import { Button, Card, Col, FormLabel, Row, Stack } from 'react-bootstrap'
import { PiFilesLight } from 'react-icons/pi'
import { agencyReportApi, planListApi } from '../../services/reportService'
import { toast } from 'react-toastify'
import { Form, Formik } from 'formik'
import dayjs from 'dayjs'
import { DatePicker } from 'antd'
import moment from 'moment'
import { CustomSelect } from '../../components/common/CustomSelect'
import { useSelector } from 'react-redux'
import NotAuthorized from '../../components/common/NotAuthorized'
const { RangePicker } = DatePicker;

const AgencyReport = () => {

	// MANAGING USER PERMISSIONS HERE

	const roleDetails = useSelector((state) => state?.rolesData?.masterPermissionResponseDTOList)

	const [isAuthorized, setIsAuthorized] = useState(false)

	useEffect(() => {

		if (roleDetails) {
			const permissions = roleDetails.find((detail) => {
				return detail.childPermissions.find((childPerm) => (
					childPerm?.key === "reports_agency" && childPerm?.status === true
				))
			})
			if (permissions) {
				setIsAuthorized(true)
			} else {
				setIsAuthorized(false)
			}
		} else {
			setIsAuthorized(true)
		}

	}, [roleDetails])

	const [loading, setLoading] = useState(true)

	const [planList, setPlanList] = useState([])

	const dateFormat = "YYYY-MM-DD";

	// First date of the year
	const firstDateOfYear = moment().startOf('year').format(dateFormat);

	// Last date of the year
	const lastDateOfYear = moment().endOf('year').format(dateFormat);

	const handleReportDownload = (values, actions) => {
		// CALL API HERE TO GET FILE DATA
		setLoading(true)
		const params = {
			startDate: values?.dateRange[0] ? dayjs(values?.dateRange[0]).format(dateFormat) : "",
			endDate: values?.dateRange[1] ? dayjs(values?.dateRange[1]).format(dateFormat) : "",
			// deviceTypeList : values?.deviceType
		}

		if (values?.status && values?.status !== 'ALL') {
			params.status = values?.status
		}

		if (values?.plan) {
			params.planId = values?.plan
		}


		agencyReportApi(params).then((response) => {
			if (response?.data) {
				const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
				const blobUrl = window.URL.createObjectURL(blob);

				const tempLink = document.createElement('a');
				tempLink.href = blobUrl;
				tempLink.setAttribute('download', 'Agency.xlsx');

				// Append the link to the document body before clicking it
				document.body.appendChild(tempLink);

				tempLink.click();

				// Clean up by revoking the Blob URL
				window.URL.revokeObjectURL(blobUrl);

				// Remove the link from the document body after clicking
				document.body.removeChild(tempLink);
			} else {
				throw new Error('Response data is empty.');
			}
		}).catch((error) => {

			console.log(error)
			if (error?.status == 500) {
				toast.error('Unauthorized or server issue. Please refresh or try later. ')
			} else {
				toast.error(
					error?.error_description
						? error?.error_description
						: error?.details ? error?.details : error?.message
				);
			}
		}).finally(() => {
			setLoading(false)
		})
	}

	// FETCH PLAN LIST
	const getPlanList = () => {
		setLoading(true)
		planListApi().then(response => {
			const plan_list = response?.map((plans) => {
				return { value: plans?.id, label: plans?.planName }
			})

			setPlanList(plan_list)
		}).catch((error) => {
			if (error?.status == 500) {
				toast.error('Unauthorized or server issue. Please refresh or try later. ')
			} else {
				toast.error(
					error?.error_description
						? error?.error_description
						: error?.details ? error?.details : error?.message
				);
			}
		}).finally(() => {
			setLoading(false)
		})
	}

	useEffect(() => {
		if (isAuthorized) {
			getPlanList()
		} else {
			setLoading(false)
		}
	}, [isAuthorized])
	return (
		<div className="d-flex flex-column pageContainer p-3 h-100">
			<div className="flex-grow-1 h-100 pageContent position-relative pt-4 overflow-auto">
				{
					//!isAuthorized && !loading ?
						//<NotAuthorized /> :
						
						 <Card className="h-100 bg-white shadow border-0 theme-card-cover">
							<div className="theme-card-header px-1">
								<Stack
									direction="horizontal"
									gap={2}
									className="flex-wrap justify-content-between px-3 pt-3 pb-2 align-items-start"
								>
									<h5 className="mb-0 position-relative fs-18 text-black fw-medium">
										<div className="align-items-center bg-primary d-inline-flex h-60 justify-content-center  position-absolute rounded start-0 text-white theme-icon-box w-60 z-1">
											<PiFilesLight size={40} />
										</div>
										Agency Report
									</h5>
								</Stack>
							</div>
							<div className="flex-grow-1 d-flex flex-column px-1">
								<div className="p-3 d-flex flex-column h-100">
									<Formik
										enableReinitialize={true}
										initialValues={{
											plan: '',
											status: 'ALL',
											dateRange: [dayjs(firstDateOfYear, dateFormat), dayjs(lastDateOfYear, dateFormat)]
										}}
										onSubmit={(values, actions) => {
											actions.setSubmitting(false)
											handleReportDownload(values, actions)
										}}
									>
										{({
											handleSubmit,
											handleChange,
											handleBlur,
											values,
											setFieldValue,
											touched,
											isValid,
											errors
										}) => (
											<Form className="d-flex flex-column flex-grow-1 theme-from">
												<Row>
													<Col sm={6} md={6} lg={6} className="mb-4 pb-1">
														<CustomSelect
															options={planList ?? []}
															controlId="plan"
															label="Plan"
															value={values?.plan}
															name="plan"
															variant="outlined"
															errorsField={errors?.plan}
															touched={touched?.plan}
															handleChange={(value) => {
																setFieldValue("plan", value)
															}}
															handleBlur={handleBlur}
															onChange={handleChange}
														/>
													</Col>
													<Col sm={6} md={6} lg={6} className="mb-4 pb-1">
														<CustomSelect

															controlId="status"
															label="Status"
															value={values?.status}
															name="status"
															variant="outlined"
															errorsField={errors?.status}
															touched={touched?.status}
															handleChange={(value) => {
																setFieldValue("status", value)
															}}
															handleBlur={handleBlur}
															onChange={handleChange}
															options={[
																{ value: 'ALL', label: "All" },
																{ value: "ACTIVE", label: "Active" },
																{ value: "INACTIVE", label: "Inactive" },
																{ value: "REJECTED", label: "Rejected" },
															]}
														/>
													</Col>

												</Row>

												<Row>
													<Col sm={6} md={6} lg={6} className="mb-4 pb-1">
														<FormLabel className='d-block'>
															Select Date
														</FormLabel>
														<RangePicker
															controlId="dateRange"
															name="dateRange"
															value={values?.dateRange}
															className={`form-rangepicker antd-rangePicker`}
															// defaultValue={[dayjs(startDate, dateFormat), dayjs(endDate, dateFormat)]}
															format={dateFormat}
															onChange={(date, dateString) => {
																setFieldValue("dateRange", date)
															}}
															footer={true}
															allowClear={false}
														/>
													</Col>
												</Row>
												<Stack direction="horizontal" gap={3} className="my-3">
													<Button
														variant="primary"
														onClick={handleSubmit}
														className="rounded-xs fs-15"
														type="submit"
														disabled={loading ?? false}
													>Download Report</Button>
												</Stack>
											</Form>
										)}
									</Formik>
								</div>
							</div>
						</Card>
				}

			</div>
		</div>
	)
}

export default AgencyReport