import React, { useEffect, useState } from "react";
import { Button, Card, Stack } from "react-bootstrap";
import { MdSupervisorAccount } from "react-icons/md";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Checkbox, Empty } from "antd";
import { toast } from "react-toastify";
import { nameBasedRoutes } from "../Routes";
import { fetchDetailsListApi, propertyDetailsApi, savePropertyTypesApi } from "../../../services/propertyType";
import LoaderMu from "../../../components/common/LoaderMu";
import { useSelector } from "react-redux";
import NotAuthorized from "../../../components/common/NotAuthorized";
export const DetailsConfig = () => {

  // MANAGING PERMISSIONS HERE

  const roleDetails = useSelector((state) => state?.rolesData?.masterPermissionResponseDTOList)

  const [isAuthorized, setIsAuthorized] = useState(false)

  useEffect(() => {
    if (roleDetails) {
      const permissions = roleDetails.find((detail) => {
        return detail.childPermissions.find((childPerm) => (
          childPerm?.key === "property_type_property_details" && childPerm?.status === true
        ))
      })
      if (permissions) {
        setIsAuthorized(true)
      } else {
        setIsAuthorized(false)
      }
    } else {
      setIsAuthorized(true)
    }

  }, [roleDetails])

  const navigate = useNavigate()
  const [propertyTypeList, setPropertyTypeList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [checkTypes, setCheckTypes] = useState([]);

  const { id } = useParams();


  // FUNCTION FOR FETCH PROPERTY LIST 
  const fetchDetailsList = () => {
    setLoading(true)
    // CALLING API SERVICE FOR ROLE DATA
    propertyDetailsApi(id)
      .then(async (response) => {
        // SET DATA IN STATE
        setPropertyTypeList(response?.propertyDetail);


        const checkedDetails = response?.propertyDetail
          ?.filter((element) => element?.visible === true)
          .map((element) => element?.field_name);

        setCheckTypes(checkedDetails)
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };
  // CALLING FUNCTION FETCH PROPERTY LIST
  useEffect(() => {
    if (isAuthorized) {
      fetchDetailsList();
    } else {
      setLoading(false)
    }
  }, [isAuthorized]);

  //FUNCTION FOR HANDLE PERMISSION TOGGLE
  const handlePropertyTypeToggle = (event, p_type) => {
    // IF PERMISSION CHECKED FALSE THEN MARK AS UNCHECKED AND REMOVE FROM STATE
    if (event.target.checked === false) {
      const filterArray = checkTypes.filter(
        (element) => element !== p_type
      );
      setCheckTypes(filterArray);
    } else {
      // IF NEW PERMISSION CHECKED THEN ADD IN STATE
      setCheckTypes((prev) => Array.from(new Set([...prev, p_type])));
    }
  };

  // CALLING FUNCTION ON SAVE BUTTON CLICK
  const handleUpdate = () => {
    setLoading(true)
    // CALLING API SERVICE FOR UPDATE ROLES AND PERMISSION
    savePropertyTypesApi(id, checkTypes)
      .then((response) => {
        setLoading(false)
        // DISPLAY MESSAGE AFTER SUCCESSFUL UPDATE
        // navigate(nameBasedRoutes?.List?.path)
        navigate(-1)
        toast.success(response?.message);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  return (
    <div className="d-flex flex-column pageContainer p-3 h-100">
      <LoaderMu loading={loading} />
      <div className="flex-grow-1 h-100 pageContent position-relative pt-4 overflow-auto">
      {
        !loading && !isAuthorized ? <NotAuthorized/> : 
        <Card className="h-100 bg-white shadow border-0 theme-card-cover">
          <div className="theme-card-header px-1">
            <Stack
              direction="horizontal"
              gap={2}
              className="flex-wrap justify-content-between px-3 pt-3 pb-2 align-items-start"
            >
              <h5 className="mb-0 position-relative fs-18 text-black fw-medium">
                <div className="align-items-center bg-primary d-inline-flex h-60 justify-content-center  position-absolute rounded start-0 text-white theme-icon-box w-60 z-1">
                  <MdSupervisorAccount size={40} />
                </div>
                Details Config
              </h5>
            </Stack>
          </div>
          <div className="flex-grow-1 d-flex flex-column px-3 h-100 overflow-auto mt-4">
            <div className="row bg-light-custom border-bottom border-light">
              <div className="col-md-12">
                <div className="p-3 ">
                  <h5>Title</h5>
                </div>
              </div>
            </div>

            {propertyTypeList && propertyTypeList?.length > 0 ? (
              <div className="row">
                {propertyTypeList.map((element, index) => {
                  return (
                    <div key={index + 1} className="col-sm-4 col-md-3 my-3">
                      <Checkbox
                        defaultChecked={element?.visible ?? false}
                        onChange={(event) =>
                          handlePropertyTypeToggle(
                            event,
                            element?.field_name
                          )
                        }
                      >
                        <span className="fw-500" >{element?.field_level?.en}</span>
                      </Checkbox>
                    </div>
                  );
                })}
                <div className="mt-4">
                  <Button
                    variant="primary"
                    onClick={handleUpdate}
                    className="rounded-xs fs-15 me-2"
                    type="submit"
                  >
                    Save
                  </Button>
                  {/* <Link to={nameBasedRoutes?.List?.path} className="btn btn-outline-secondary rounded-xs fs-15">Cancel</Link> */}
                  <Button variant="outline-secondary"
                    onClick={() => { navigate(-1) }}
                    className="rounded-xs fs-15">Cancel</Button>
                </div>
              </div>
            ) : (
              <Empty /> // COMPONENT IF NO DATA AVAILABLE TO DISPLAY
            )}
          </div>
        </Card>
      }
      
      </div>
    </div>
  );
}

