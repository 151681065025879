import React, { useState, useEffect } from 'react'
import { Card, Col, Row, Stack } from 'react-bootstrap';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { PiFilesLight } from "react-icons/pi";
import { IoChevronBack } from "react-icons/io5";
import Loader from "../../../components/common/Loader";
import { getCMSDataById } from '../../../services/cmsService';
import { toast } from 'react-toastify';
import NotAuthorized from '../../../components/common/NotAuthorized';
import { useSelector } from 'react-redux';

export const ViewCms = () => {
	// MANAGING PERMISSIONS HERE

	const roleDetails = useSelector((state) => state?.rolesData?.masterPermissionResponseDTOList)
	const [isAuthorized, setIsAuthorized] = useState(false)

	useEffect(() => {
		if (roleDetails) {
			const permissions = roleDetails.find((detail) => {
				return detail.childPermissions.find((childPerm) => (
					childPerm?.key === "content_management_get" && childPerm?.status === true
				))
			})
			if (permissions) {
				setIsAuthorized(true)
			} else {
				setIsAuthorized(false)
			}
		} else {
			setIsAuthorized(true)
		}

	}, [roleDetails])

	const navigate = useNavigate()
	const { id } = useParams()
	const [loading, setLoading] = useState(false)
	const [cmsData, setCmsData] = useState({})

	// FETCH CMS DATA
	const fetchCmsData = () => {
		setLoading(true)
		getCMSDataById(id).then(response => {
			setLoading(false)
			setCmsData(response)
		}).catch((error) => {
			setLoading(false)
			 if(error?.status == 500){
                toast.error('Unauthorized or server issue. Please refresh or try later. ')
            }else{
                 toast.error(
          error?.error_description
            ? error?.error_description
            : error?.details ? error?.details : error?.message
        );
            }
		})
	}

	//IF USER IS AUTHORIZED THEN ONLY FETCH CMS DATA
	useEffect(() => {
		if (isAuthorized) {
			fetchCmsData()
		} else {
			setLoading(false)
		}
	}, [id, isAuthorized])

	return (
		<>
			{loading ? <Loader isLoading={loading} /> : <div className="d-flex flex-column pageContainer p-3 h-100 overflow-auto">
				<div className="flex-grow-1 pageContent position-relative pt-4">
					{
						!isAuthorized && !loading ?
							<NotAuthorized />
							:
							<Card className="h-100 bg-white shadow border-0 theme-card-cover">
								<div className="theme-card-header px-1">
									<Stack direction="horizontal" gap={2} className="flex-wrap justify-content-between px-3 pt-3 pb-2 align-items-start">
										<h5 className="mb-0 position-relative fw-medium">
											<div className="align-items-center bg-primary d-inline-flex h-60 justify-content-center  position-absolute rounded start-0 text-white theme-icon-box w-60 z-1">
												<span className="theme-icon-box-inner"><PiFilesLight size={36} /></span>
											</div>
											CMS Page
										</h5>
										<Link className='btn btn-secondary text-white fw-bold' onClick={() => navigate(-1)}><IoChevronBack size={20} className='mb-1' />Back</Link>
									</Stack>
								</div>
								<div className="flex-grow-1 d-flex flex-column px-1">
									<div className="p-3 d-flex flex-column h-100">
										<div className="d-flex flex-column flex-grow-1 theme-from">
											<Row className="gx-xl-5">
												<Col sm={12} md={6} lg={6} className=" pb-1">
													<div className="static-data fs-16 fw-bold">Title : {cmsData?.title?.en}</div>
													<div className="static-data fs-16 mt-2" dangerouslySetInnerHTML={{ __html: cmsData?.content?.en }} />
												</Col>
												<Col sm={12} md={6} lg={6} dir="rtl" className="pb-1">
													<div className="static-data fs-16 fw-500" >عنوان :
														{cmsData?.title?.ar}</div>
													<div className="static-data fs-16 mt-2" dangerouslySetInnerHTML={{ __html: cmsData?.content?.ar }} />
												</Col>
											</Row>
										</div>
									</div>
								</div>
							</Card>
					}
				</div>
			</div>}
		</>
	)
}