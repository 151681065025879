import React, { useEffect, useState } from 'react';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { toast } from 'react-toastify';
import { convertToCSV, propertyTypeChartApi } from '../../services/dashboardService';
import DownloadCSVButton from './DownloadCSVButton';

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
);


const PropertyTypesChart = ({ startDate, endDate }) => {

	const [chartData, setChartData] = useState([])

	// FETCH PROPERTY TYPE CHART DATA
	const fetchPropertyTypeData = () => {
		const params = {
			startDate: startDate,
			endDate: endDate
		}
		propertyTypeChartApi(params)
			.then((response) => {
				setChartData(response)
			})
			.catch(error => {
				 if(error?.status == 500){
                toast.error('Unauthorized or server issue. Please refresh or try later. ')
            }else{
                 toast.error(
          error?.error_description
            ? error?.error_description
            : error?.details ? error?.details : error?.message
        );
            }
			})
	}

	useEffect(() => {
		fetchPropertyTypeData()
	}, [startDate, endDate])

	// CHART OPTIONS
	const options = {
		plugins: {
			title: {
				display: true,
			},
		},
		responsive: true,
		scales: {
			x: {
				stacked: false,
			},
			y: {
				stacked: false,
			},
		},
	};

	// SET CHART LABELS
	const chart_labels = chartData.map(item => item.title.en)
	// CHART DATA
	const data = {
		labels: chart_labels,
		datasets: [
			{
				label: 'New Properties',
				data: chartData.map(item => item?.newProperties),
				backgroundColor: 'rgba(255, 99, 132, 0.5)', // Red
				borderColor: 'rgba(255, 99, 132, 1)',
				borderWidth: 1
			},
			{
				label: 'Total Properties',
				data: chartData.map(item => item?.totalProperties),
				backgroundColor: 'rgba(54, 162, 235, 0.5)', // Blue
				borderColor: 'rgba(54, 162, 235, 1)',
				borderWidth: 1
			},
			{
				label: 'Active Properties',
				data: chartData.map(item => item?.activeProperties),
				backgroundColor: 'rgba(75, 192, 192, 0.5)', // Green
				borderColor: 'rgba(75, 192, 192, 1)',
				borderWidth: 1
			}
		]
	};
	

	// GENERATE CSV DATA
	const csvData = convertToCSV(chartData);
	return (
		<div className="card p-3">
			<div className="d-flex justify-content-between">
				<h5 className="mb-0 position-relative fs-22  text-black fw-medium">Property Types</h5>
				{
					csvData && csvData?.length > 0 ? <div>
						<DownloadCSVButton csvData={csvData} filename={'propertyTypes.csv'} />
					</div> : ""
				}
			</div>
			<div>
				<Line options={options} data={data} />
			</div>
		</div>
	);
}

export default PropertyTypesChart