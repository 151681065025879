import { axios } from "./axios"
import {
  
  SET_NEW_PASSWORD_API,
  GET_CMS_LIST_API,
  ADD_CMS_API,
  VIEW_CMS_API,
  GET_CONTACTUS_LIST_API,
  REPLY_CONTACTUS_API,
  VIEW_CONTACTUS_API,
  LISTING_LEVEL_API,
  STATUS_ARTICAL_API,
  DELETE_ARTICAL_API,
  VIEW_ARTICAL_API,
  SUBMIT_ARTICAL_API,
  GET_GOVERNORATE_LIST_API,
  GET_USER_INFO,
  UPDATE_WEBSITE_USER_API,
  GET_WEBSITE_USERS_LIST_API,
  GET_WEBSITE_USERS_DETAIL_API

 
} from "../constants/paths" 





/******
 * Get level listing api call
 * @param  data
 * @returns {Promise<any>}
 */
export const levelsListingAPI = data => {
  return axios.post(LISTING_LEVEL_API, data)  
}

/******
 * Get governorate list api call
 * @param  data
 * @returns {Promise<any>}
 */
export const getGovernorateListAPI = (params)=> {
  return axios.get(`${GET_GOVERNORATE_LIST_API}`,{params})  
}

/******
 * Add cms list api call
 * @param  data
 * @returns {Promise<any>}
 */
export const addArticalAPI = data => {
  return axios.post(SUBMIT_ARTICAL_API, data)  
}

/******
 * Get cms list api call
 * @param  data
 * @returns {Promise<any>}
 */
export const viewArticalAPI = data => {
  return axios.post(VIEW_ARTICAL_API, data)  
}

/**
 * Delete 
 * @param  data
 * @returns {Promise<any>}
 */
export const deleteArticalStatus = data => {
  return axios.post(DELETE_ARTICAL_API, data)  
}


/**
 * Change user status
 * @param  data
 * @returns {Promise<any>}
 */
export const changeArticalStatus = data => {
  return axios.post(STATUS_ARTICAL_API, data)  
}


/******
 * Get contactus list api call
 * @param  data
 * @returns {Promise<any>}
 */
export const getContactusListAPI = (params)=> {
  return axios.get(`${GET_CONTACTUS_LIST_API}`,{params})  
}

/******
 * Add contactus list api call
 * @param  data
 * @returns {Promise<any>}
 */
export const updateContactusAPI = data => {
  return axios.post(REPLY_CONTACTUS_API, data)  
}

/******
 * Get contactus list api call
 * @param  data
 * @returns {Promise<any>}
 */
export const viewContactusAPI = data => {
  return axios.post(VIEW_CONTACTUS_API, data)  
}

/******
 * Update profile/ password
 * @param  data
 * @returns {Promise<any>}
 */
export const updateProfile = data => {
  return axios.post(SET_NEW_PASSWORD_API, data)  
}


/******
 * Get cms list api call
 * @param  data
 * @returns {Promise<any>}
 */
export const getCmsListAPI = (params)=> {
  return axios.get(`${GET_CMS_LIST_API}`,{params})  
}

/******
 * Add cms list api call
 * @param  data
 * @returns {Promise<any>}
 */
export const addCmsAPI = data => {
  return axios.post(ADD_CMS_API, data)  
}

/******
 * Get cms list api call
 * @param  data
 * @returns {Promise<any>}
 */
export const viewCmsAPI = data => {
  return axios.post(VIEW_CMS_API, data)  
}

/**
 * Delete user
 * @param  data
 * @returns {Promise<any>}
 */
export const deleteUser = data => {
  return axios.post(VIEW_CONTACTUS_API, data)  
}


// AMAKEN ADMIN

export const getAdminInfo = async () =>{
  return axios.get(GET_USER_INFO)
}



/******
 * Get Website users list api call
 * @param  data
 * @returns {Promise<any>}
 */
export const getWebsiteUsersListAPI = (params)=> {
  return axios.get(`${GET_WEBSITE_USERS_LIST_API}`,{params})  
}

// GET WebsiteUsers BY ID
export const getWebsiteUsersById = async (params) => {
  const response = await axios.get(GET_WEBSITE_USERS_DETAIL_API,{params})
  return response
}



// update WebsiteUsers
export const updateWebsiteUsersAPI = data => {
  return axios.put(UPDATE_WEBSITE_USER_API, data)  
}