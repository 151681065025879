import { axios } from "./axios"
import{
    EDIT_SUBSCIPTION_API,
    CREATE_SUBSCIPTION_API,
    GET_SUBSCIPTION_LIST_API,
    GET_SPECIFIC_SUBSCIPTION_API,
    UPDATE_STATUS_SUBSCIPTION_API,
    CUSTOM_PLAN_AGENCY_LIST,
    CUSTOM_PLAN_CREATE,
    CUSTOM_PLAN_LIST_API,
    CUSTOM_PLAN_DETAILS,
    CUSTOM_LIST_EDIT_API,
    CUSTOM_PLAN_STATUS_UPDATE_API,
} from "../constants/paths" 

/******
 * Get Package list api call
 * @param  data
 * @returns {Promise<any>}
 */
export const getPackageListAPI = (params)=> {
    return axios.get(`${GET_SUBSCIPTION_LIST_API}`,{params}) 
    // const response = await axios.get(CUSTOM_PLAN_LIST_API,data)
    // return response 
}

// GET Directorate BY ID
export const getPackageById = async (id) => {
    const response = await axios.get(`${GET_SPECIFIC_SUBSCIPTION_API}${id}`);
    return response?.data
}

// update status directorate
export const updatePackageStatusAPI = data => {
    return axios.put(UPDATE_STATUS_SUBSCIPTION_API, data)  
    //return axios.get(`${UPDATE_STATUS_SUBSCIPTION_API}`,{params})  
}

// update directorate
export const updatePackageAPI = data => {
    return axios.put(EDIT_SUBSCIPTION_API, data)  
}

// submit directorate
export const savePackageAPI = data => {
    return axios.post(CREATE_SUBSCIPTION_API, data)  
}

// CUSTOM PACKAGE APIS

// FETCH AGENCY DROPDOWN LIST
export const agencyDropdownListApi = async() =>{
    const response =  await axios.get(CUSTOM_PLAN_AGENCY_LIST)
    return response?.data  
}
// CUSTOM_PLAN_CREATE API
export const customPlanCreateApi = async(data)=>{
    const response = await axios.post(CUSTOM_PLAN_CREATE,data)
    return response?.data
}
// CUSTOM PLAN LIST API 
export const customPlanListApi = async(params)=>{
    return axios.get(`${CUSTOM_PLAN_LIST_API}`,{params}) 
}
// CUSTOM PLAN DETAILS API
export const customPlanDetailsApi = async(id)=>{
    const response = await axios.get(`${CUSTOM_PLAN_DETAILS}/${id}`)
    return response?.data
}
// CUSTOM LIST EDIT API
export const customPlanEditApi = async(data)=>{
    const response = await axios.put(CUSTOM_LIST_EDIT_API,data)
    return response?.data
}
// CUSTOM PLAN STATUS UPDATE API
export const customPlanStatusUpdateApi = async(data)=>{
    const response = await axios.put(CUSTOM_PLAN_STATUS_UPDATE_API,data)
    return response?.data
}
