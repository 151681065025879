import Axios from "axios"
import storage from "../helpers/storage"

/**
 * Axios Interceptor
 * @param {*} config
 * @returns {*}
 */
function authRequestInterceptor(config) {
  const token = storage.getToken()
  if (token) {
    config.headers.authorization = `Bearer ${token}`
  }
  // Add your custom headers here
  //  config.headers['Access-Control-Allow-Origin'] = '*';
  config.headers['Content-Type'] = 'application/json';
  config.headers['Mode'] = 'no-cors';
  config.headers['Device-Type'] = 'BROWSER';
  config.headers["Accept-Language"] = "en";


  if (config.data instanceof FormData) {
    config.headers["Content-Type"] = "multipart/form-data";
  }

  return config
}

/**
 * Axios Object to use
 * @type {*}
 */
export const axios = Axios.create({
  baseURL: process.env.REACT_APP_API_URL
})

// CHECK IF ERROR RESPONSE IS ARRAY BUFFER
function isArrayBuffer(obj) {
  return obj instanceof ArrayBuffer;
}

axios.interceptors.request.use(authRequestInterceptor)
axios.interceptors.response.use(
  response => {
    return response
  },
  error => {
    if (error?.response?.status === 401) {
      if (storage.getToken() !== null) {
        localStorage.removeItem('user-info');
        localStorage.removeItem('refresh-token');
        localStorage.removeItem('RL_ADMIN_refreshToken');
        localStorage.removeItem('RL_ADMIN_token');
        window.location.href = process.env.REACT_APP_ADMIN_WEB_URL
      }
    }

    let errorObject;

    if (!isArrayBuffer(error?.response?.data)) {
   
      errorObject = error?.response?.data;
    } else {

      // Convert ArrayBuffer to string
      const decoder = new TextDecoder();
      const errorMessage = decoder.decode(error?.response?.data) || "An error occurred.";


      try {
        errorObject = JSON.parse(errorMessage);
      } catch (e) {
        // JSON parsing failed, handle error
        console.error("Failed to parse error response:", e);
        errorObject = error;
      }

    } 

    // console.log(JSON.parse(errorMessage))
    return Promise.reject(errorObject)
  }
)
