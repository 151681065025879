import {
  Button,
  Container,
  Dropdown,
  Image,
  Nav,
  Navbar
} from "react-bootstrap"
import { Link } from "react-router-dom"
import "./header.scss"
import { useNavigate } from "react-router-dom" 
import Cookies from "universal-cookie";
import LogoSmall from "../../../assets/images/mylo-logo-icon.png"

import { IoMdNotifications } from "react-icons/io";
import { nameBasedProtectedRoutes } from "../../../router/protected";
import { useDispatch } from "react-redux";
import { logoutAdmin } from "../../../redux/adminSlices";
/**
 * Header Sidebar & Toggle Manager
 * @date 4/12/2023 - 12:56:12 PM
 *
 * @param {{ isActiveSidebar: any; toggleSidebarButton: any; }} {
  isActiveSidebar, toggleSidebarButton
}
 * @returns {*}
 */

export const Header = ({ isActiveSidebar, toggleSidebarButton }) => {
 
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const cookies = new Cookies();

  const handleLogout = () => {
      localStorage.removeItem("RL_ADMIN_refreshToken");
      localStorage.removeItem("RL_ADMIN_token");
      localStorage.removeItem("refresh-token");
      localStorage.removeItem("user-info");
      dispatch(logoutAdmin())
      navigate('/');
  }

  const UserProfile = LogoSmall
  const userName = "Admin"
  return (
    <Navbar bg="" variant="light" className="py-0 px-md-1">
      <Container fluid className="px-3 mh-60">
        <Button
          onClick={toggleSidebarButton}
          variant="link"
          className="align-items-center d-flex justify-content-center navMenuBtn p-0 bg-white rounded-circle h-40 w-40"
        >
          <span
            className={`bg-black d-inline-block menuTrigger position-relative text-center ${
              isActiveSidebar ? "active" : ""
            }`}
          ></span>
        </Button>
        {/* <h1 className="fw-bold h4 mb-0 ms-2 ps-1 text-black">
          { cookies.get("page_heading") ? cookies.get("page_heading") : '' }
        </h1> */}
        
        {/* <Link to="/" className="d-none mx-2 mx-md-3 px-1">
          <Image
            className="img-fluid"
            src={Logo}
            alt="Logo"
            width={80}
            height={32}
          />
        </Link> */}
        <Nav className="ms-auto align-items-center">
        <Link to={`${nameBasedProtectedRoutes?.notification?.path}`} className="btn btn-primary me-2"><IoMdNotifications size={22}/> Send Notification</Link>
          <Dropdown className="profileDropdown ms-md-2 ms-1">
            <Dropdown.Toggle
              variant="link"
              id="dropdown-profile"
              className="border-0 fs-14 fw-semibold text-decoration-none p-0 text-light"
            >
              <Image
                className="object-fit-cover rounded-circle"
                src={UserProfile}
                alt="Profile Image"
                width={40}
                height={40}
              />
              <span className="align-middle d-none d-md-inline-block ms-1 px-2 text-truncate mw-150">
                {userName}
              </span>
              {/*<FaAngleDown size={16} />*/}
            </Dropdown.Toggle>
            <Dropdown.Menu align="end" className="fs-14 shadow-sm">
              <Dropdown.Item as={Link} to="/app/profile" className="fw-medium">
                Change Password
              </Dropdown.Item>
              <Dropdown.Divider className="mb-1" />
              <Dropdown.Item className="fw-medium" onClick={handleLogout}>Logout</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <div className="divider mx-1 mx-md-3 vr opacity-100"></div>
        </Nav>
      </Container>
    </Navbar>
  )
}
