import { Route, Routes } from 'react-router-dom';
import BlogListing from './List';
import EditBlog from './Edit';
import AddBlogs from './Add';


export const Blogs = () => {
  return (
    <Routes>
      <Route path="/" element={<BlogListing />} />
      <Route path="/add" element={<AddBlogs />} />
      <Route path="/edit/:id" element={<EditBlog />} />
    </Routes>
  )
};

export const nameBasedRoutes = {
  blogs: {
    name: "List blogs",
    path: `/app/blogs`,
  },
  addBlogs: {
    name: "Add Blogs",
    path: `/app/blogs/add`
  },
  editBlog: {
    name: "Edit Blogs",
    path: `/app/blogs/edit`
  }
}