import React, { useEffect, useState } from 'react'
import ChangePassword from './ChangePassword';
import UserInfoForm from './UserInfoForm';
import { useSelector } from 'react-redux';
import NotAuthorized from '../../../components/common/NotAuthorized';

function Edit() {
  // MANAGING USER PERMISSIONS HERE

  const roleDetails = useSelector((state) => state?.rolesData?.masterPermissionResponseDTOList)

  const [isAuthorized, setIsAuthorized] = useState(false)

  useEffect(() => {
    if (roleDetails) {
      const permissions = roleDetails.find((detail) => {
        return detail.childPermissions.find((childPerm) => (
          childPerm?.key === "user_edit" && childPerm?.status === true
        ))
      })
      if (permissions) {
        setIsAuthorized(true)
      } else {
        setIsAuthorized(false)
      }
    } else {
      setIsAuthorized(true)
    }

  }, [roleDetails])

  return (
    <div className="d-flex flex-column pageContainer p-3 h-100 overflow-auto">
    {/* IF AUTHORIZED THEN ONLY CALL CHILD COMPONENT */}
      {
        !isAuthorized ?
          <NotAuthorized /> :
          <div className="flex-grow-1 pageContent position-relative pt-4">
            {/*PLATFORM USER INFO EDIT FORM */}
            <UserInfoForm />
            {/*PLATFORM USER CHANGE PASSWQORD FORM */}
            <ChangePassword />
          </div>}
    </div>
  )
}

export default Edit
