import { Route, Routes } from 'react-router-dom';
import { AllList } from './List/List';

import { ViewCms } from './View/View';
import  EditCms from './Edit/Edit';


export const Cms = () => {
    return (  
        <Routes>
            <Route path="/" element={<AllList />} />
            <Route path="/view/:id" element={<ViewCms />} /> 
            <Route path="/edit/:id" element={<EditCms />} /> 
        </Routes>
    )
};


export const nameBasedRoutes = {
    cms: {
        name: "List cms",
        path: `/app/cms`,
    },
    viewCms: {
        name: "View cms",
        path: `/app/cms/view`
    },
    editCms: {
        name: "Edit cms",
        path: `/app/cms/edit`
    }
}