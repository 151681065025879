import React, { useEffect, useState } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { toast } from 'react-toastify';
import { convertToCSV, propertyChartApi } from '../../services/dashboardService';
import DownloadCSVButton from './DownloadCSVButton';


ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);


const PropertiesChart = () => {

    const [chartData, setChartData] = useState([])

    // FETCH PROPERTIES CHART DATA
    const fetchPropertiesData = () => {
        propertyChartApi()
            .then((response) => {
                setChartData(response)
            })
            .catch(error => {
                 if(error?.status == 500){
                toast.error('Unauthorized or server issue. Please refresh or try later. ')
            }else{
                 toast.error(
          error?.error_description
            ? error?.error_description
            : error?.details ? error?.details : error?.message
        );
            }
            })
    }

    useEffect(() => {
        fetchPropertiesData()
    }, [])

    // CHART CONFIG OPTIONS

    const options = {
        plugins: {
            title: {
                display: true,
            },
        },
        responsive: true,
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
            },
        },
    };


    // CHART DATA
    const data = {
        labels: chartData?.map(item => item?.year),
        datasets: [
            {
                label: 'Total Properties',
                data: chartData.map(item => item.totalProperties),
                backgroundColor: 'rgb(255, 99, 132)',
            },
            {
                label: 'Active Properties',
                data: chartData.map(item => item.activeProperties),
                backgroundColor: 'rgb(75, 192, 192)',
            }
        ],
    };
    // CREATE CSV DATA FORMAT
    const csvData = chartData && chartData?.length > 0 ? convertToCSV(chartData) : [];
    return (
        <div className="card p-3">
            <div className="d-flex justify-content-between">
                <h5 className="mb-0 position-relative fs-22  text-black fw-medium">Properties By Year</h5>
                {
                    csvData && csvData?.length > 0 ?
                        <div>
                            <DownloadCSVButton csvData={csvData} filename={'properties.csv'} />
                        </div>
                        : ""
                }

            </div>
            <div>
                <Bar options={options} data={data} />;
            </div>

        </div>
    );
}

export default PropertiesChart