import { ADD_PROPERTY_TYPE_API, PROPERTY_TYPE_LISTING,PROPERTY_DETAILS_API,SAVE_PROPERTY_TYPE,PROPERTY_TYPE_STATUS,AMENITIES_LIST ,SAVE_AMENITIES, EDIT_PROPERTY_TYPE, PROPERTY_TYPE_INFO} from '../constants/paths'
import { axios } from './axios'

// ADD PROPERTY TYPE API
export const AddPropertyTypeApi = async (data) => {
    const response = await axios.post(ADD_PROPERTY_TYPE_API, data)
    return response?.data
}

// PROPERTY TYPE LISTING API

export const propertyTypeListingApi = async (params) => {
    const response = await axios.get(PROPERTY_TYPE_LISTING,{params})
    return response
}

// PROPERTY TYPE LISTING API
export const propertyDetailsApi = async (id) => {
    const response = await axios.get(`${PROPERTY_DETAILS_API}/${id}`)
    return response?.data
}

// SAVE PROPERTY TYPE API
export const savePropertyTypesApi = async (id,data) => {
    const response = await axios.post(`${SAVE_PROPERTY_TYPE}/${id}`,data)
    return response?.data
}


// SAVE PROPERTY TYPE API
export const saveAmenitiesApi = async (id,data) => {
    const response = await axios.post(`${SAVE_AMENITIES}/${id}`,data)
    return response?.data
}

// UPDATE PROPERTY TYPE STATUS
export const updatePropertyTypeStatus = async (id,data) => {
    const response = await axios.put(`${PROPERTY_TYPE_STATUS}/${id}`,data)
    return response?.data
}

// AMENITY LIST API
export const amenityListApi = async () => {
    const response = await axios.get(AMENITIES_LIST)
    return response?.data
}

// EDIT PROPERTY TYPE API
export const editPropertyTypeApi = async (data) => {
    const response = await axios.put(EDIT_PROPERTY_TYPE,data)
    return response?.data
}

// EDIT PROPERTY TYPE API
export const fetchPropertyTypeInfo = async () => {
    const response = await axios.get(PROPERTY_TYPE_INFO)
    return response?.data
}