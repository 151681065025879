import { Formik } from "formik"
import { Button, Stack } from "react-bootstrap"
import Form from "react-bootstrap/Form"
import { ResetPasswordSchema } from "../../../validations/Auth"
import { FaEye, FaEyeSlash } from "react-icons/fa"
import { AuthHeader } from "../authHeader/AuthHeader"
import { useState } from "react"
import { toast } from "react-toastify"
import { resetPassword } from "../../../services/authService"
import InputText from "../../../components/common/InputText"
import { useNavigate } from "react-router-dom"
import { nameBasedRoutes } from "../../../router/public"

import { useSearchParams } from "react-router-dom";
import LoaderMu from "../../../components/common/LoaderMu"

export const ResetPassword = () => {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const key = searchParams.get("key")
  const [showEye, setShowEye] = useState(false)
  const [showConfirmPasEye, setShowConfirmPasEye] = useState(false)

  const initialValues = {
    password: "",
    key: key,
    confirmPassword: "",
  }

  const handleSubmit = (values) => {
    setLoading(true)
    const formData = {
      key: key,
      panel: "ADMIN",
      newPassword: values?.password
    }
    resetPassword(formData)
      .then(response => {
        setLoading(false)
        toast.success(response?.message)
        navigate(nameBasedRoutes?.login?.path)
      })
      .catch(error => {
        setLoading(false)
        toast.error(error?.error_description
          ? error?.error_description
          : error?.message)
      })
  }
  // Show/hide password input field
  const showToggle = event => {
    setShowEye(event)
  }
  // Show/hide confirm password input field
  const onConfirmPasswordShowToggle = event => {
    setShowConfirmPasEye(event)
  }
  return (
    <div className="d-flex flex-column p-3 min-vh-100 w-100 theme-body-bg">
      <LoaderMu loading={loading} />
      <div className="mw-450 mx-auto my-4 py-md-5 text-center w-100">
        <div className="bg-white d-inline-block mt-3 p-4 rounded-3 text-start w-100">
          <div className="p-2" noValidate onSubmit={handleSubmit}>
            <div className="fw-normal text-center mb-3">
              <AuthHeader />
            </div>
            <h6 className="fw-normal text-center mb-3 h5 fw-bold heading">
              Reset Password
            </h6>
            <p className="fw-normal text-center mb-3 fs-14 mw-290 m-auto lh-sm subheading">
              Now you can create a new password.
            </p>
            <Formik
              validationSchema={ResetPasswordSchema}
              initialValues={initialValues}
              onSubmit={(values, actions) => {
                actions.setSubmitting(false)
                handleSubmit(values)
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors
              }) => (

                <Form>
                  <div className={`position-relative ${showEye ? "form-right-icon" : ""}`} >
                    <InputText
                      controlId="password"
                      label='Password *'
                      type={showEye ? "text" : "password"}
                      touched={touched.password}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      errorsField={errors.password}
                      value={values.password}
                      isPasswordHintVisible={true}
                      className="w-100 fs-16 mb-3 custom-text-field"
                      InputLabelProps={{
                        className: "fs-15 grey-color"
                      }}
                    />
                    <div onClick={() => setShowEye(!showEye)}>
                      {showEye ? (
                        <FaEye size="22"
                          className="eyePosition"
                          onClick={() => showToggle(true)} />
                      ) : (
                        <FaEyeSlash
                          size="22"
                          className="eyePosition"
                          onClick={() => showToggle(true)}
                        />
                      )}
                    </div>
                  </div>
                  <div className={`position-relative ${showConfirmPasEye ? "form-right-icon" : ""}`} >
                    <InputText
                      controlId="confirmPassword"
                      label='Confirm Password *'
                      type={showConfirmPasEye ? "text" : "password"}
                      touched={touched.confirmPassword}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      errorsField={errors.confirmPassword}
                      value={values.confirmPassword}
                      isPasswordHintVisible={true}
                      className="w-100 fs-16 mb-3 custom-text-field"
                      InputLabelProps={{
                        className: "fs-15 grey-color"
                      }}
                    />
                    <div onClick={() => setShowConfirmPasEye(!showConfirmPasEye)}>
                      {showConfirmPasEye ? (
                        <FaEye size="22"
                          className="eyePosition"
                          onClick={() => onConfirmPasswordShowToggle(true)} />
                      ) : (
                        <FaEyeSlash
                          size="22"
                          className="eyePosition"
                          onClick={() => onConfirmPasswordShowToggle(true)}
                        />
                      )}
                    </div>
                  </div>
                  <Stack gap={4} className="justify-content-center">
                    <Button
                      className="fw-semibold fs-18 text-white min-h-48 mt-2 w-100"
                      variant="primary"
                      type="submit"
                      onClick={handleSubmit}
                    >
                      Submit
                    </Button>
                  </Stack>
                </Form>



              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>

  )
}
