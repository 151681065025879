import React from "react";
import { FormLabel } from "react-bootstrap";
import { Field, ErrorMessage } from "formik";
function UserTextArea({
  type,
  label,
  disabled,
  placeholder,
  handleChange,
  handleBlur,
  value,
  name,
  className,
  readOnly,
  rows,
  cols,
  ...rest
}) {
  return (
    <div className="mb20">
      <FormLabel className="heading-color ff-heading fw600 mb10">
        {label}
      </FormLabel>
      <textarea
        rows={rows}
        cols={cols}
        placeholder={placeholder}
        id={name}
        readOnly={readOnly}
        name={name}
        onChange={handleChange}
        onBlur={handleBlur}
        className={className}
        disabled={disabled}
      />
      <ErrorMessage name={name} component="div" className="text-danger" />
    </div>
  );
}

export default UserTextArea;