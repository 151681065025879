import { useRoutes } from "react-router-dom";
import { protectedRoutes } from "./protected";
import {publicRoutes} from './public'
import storage from "../helpers/storage";


export default function AppRoutes() {
    const token = storage.getToken();
    
    const mainRoutes = token != null ? protectedRoutes : [];
   
    const element = useRoutes([...mainRoutes, ...publicRoutes]);
    return element;
}
