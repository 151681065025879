import React, { useEffect, useState } from 'react'
import { IoChevronBack } from "react-icons/io5";
import LoaderMu from '../../../components/common/LoaderMu';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Col, Row, Stack } from 'react-bootstrap';
import { BsBuildingFillAdd } from "react-icons/bs"
import { Form, Formik } from 'formik';
import InputText from '../../../components/common/InputText';
import { EditAgencyApi, fetchAgencyData } from '../../../services/agencyService';
import { toast } from 'react-toastify';
import { agencyEditSchema } from '../../../validations/ValidationSchema';
import { useSelector } from 'react-redux';
import NotAuthorized from '../../../components/common/NotAuthorized';
// import { CustomSelect } from '../../../components/common/CustomSelect';
export const EditAgency = () => {

	// MANAGING PERMISSIONS HERE

	const roleDetails = useSelector((state) => state?.rolesData?.masterPermissionResponseDTOList)

	const [isAuthorized, setIsAuthorized] = useState(false)

	useEffect(() => {
		if (roleDetails) {
			const permissions = roleDetails.find((detail) => {
				return detail.childPermissions.find((childPerm) => (
					childPerm?.key === "agency_edit" && childPerm?.status === true
				))
			})
			if (permissions) {
				setIsAuthorized(true)
			} else {
				setIsAuthorized(false)
			}
		} else {
			setIsAuthorized(true)
		}

	}, [roleDetails])


	const { id } = useParams()
	const navigate = useNavigate()
	const [loading, setLoading] = useState(true)
	const [isSubmitting, setIsSubmitting] = useState(false)

	// DEFAULT INITIAL VALUES STATE
	const [initialValues, setInitialValues] = useState({
		agencyName: "",
		contactName: "",
		agencyEmail: "",
		agencyAddress: "",
	})

	// GET AGENCY DATA FOR FORM 
	const getData = () => {
		setLoading(true)
		// CALLING API FOR AGENCY DATA
		fetchAgencyData(id).then((response) => {
			setLoading(false)
			setInitialValues({
				agencyName: response?.name,
				contactName: response?.contactName,
				agencyEmail: response?.email,
				agencyAddress: response?.address,
			})
			// SET AGENCY DATA IN STATE
		}).catch((error) => {
			setLoading(false)
			 if(error?.status == 500){
                toast.error('Unauthorized or server issue. Please refresh or try later. ')
            }else{
                 toast.error(
          error?.error_description
            ? error?.error_description
            : error?.details ? error?.details : error?.message
        );
            }
		})
	}
	// FETCH AGENCY DATA
	useEffect(() => {
		if (isAuthorized) {
			getData()
		} else {
			setLoading(false)
		}
	}, [isAuthorized])

	// FORM SUBMIT
	const handleSubmit = (values) => {
		setIsSubmitting(true)
		const formData = {
			id: id,
			agencyName: values?.agencyName,
			contactName: values?.contactName,
			agencyEmail: values?.agencyEmail,
			agencyAddress: values?.agencyAddress,
		}

		// CALLING API FOR CREATE PLATFORM USER
		EditAgencyApi(formData).then((response) => {
			setIsSubmitting(false)
			navigate(-1)
			toast.success(response?.message ?? "Agency updated successfully.")

		}).catch((error) => {
			setIsSubmitting(false)
			 if(error?.status == 500){
                toast.error('Unauthorized or server issue. Please refresh or try later. ')
            }else{
                 toast.error(
          error?.error_description
            ? error?.error_description
            : error?.details ? error?.details : error?.message
        );
            }
		})
	}
	return (
		<div className="d-flex flex-column pageContainer p-3 h-100">
			{/* LOADER */}
			<LoaderMu loading={loading} />
			<div className="flex-grow-1 h-100 pageContent position-relative pt-4">
				{/* IF USER IS AUTHORIZED THEN ONLY SHOW PAGE CONTENT */}
				{
					!isAuthorized && !loading ?
						<NotAuthorized /> :
						<Card className="h-100 bg-white shadow border-0 theme-card-cover">
							{/* HEADER */}
							<div className="theme-card-header px-1">
								<Stack
									direction="horizontal"
									gap={2}
									className="flex-wrap justify-content-between px-3 pt-3 pb-2 align-items-start"
								>
									<h5 className="mb-0 position-relative fs-18 text-black fw-medium">
										<div className="align-items-center bg-primary d-inline-flex h-60 justify-content-center  position-absolute rounded start-0 text-white theme-icon-box w-60 z-1">
											<BsBuildingFillAdd size={40} />
										</div>
										Edit Agency
									</h5>
									{/* NAVIGATE TO LAST SCREEN */}
									<Button variant="secondary"
										onClick={() => { navigate(-1) }}
										className="rounded-xs fs-15 text-white fw-bold"> <IoChevronBack size={20} className='mb-1' /> Back</Button>
								</Stack>
							</div>
							<div className="flex-grow-1 d-flex flex-column px-1">
								<div className="p-3 d-flex flex-column h-100">
									<Formik
										enableReinitialize={true}
										validationSchema={agencyEditSchema}
										initialValues={
											initialValues
										}
										onSubmit={(values, actions) => {
											actions.setSubmitting(false)
											handleSubmit(values)
										}}
									>
										{({
											handleSubmit,
											handleChange,
											handleBlur,
											values,
											setFieldValue,
											touched,
											isValid,
											errors
										}) => (
											<Form className="d-flex flex-column flex-grow-1 theme-from">
												<Row className="gx-xl-5">
													{/* AGENCY NAME */}
													<Col sm={6} md={6} lg={4} className="mb-4 pb-1">
														<InputText
															controlId="agencyName"
															label="Agency Name"
															value={values?.agencyName}
															name="agencyName"
															maxLength="70"
															errorsField={errors?.agencyName}
															touched={touched?.agencyName}
															handleChange={handleChange}
															handleBlur={handleBlur}
															className={"w-100 form-control"}
															variant="outlined"
														/>
													</Col>
													{/* CONTACT NAME */}
													<Col sm={6} md={6} lg={4} className="mb-4 pb-1">
														<InputText
															controlId="contactName"
															label="Contact Name"
															value={values?.contactName}
															name="contactName"
															maxLength="70"
															errorsField={errors?.contactName}
															touched={touched?.contactName}
															handleChange={handleChange}
															handleBlur={handleBlur}
															className={"w-100 form-control"}
															variant="outlined"
														/>
													</Col>
													{/* AGENCY EMAIL */}
													<Col sm={6} md={6} lg={4} className="mb-4 pb-1">
														<InputText
															type="text"
															controlId="agencyEmail"
															label="Agency Email"
															value={values?.agencyEmail}
															name="agencyEmail"
															maxLength="70"
															errorsField={errors?.agencyEmail}
															touched={touched?.agencyEmail}
															handleChange={handleChange}
															handleBlur={handleBlur}
															className={"w-100 form-control"}
															variant="outlined"
														/>
													</Col>
												</Row>
												<Row className="gx-xl-5">
													{/* AGENCY ADDRESS */}
													<Col sm={6} md={6} lg={4} className="mb-4 pb-1">
														<InputText
															type="text"
															controlId="agencyAddress"
															label="Agency Address"
															value={values?.agencyAddress}
															name="agencyAddress"
															maxLength="70"
															errorsField={errors?.agencyAddress}
															touched={touched?.agencyAddress}
															handleChange={handleChange}
															handleBlur={handleBlur}
															className={"w-100 form-control"}
															variant="outlined"
														/>
													</Col>
												</Row>
												<Stack direction="horizontal" gap={3} className="my-3">
													<Button
														variant="primary"
														onClick={handleSubmit}
														className="rounded-xs fs-15"
														type="submit"
														disabled={isSubmitting ?? false}
													>Update</Button>
													<Button variant="outline-secondary"
														onClick={() => { navigate(-1) }}
														className="rounded-xs fs-15">Cancel</Button>
												</Stack>
											</Form>
										)}
									</Formik>
								</div>
							</div>
						</Card>
				}
			</div>
		</div>
	)
}
