import { Form, Formik, getIn } from 'formik'
import React, { useEffect, useState } from 'react'
import { Button, Card, Col, FormLabel, Row, Stack } from 'react-bootstrap'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { PiFilesLight } from "react-icons/pi";
import 'react-quill/dist/quill.snow.css';
import { blogValidationSchema } from '../../../validations/ValidationSchema';
import LoaderMu from '../../../components/common/LoaderMu';
import { useSelector } from 'react-redux';
import NotAuthorized from '../../../components/common/NotAuthorized';
import InputText from '../../../components/common/InputText';
import TagsInput from '../../../components/common/TagsInput';
import SunTextEditor from '../../../components/common/SunTextEditor';
import { addBlogApi } from '../../../services/blogService';
import { toast } from 'react-toastify';
function AddBlogs() {
	// MANAGING PERMISSIONS HERE

	const roleDetails = useSelector((state) => state?.rolesData?.masterPermissionResponseDTOList)

	const [isAuthorized, setIsAuthorized] = useState(false)

	const [loading, setLoading] = useState(true)
	useEffect(() => {
		if (roleDetails) {
			const permissions = roleDetails.find((detail) => {
				return detail.childPermissions.find((childPerm) => (
					childPerm?.key === "blog_post_add" && childPerm?.status === true
				))
			})
			if (permissions) {
				setIsAuthorized(true)
			} else {
				setIsAuthorized(false)
			}
		} else {
			setIsAuthorized(true)
		}
		setLoading(false)
	}, [roleDetails])


	const navigate = useNavigate()

	const [isSubmitting, setIsSubmitting] = useState(false)

	const [initialValues, setInitialValues] = useState({
		title_en: "",
		title_ar: "",
		metaTitle_en: "",
		metaTitle_ar: "",
		metaDescription_en: "",
		metaDescription_ar: "",
		keywords_en: [],
		keywords_ar: [],
		content_en: "",
		content_ar: "",
		author_en: "",
		author_ar: "",
		tags_en: [],
		tags_ar: [],
		browseFile: null
	})

	const handleSubmit = (values, actions) => {
		setIsSubmitting(true)
		setLoading(false)
		const formData = new FormData();

		// Append title fields
		formData.append('title[en]', values?.title_en);
		formData.append('title[ar]', values?.title_ar);

		// Append content fields
		formData.append('content[en]', values?.content_en);
		formData.append('content[ar]', values?.content_ar);

		// Append author fields
		formData.append('author[en]', values?.author_en);
		formData.append('author[ar]', values?.author_ar);

		// Append tags fields
		formData.append('tags[en]', values?.tags_en);
		formData.append('tags[ar]', values?.tags_ar);

		// Append metaKeywords fields
		formData.append('metaKeywords[en]', values?.keywords_en);
		formData.append('metaKeywords[ar]', values?.keywords_ar);

		// Append metaTitle fields
		formData.append('metaTitle[en]', values?.metaTitle_en);
		formData.append('metaTitle[ar]', values?.metaTitle_ar);

		// Append metaDescription fields
		formData.append('metaDescription[en]', values?.metaDescription_en);
		formData.append('metaDescription[ar]', values?.metaDescription_ar);

		// Append the file if it exists
		if (values?.browseImage && values?.browseImage !== '') {
			formData.append('browseImage', values?.browseImage);
		}
		// CALLING API FOR UPDATE CMS
		addBlogApi(formData).then((response) => {
			navigate(-1)
			toast.success(response?.message ?? "New blog added.")
		}).catch((error) => {
			if (error?.status == 500) {
				toast.error('Unauthorized or server issue. Please refresh or try later. ')
			} else {
				toast.error(
					error?.error_description
						? error?.error_description
						: error?.details ? error?.details : error?.message
				);
			}
		}).finally(() => {
			setLoading(false)
			setIsSubmitting(false)
		})
	}
	// TOOLBAR MODULES FOR TEXT EDITOR
	const modules = {
		toolbar: [
			[{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
			[{ 'size': [] }],
			['bold', 'italic', 'underline', 'strike'],
			[{ 'color': [] }, { 'background': [] }],
			[{ 'list': 'ordered' }, { 'list': 'bullet' }],
			[{ 'align': [] }],
			['link', 'image',],
			['clean'] // remove formatting button
		],
		clipboard: {
			matchVisual: true // Use the custom clipboard to match visual
		}
	};
	const formats = [
		// 'header', 'font', 'size',
		'bold', 'italic', 'underline', 'strike',
		'list', 'bullet', 'indent',
		'link', 'image',
		'link',
		'script', // Include the script format
		'align'
	];

	// Set image in field
	const handleUploadImage = async (event, setFieldValue) => {
		setFieldValue('browseImage', event.target.files[0])
		//console.log('selectedFile',selectedFile)
	}
	return (
		<div className="d-flex flex-column pageContainer p-3 h-100 overflow-auto overflow-x-hidden">
			<LoaderMu loading={loading} />
			<div className="flex-grow-1 pageContent position-relative pt-4">
				{
					!isAuthorized && !loading ?
						<NotAuthorized /> :
						<Card className="h-100 bg-white shadow border-0 theme-card-cover">
							<div className="theme-card-header px-1">
								<Stack direction="horizontal" gap={2} className="flex-wrap justify-content-between px-3 pt-3 pb-2 align-items-start">
									<h5 className="mb-0 position-relative fw-medium">
										<div className="align-items-center bg-primary d-inline-flex h-60 justify-content-center  position-absolute rounded start-0 text-white theme-icon-box w-60 z-1">
											<span className="theme-icon-box-inner"><PiFilesLight size={36} /></span>
										</div>
										Add Blog
									</h5>
								</Stack>
							</div>
							<div className="flex-grow-1 d-flex flex-column px-1">
								<div className="p-3 d-flex flex-column h-100">
									<Formik
										initialValues={initialValues}
										validationSchema={blogValidationSchema}
										onSubmit={(values, actions) => {
											actions.setSubmitting(false)
											handleSubmit(values, actions)
										}}
									>
										{({
											handleSubmit,
											handleChange,
											handleBlur,
											values,
											setFieldValue,
											touched,
											isValid,
											errors
										}) => (
											<Form className="d-flex flex-column flex-grow-1 theme-from">
												<Row className="gx-xl-5">
													<Col sm={6} md={6} lg={"auto"} className="my-1">
														<label className='d-block mb-1'>Blog Image</label>
														<label className='position-relative'>
															<input
																type="file"
																name="browseImage"
																className='form-control'
																errorsField={errors?.browseImage}
																touched={touched?.browseImage}
																handleBlur={handleBlur}
																onChange={(e) => { handleUploadImage(e, setFieldValue) }}
																// className='opacity-0 position-absolute start-0 w-64' 
																id='browseImage'
															/>
														</label>
														<p className='text-danger answer-type-error fs-11'>{getIn(errors, `image`)}</p>
													</Col>
												</Row>
												{/* TITLE */}
												<Row className="gx-xl-5">
													<Col sm={6} md={6} lg={6} className="mb-4 pb-1">
														<InputText
															type="title_en"
															controlId="title_en"
															label="Title (English)"
															value={values?.title_en}
															name="title_en"
															maxLength="70"
															errorsField={errors?.title_en}
															touched={touched?.title_en}
															handleChange={handleChange}
															handleBlur={handleBlur}
															className={"w-100 form-control"}
															variant="outlined"
														/>
													</Col>
													<Col sm={6} md={6} lg={6} className="mb-4 pb-1">
														<div>
															<InputText
																dir="rtl"
																type="title_ar"
																controlId="title_ar"
																label="Title (Arabic)"
																value={values?.title_ar}
																name="title_ar"
																maxLength="70"
																errorsField={errors?.title_ar}
																touched={touched?.title_ar}
																handleChange={handleChange}
																handleBlur={handleBlur}
																className={"w-100 form-control"}
																variant="outlined"
															/>
														</div>
													</Col>
												</Row>
												{/* AUTHOR */}
												<Row className="gx-xl-5">
													<Col sm={6} md={6} lg={6} className="mb-4 pb-1">
														<InputText
															type="author_en"
															controlId="author_en"
															label="Author (English)"
															value={values?.author_en}
															name="author_en"
															maxLength="70"
															errorsField={errors?.author_en}
															touched={touched?.author_en}
															handleChange={handleChange}
															handleBlur={handleBlur}
															className={"w-100 form-control"}
															variant="outlined"
														/>
													</Col>
													<Col sm={6} md={6} lg={6} className="mb-4 pb-1">
														<div>
															<InputText
																dir="rtl"
																type="author_ar"
																controlId="author_ar"
																label="Author (Arabic)"
																value={values?.author_ar}
																name="author_ar"
																maxLength="70"
																errorsField={errors?.author_ar}
																touched={touched?.author_ar}
																handleChange={handleChange}
																handleBlur={handleBlur}
																className={"w-100 form-control"}
																variant="outlined"
															/>
														</div>
													</Col>
												</Row>
												{/* TAGS */}
												<Row className='gx-xl-5'>
													<Col sm={6} md={6} lg={6} className="mb-4 pb-1">
														<TagsInput
															label="Tags (English)"
															placeholder="Tags (English)"
															controlId="tags_en"
															tags={values?.tags_en ?? []}
															handleChange={(e, newValue) => setFieldValue("tags_en", newValue)}
															errorsField={errors?.tags_en}
															touched={touched?.tags_en}
														/>
													</Col>
													<Col sm={6} md={6} lg={6} className="mb-4 pb-1">
														<TagsInput
															label="Tags (Arabic)"
															placeholder="Tags (Arabic)"
															controlId="tags_ar"
															dir="rtl"
															tags={values?.tags_ar ?? []}
															handleChange={(e, newValue) => setFieldValue("tags_ar", newValue)}
															errorsField={errors?.tags_ar}
															touched={touched?.tags_ar}
														/>
													</Col>
												</Row>
												{/* META TITLE */}
												<Row className="gx-xl-5">
													<Col sm={6} md={6} lg={6} className="mb-4 pb-1">
														<InputText
															type="metaTitle_en"
															controlId="metaTitle_en"
															label="Meta Title (English)"
															value={values?.metaTitle_en}
															name="metaTitle_en"
															maxLength="70"
															errorsField={errors?.metaTitle_en}
															touched={touched?.metaTitle_en}
															handleChange={handleChange}
															handleBlur={handleBlur}
															className={"w-100 form-control"}
															variant="outlined"
														/>
													</Col>
													<Col sm={6} md={6} lg={6} className="mb-4 pb-1">
														<div>
															<InputText
																dir="rtl"
																type="metaTitle_ar"
																controlId="metaTitle_ar"
																label="Meta Title (Arabic)"
																value={values?.metaTitle_ar}
																name="metaTitle_ar"
																maxLength="70"
																errorsField={errors?.metaTitle_ar}
																touched={touched?.metaTitle_ar}
																handleChange={handleChange}
																handleBlur={handleBlur}
																className={"w-100 form-control"}
																variant="outlined"
															/>
														</div>
													</Col>
												</Row>
												{/* META DESCRIPTION */}
												<Row className="gx-xl-5">
													<Col sm={6} md={6} lg={6} className="mb-4 pb-1">
														<InputText
															type="metaDescription_en"
															controlId="metaDescription_en"
															multiline={true}
															rows={3}
															label="Meta Description (English)"
															value={values?.metaDescription_en}
															name="metaDescription_en"
															maxLength="70"
															errorsField={errors?.metaDescription_en}
															touched={touched?.metaDescription_en}
															handleChange={handleChange}
															handleBlur={handleBlur}
															className={"w-100 form-control"}
															variant="outlined"
														/>
													</Col>
													<Col sm={6} md={6} lg={6} className="mb-4 pb-1">
														<div>
															<InputText
																dir="rtl"
																type="metaDescription_ar"
																controlId="metaDescription_ar"
																multiline={true}
																rows={3}
																label="Meta Description (Arabic)"
																value={values?.metaDescription_ar}
																name="metaDescription_ar"
																maxLength="70"
																errorsField={errors?.metaDescription_ar}
																touched={touched?.metaDescription_ar}
																handleChange={handleChange}
																handleBlur={handleBlur}
																className={"w-100 form-control"}
																variant="outlined"
															/>
														</div>
													</Col>
												</Row>
												{/* META KEYWORDS */}
												<Row className='gx-xl-5'>
													<Col sm={6} md={6} lg={6} className="mb-4 pb-1">
														<TagsInput
															label="Keywords (English)"
															placeholder="Keywords (English)"
															controlId="keywords_en"
															tags={values?.keywords_en ?? []}
															handleChange={(e, newValue) => setFieldValue("keywords_en", newValue)}
															errorsField={errors?.keywords_en}
															touched={touched?.keywords_en}
															handleBlur={handleBlur}
														/>
													</Col>
													<Col sm={6} md={6} lg={6} className="mb-4 pb-1">
														<TagsInput
															label="Keywords (Arabic)"
															placeholder="Keywords (Arabic)"
															controlId="keywords_ar"
															dir="rtl"
															tags={values?.keywords_ar ?? []}
															handleChange={(e, newValue) => setFieldValue("keywords_ar", newValue)}
															errorsField={errors?.keywords_ar}
															touched={touched?.keywords_ar}
															handleBlur={handleBlur}
														/>
													</Col>
												</Row>
												{/* CONTENT */}
												<Row>
													<Col lg={12} className="mb-4 pb-1">
														<FormLabel className="mb-1 labelColor fs-16 fw-500">Content (English)</FormLabel>
														<SunTextEditor
															id="content_en"
															name="content_en"
															content={values.content_en}
															errorsField={errors?.content_en}
															touched={touched?.content_en}
															handleBlur={handleBlur}
															handleChange={(value) => {
																if (value === "<p><br></p>") {
																	setFieldValue("content_en", '')
																} else {
																	setFieldValue("content_en", value)
																}
															}} />
														{/* <ReactQuill
															modules={modules}
															formats={formats}
															name="content_en"
															theme="snow"
															value={values?.content_en}
															onChange={(value) => {
																if (value === "<p><br></p>") {
																	setFieldValue("content_en", '')
																} else {
																	setFieldValue("content_en", value)
																}
															}}
														/> */}
														<span className='text-danger answer-type-error fs-11'>{getIn(errors, `content_en`)}</span>
													</Col>
													<Col lg={12} className="mb-4 pb-1">
														<FormLabel className="mb-1 labelColor fs-16 fw-500">Content (Arabic)</FormLabel>
														<SunTextEditor
															id="content_ar"
															name="content_ar"
															content={values.content_ar}
															errorsField={errors?.content_ar}
															touched={touched?.content_ar}
															handleBlur={handleBlur}
															dir={"rtl"}
															handleChange={(value) => {
																if (value === "<p><br></p>") {
																	setFieldValue("content_ar", '')
																} else {
																	setFieldValue("content_ar", value)
																}
															}} />
															<span className='text-danger answer-type-error fs-11'>{getIn(errors, `content_ar`)}</span>
														{/* <ReactQuill
															dir="rtl"
															modules={modules}
															formats={formats}
															name="content_ar"
															theme="snow"
															className='rtl-textEditor'
															value={values.content_ar}
															onChange={(value) => {
																if (value === "<p><br></p>") {
																	setFieldValue("content_ar", '')
																} else {
																	setFieldValue("content_ar", value)
																}
															}}
														/>
														<span className='text-danger answer-type-error fs-11'>{getIn(errors, `content_ar`)}</span> */}
													</Col>
												</Row>
												<Stack direction="horizontal" gap={3} className="my-3">
													<Button
														variant="primary"
														onClick={handleSubmit}
														className="rounded-xs fs-15"
														type="submit"
														disabled={isSubmitting}
													>Update</Button>
													<Link onClick={() => navigate(-1)} className="btn btn-outline-secondary rounded-xs fs-15">Cancel</Link>
												</Stack>
											</Form>
										)}
									</Formik>
								</div>
							</div>
						</Card>
				}
			</div>
		</div>
	)
}

export default AddBlogs
