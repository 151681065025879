import React, { useEffect, useState } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { toast } from 'react-toastify';
import { convertToCSV, usersChartApi } from '../../services/dashboardService';
import DownloadCSVButton from './DownloadCSVButton';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);


const UsersChart = () => {

    const [chartData, setChartData] = useState([])

    // FETCH USERS CHART DATA
    const fetchUsersChartData = () => {
        usersChartApi()
            .then((response) => {
                setChartData(response)
            })
            .catch(error => {
                 if(error?.status == 500){
                toast.error('Unauthorized or server issue. Please refresh or try later. ')
            }else{
                 toast.error(
          error?.error_description
            ? error?.error_description
            : error?.details ? error?.details : error?.message
        );
            }
            })
    }

    useEffect(() => {
        fetchUsersChartData()
    }, [])

    // CHART OPTIONS
    const options = {
        plugins: {
            title: {
                display: true,
            },
        },
        responsive: true,
        scales: {
            x: {
                stacked: false,
            },
            y: {
                stacked: false,
            },
        },
    };

    // CHART DATA
    const data = {
        labels: chartData.map(item => item.year),
        datasets: [
            {
                label: 'Total Users',
                data: chartData.map(item => item.totalUsers),
                backgroundColor: 'rgba(255, 99, 132,0.7)', // Red
                borderColor: 'rgba(255, 99, 132, 1)',
                borderWidth: 1
            },
            {
                label: 'New Users',
                data: chartData.map(item => item.newUsers),
                backgroundColor: 'rgba(54, 162, 235,0.7)', // Blue
                borderColor: 'rgba(54, 162, 235, 1)',
                borderWidth: 1
            },
            {
                label: 'Web Users',
                data: chartData.map(item => item.totalWebUsers),
                backgroundColor: 'rgba(244, 139, 25,0.7)', // ORANGE
                borderColor: 'rgba(244, 139, 25, 1)',
                borderWidth: 1
            },
            {
                label: 'Mobile Users',
                data: chartData.map(item => item.totalMobileUsers),
                backgroundColor: 'rgba(75, 192, 192,0.7)', // Green
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1
            }
        ]
    };

    // CREATE CSV DATA FORMAT
    const csvData = chartData && chartData?.length > 0 ?convertToCSV(chartData): [];
    return (
        <div className="card p-3">
            <div className="d-flex justify-content-between">
                <h5 className="mb-0 position-relative fs-22  text-black fw-medium">Users By Year</h5>
                {
                    csvData && csvData?.length > 0 ? <div>
                        <DownloadCSVButton csvData={csvData} filename={'users.csv'} />
                    </div> : ""
                }
            </div>
            <div>
                <Bar options={options} data={data} />;
            </div>

        </div>
    );
}

export default UsersChart