import React, { useEffect, useState } from "react";
import { Button, Card, Stack } from "react-bootstrap";
import { MdSupervisorAccount } from "react-icons/md";
import {
  getRoleById,
  getUserRoleDetails,
  updateRolesPermissions,
} from "../../../services/permissionService";
import { useParams } from "react-router-dom";
import { Checkbox, Empty } from "antd";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { setRoleDetails } from "../../../redux/adminSlices";
import LoaderMu from "../../../components/common/LoaderMu";
function EditRoles() {


  // CURRENT USER ROLE DETAILS FROM REDUX
  const roleDetails = useSelector((state) => state?.rolesData)


  const [roleData, setRoleData] = useState({});
  const [permissionList, setPermissionList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [checkPermissions, setCheckPermissions] = useState([]);

  const dispatch = useDispatch()

  const { role_id } = useParams();

  // FUNCTION FOR FETCH ROLE DATA 
  const fetchRoleData = () => {
    setLoading(true)
    // CALLING API SERVICE FOR ROLE DATA
    getRoleById({ id: role_id })
      .then(async (response) => {
        // SET DATA IN STATE
        setRoleData(response);
        setPermissionList(response?.masterPermissionResponseDTOList);
        const masterPermissions = response?.masterPermissionResponseDTOList;
        await Promise.all(
          masterPermissions.map(async (mp) => {
            const childPermissions = mp.childPermissions;
            if (childPermissions && childPermissions.length > 0) {
              const checkedPermissions = childPermissions
                .filter((element) => element.status === true)
                .map((element) => element.id);

              setCheckPermissions((prev) =>
                Array.from(new Set([...prev, ...checkedPermissions]))
              );
            }
          })
        );
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };
  // CALLING FUNCTION FETCH ROLE DATA
  useEffect(() => {
    fetchRoleData();
  }, []);

  //FUNCTION FOR HANDLE PERMISSION TOGGLE
  const handlePermissionToggle = (event, permissionId) => {
    // IF PERMISSION CHECKED FALSE THEN MARK AS UNCHECKED AND REMOVE FROM STATE
    if (event.target.checked === false) {
      const filterArray = checkPermissions.filter(
        (element) => element !== permissionId
      );
      setCheckPermissions(filterArray);
    } else {
      // IF NEW PERMISSION CHECKED THEN ADD IN STATE
      setCheckPermissions((prev) => Array.from(new Set([...prev, permissionId])));
    }
  };

  // GET CURRENT USER ROLES DETAILS
  const getCurrentRoleData = () => {
    // AFTER PERMISSION UPDATE SET REDUX ROLE STATE WITH NEW DATA
    if (roleDetails) {
      getUserRoleDetails(roleDetails?.roleId).then((response) => {
        dispatch(setRoleDetails({ rolesData: response?.data }))
      }).catch((error) => {
        toast.error(error?.message)
      })
    }
  }

  // CALLING FUNCTION ON SAVE BUTTON CLICK
  const handleUpdate = () => {
    setLoading(true)
    // SET DATA FOR API CALLING
    const formData = {
      roleId: role_id,
      title: roleData?.title,
      description: roleData?.description,
      permissionIdList: checkPermissions,
    };
    // CALLING API SERVICE FOR UPDATE ROLES AND PERMISSION
    updateRolesPermissions(formData)
      .then((response) => {
        // DISPLAY MESSAGE AFTER SUCCESSFUL UPDATE
        // navigate(nameBasedRoutes?.roles?.path)
        toast.success(response?.message ?? "Permissions Updated");
        fetchRoleData();
        getCurrentRoleData()
      })
      .catch((error) => {
         if(error?.status == 500){
                toast.error('Unauthorized or server issue. Please refresh or try later. ')
            }else{
                 toast.error(
          error?.error_description
            ? error?.error_description
            : error?.details ? error?.details : error?.message
        );
            };
      }).finally(() => {
        setLoading(false)
      });
  };
  return (
    <div className="d-flex flex-column pageContainer p-3 h-100">
      <LoaderMu loading={loading} />
      <div className="flex-grow-1 h-100 pageContent position-relative pt-4 overflow-auto">
        <Card className="h-100 bg-white shadow border-0 theme-card-cover">
          <div className="theme-card-header px-1">
            <Stack
              direction="horizontal"
              gap={2}
              className="flex-wrap justify-content-between px-3 pt-3 pb-2 align-items-start"
            >
              <h5 className="mb-0 position-relative fs-18 text-black fw-medium">
                <div className="align-items-center bg-primary d-inline-flex h-60 justify-content-center  position-absolute rounded start-0 text-white theme-icon-box w-60 z-1">
                  <MdSupervisorAccount size={40} />
                </div>
                Edit Roles Permissions
              </h5>
            </Stack>
          </div>
          <div className="flex-grow-1 d-flex flex-column px-3 h-100 overflow-auto mt-4">
            {/* ABOUT ROLE */}
            <div className="row">
              <div className="col-auto">
                <h6>Role Title </h6>
                <p className="fs-15 ">{roleData?.title}</p>
              </div>
              <div className="col-auto ms-4">
                <h6>Role Description</h6>
                <p className="fs-15">{roleData?.description}</p>
              </div>
            </div>
            <div className="row bg-light-custom border-bottom border-light">
              <div className="col-md-12">
                <div className="p-3 ">
                  <h5>Rights</h5>
                </div>
              </div>
            </div>
            {/* CREATING PERMISSION LIST TABLE  */}
            {permissionList && permissionList?.length > 0 ? (
              <div>
                {permissionList.map((element, index) => {
                  return (
                    <div
                      key={index + 1}
                      className="row p-3  border-bottom border-light"
                    >
                      <div className="col-md-3">
                        <div>
                          <p className="mb-0 fs-16 fw-500">
                            {element?.parentPermissions?.title}
                          </p>
                        </div>
                      </div>
                      <div className="col-md">
                        <div className="row align-items-center h-100">
                          {element?.childPermissions &&
                            element?.childPermissions?.length > 0
                            ? element?.childPermissions?.map(
                              (permission, key) => {
                                return (
                                  <div key={key + 1} className="col-sm-2">
                                    <Checkbox
                                      defaultChecked={permission?.status}
                                      onChange={(event) =>
                                        handlePermissionToggle(
                                          event,
                                          permission?.id
                                        )
                                      }
                                    >
                                      {permission?.title}
                                    </Checkbox>
                                  </div>
                                );
                              }
                            )
                            : ""}
                        </div>
                      </div>
                    </div>
                  );
                })}
                <div>
                  <Button
                    className="fw-semibold fs-18 text-white min-h-48 mt-2 px-5"
                    variant="primary"
                    onClick={handleUpdate}
                    type="submit"
                    disabled={loading ?? false}
                  >
                    Update
                  </Button>
                </div>
              </div>
            ) : (
              <Empty /> // COMPONENT IF NO DATA AVAILABLE TO DISPLAY
            )}
          </div>
        </Card>
      </div>
    </div>
  );
}

export default EditRoles;
