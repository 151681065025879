import { axios } from "./axios"
import {
    GET_VILLAGE_LIST_API,
    UPDATE_VILLAGE_API,
} from "../constants/paths"
// GET VILLAGE LIST
export const getVillageListAPI = (params) => {
    return axios.get(`${GET_VILLAGE_LIST_API}`, { params })
}

// GET VILLAGE BY ID
export const getVillageById = async (id) => {
    const response = await axios.get(`${GET_VILLAGE_LIST_API}/${id}`);
    return response?.data
}

// UPDATE VILLAGE API
export const updateVillageAPI = data => {
    return axios.put(UPDATE_VILLAGE_API, data)
}
