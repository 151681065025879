import { Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Row, Stack } from 'react-bootstrap'
import { editSystemUserSchema } from '../../../validations/ValidationSchema'
import { useNavigate, useParams } from 'react-router-dom'
import { editSystemUser, getSystemUserById } from '../../../services/systemUserService'
import { toast } from 'react-toastify'
import { FaUserEdit } from "react-icons/fa";
import InputText from '../../../components/common/InputText'

function UserInfoForm() {

  const { id } = useParams() // GET ID FROM URL PARAMS

  const navigate = useNavigate()

  const [isSubmitting , setIsSubmitting] = useState(false)

  const [initialValues, setInitialValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    roleId: "",
    langKey: "",
    password: "",
    confirm_password: "",
    about_en: "",
    about_ar: ""
  })
  // FORM SUBMIT FOR CREATE PLATFORM USER
  const handleSubmit = (values, actions) => {
    setIsSubmitting(true)
    const formData = {
      id: id,
      firstName: values?.firstName,
      lastName: values?.lastName,
      email: values?.email,
      password: values?.password,
      about: { en: values?.about_en, ar: values?.about_ar }
    }
    // CALLING API FOR CREATE PLATFORM USER
    editSystemUser(formData).then((response) => {
      navigate(-1) // NAVIGATE TO PREVIOUS ROUTE
      toast.success(response?.message ?? "Platform user updated successfully.")
    }).catch((error) => {
       if(error?.status == 500){
                toast.error('Unauthorized or server issue. Please refresh or try later. ')
            }else{
                 toast.error(
          error?.error_description
            ? error?.error_description
            : error?.details ? error?.details : error?.message
        );
            }
    }).finally(()=>{
      setIsSubmitting(false)
    })
  }
  // FETCH PLATFORM USER DATA FROM API
  const getUserData = () => {
    // GETTING DATA FROM API
    getSystemUserById(id).then(response => {
      setInitialValues({
        firstName: response?.firstName,
        lastName: response?.lastName,
        email: response?.email,
        about_en: response?.about?.en,
        about_ar: response?.about?.ar
      })
    }).catch((error) => {
       if(error?.status == 500){
                toast.error('Unauthorized or server issue. Please refresh or try later. ')
            }else{
                 toast.error(
          error?.error_description
            ? error?.error_description
            : error?.details ? error?.details : error?.message
        );
            }
    })
  }
  // CALLING USER DATA FUNCTION
  useEffect(() => {
    getUserData()
  }, [])

  return (
    <Card className="bg-white shadow border-0 theme-card-cover">
      <div className="theme-card-header px-1">
        <Stack direction="horizontal" gap={2} className="flex-wrap justify-content-between px-3 pt-3 pb-2 align-items-start">
          <h5 className="mb-0 position-relative fw-medium">
            <div className="align-items-center bg-primary d-inline-flex h-60 justify-content-center  position-absolute rounded start-0 text-white theme-icon-box w-60 z-1">
              <span className="theme-icon-box-inner"><FaUserEdit size={36} /></span>
            </div>
            Edit User
          </h5>
        </Stack>
      </div>
      <div className="flex-grow-1 d-flex flex-column px-1">
        <div className="p-3 d-flex flex-column h-100">
          {/* EDIT FORM */}
          <Formik
            enableReinitialize={true} //TRUE BECAUSE OF SETTING DATA IN INPUT FIELDS
            validationSchema={editSystemUserSchema}
            initialValues={initialValues}
            onSubmit={(values, actions) => {
              actions.setSubmitting(false)
              handleSubmit(values, actions)
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              setFieldValue,
              touched,
              isValid,
              errors
            }) => (
              <Form className="d-flex flex-column flex-grow-1 theme-from">
                <Row className="gx-xl-5">
                  <Col sm={6} md={6} lg={4} className="mb-4 pb-1">
                    {/* FIRST NAME */}
                    <InputText
                      controlId="firstName"
                      label="First Name"
                      value={values?.firstName}
                      name="firstName"
                      maxLength="70"
                      errorsField={errors?.firstName}
                      touched={touched?.firstName}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      className={"w-100 form-control"}
                      variant="outlined"
                    />
                  </Col>
                  <Col sm={6} md={6} lg={4} className="mb-4 pb-1">
                    {/* LAST NAME */}
                    <InputText
                      controlId="lastName"
                      label="Last Name"
                      value={values?.lastName}
                      name="lastName"
                      maxLength="70"
                      errorsField={errors?.lastName}
                      touched={touched?.lastName}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      className={"w-100 form-control"}
                      variant="outlined"
                    />
                  </Col>
                  <Col sm={6} md={6} lg={4} className="mb-4 pb-1">
                    {/* EMAIL */}
                    <InputText
                      type="email"
                      controlId="email"
                      label="Email"
                      value={values?.email}
                      name="email"
                      disabled={true}
                      maxLength="70"
                      errorsField={errors?.email}
                      touched={touched?.email}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      className={"w-100 form-control"}
                      variant="outlined"
                    />
                  </Col>
                </Row>
                <Row className="gx-xl-5">
                  <Col sm={6} md={6} lg={6} className="mb-4 pb-1">
                    {/* ABOUT IN ENGLISH */}
                    <InputText
                      controlId="about_en"
                      label="About (en)"
                      value={values?.about_en}
                      name="about"
                      maxLength="70"
                      errorsField={errors?.about_en}
                      touched={touched?.about_en}
                      handleChange={handleChange}
                      className={'h-auto w-100'}
                      multiline={true}
                      rows={4}
                      handleBlur={handleBlur}
                      variant="outlined" />
                  </Col>

                  <Col sm={6} md={6} lg={6} className="mb-4 pb-1">
                    {/* ABOUT IN ARABIC */}
                    <InputText
                      dir="rtl"
                      controlId="about_ar"
                      label="About (ar)"
                      value={values?.about_ar}
                      name="about"
                      maxLength="70"
                      errorsField={errors?.about_ar}
                      touched={touched?.about_ar}
                      handleChange={handleChange}
                      className={'h-auto w-100'}
                      multiline={true}
                      rows={4}
                      handleBlur={handleBlur}
                      variant="outlined" />
                  </Col>
                </Row>
                {/* SUBMIT BUTTON */}
                <Stack direction="horizontal" gap={3} className="my-3">
                  <Button
                    variant="primary"
                    onClick={handleSubmit}
                    className="rounded-xs fs-15"
                    type="submit"
                    disabled={isSubmitting ?? false}
                  >Update</Button>
                  {/* CANCEL BUTTON */}
                  <Button variant="outline-secondary"
                    onClick={() => { navigate(-1) }}
                    className="rounded-xs fs-15">Cancel</Button>
                </Stack>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Card>
  )
}

export default UserInfoForm
