import { Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Row, Stack } from 'react-bootstrap'
import { CustomSelect } from '../../../components/common/CustomSelect'
import { useNavigate } from 'react-router-dom'
import { HiUserAdd } from "react-icons/hi";

import InputText from '../../../components/common/InputText'
import { addSystemUserSchema } from '../../../validations/ValidationSchema'
import { addSystemUser, getRoleListData } from '../../../services/systemUserService'
import { toast } from 'react-toastify'
import { nameBasedRoutes } from '../routes'
import LoaderMu from '../../../components/common/LoaderMu'
import { useSelector } from 'react-redux'
import NotAuthorized from '../../../components/common/NotAuthorized'


function Add() {

  // MANAGING PERMISSIONS HERE

  const roleDetails = useSelector((state) => state?.rolesData?.masterPermissionResponseDTOList)

  const [isAuthorized, setIsAuthorized] = useState(false)

  useEffect(() => {
    if (roleDetails) {
      const permissions = roleDetails.find((detail) => {
        return detail.childPermissions.find((childPerm) => (
          childPerm?.key === "user_add" && childPerm?.status === true
        ))
      })
      if (permissions) {
        setIsAuthorized(true)
      } else {
        setIsAuthorized(false)
      }
    } else {
      setIsAuthorized(true)
    }

  }, [roleDetails])

  // STATE FOR ROLE LIST DROPDOWN DATA
  const [roleList, setRoleList] = useState([])

  const [loading, setLoading] = useState(true)

  const [isSubmitting, setIsSubmitting] = useState(false)

  const navigate = useNavigate()

  // FORM SUBMIT FOR CREATE PLATFORM USER
  const handleSubmit = (values, actions) => {
    setIsSubmitting(true)
    const formData = {
      firstName: values?.firstName,
      lastName: values?.lastName,
      email: values?.email,
      roleId: values?.roleId ? parseInt(values?.roleId) : null,
      langKey: values?.langKey,
      password: values?.password,
      about: { en: values?.about_en, ar: values?.about_ar }
    }

    // CALLING API FOR CREATE PLATFORM USER
    addSystemUser(formData).then((response) => {
      setIsSubmitting(false)
      toast.success(response?.message ?? "System user created successfully.")
      actions.resetForm()
      navigate(nameBasedRoutes?.SystemUsers?.path)
    }).catch((error) => {
      setIsSubmitting(false)
       if(error?.status == 500){
                toast.error('Unauthorized or server issue. Please refresh or try later. ')
            }else{
                 toast.error(
          error?.error_description
            ? error?.error_description
            : error?.details ? error?.details : error?.message
        );
            }
    })
  }
  // FETCH ROLES LIST DATA FROM API FOR DROPDOWN
  const getRoleList = () => {
    // GETTING DATA FROM API
    setLoading(true)
    getRoleListData().then(response => {
      if (response?.length > 0) {
        const listArr = []
        response?.forEach((element) => {
          listArr.push({ value: element?.id, label: element?.name })
        })
        setRoleList(listArr)
      }
      setLoading(false)
    }).catch((error) => {
       if(error?.status == 500){
                toast.error('Unauthorized or server issue. Please refresh or try later. ')
            }else{
                 toast.error(
          error?.error_description
            ? error?.error_description
            : error?.details ? error?.details : error?.message
        );
            }
      setLoading(false)
    })
  }
  // FETCH ROLES LIST DATA ONLY IF USER IS AUTHORIZED
  useEffect(() => {
    if (isAuthorized) {
      getRoleList()
    } else {
      setLoading(false)
    }
  }, [isAuthorized])
  return (
    <div className="d-flex flex-column pageContainer p-3 h-100 overflow-auto">
      <LoaderMu loading={loading} />
      <div className="flex-grow-1 pageContent position-relative pt-4">
        {
          !isAuthorized && !loading ?
            <NotAuthorized /> :
            <Card className="h-100 bg-white shadow border-0 theme-card-cover">
              <div className="theme-card-header px-1">
                <Stack direction="horizontal" gap={2} className="flex-wrap justify-content-between px-3 pt-3 pb-2 align-items-start">
                  <h5 className="mb-0 position-relative fw-medium">
                    <div className="align-items-center bg-primary d-inline-flex h-60 justify-content-center  position-absolute rounded start-0 text-white theme-icon-box w-60 z-1">
                      <span className="theme-icon-box-inner"><HiUserAdd size={36} /></span>
                    </div>
                    Add User
                  </h5>
                </Stack>
              </div>
              <div className="flex-grow-1 d-flex flex-column px-1">
                <div className="p-3 d-flex flex-column h-100">
                  <Formik
                    validationSchema={addSystemUserSchema}
                    initialValues={
                      {
                        firstName: "",
                        lastName: "",
                        email: "",
                        roleId: "",
                        langKey: "",
                        password: "",
                        confirmPassword: "",
                        about: { en: "", ar: "" }
                      }
                    }
                    onSubmit={(values, actions) => {
                      actions.setSubmitting(false)
                      handleSubmit(values, actions)
                    }}
                  >
                    {({
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      values,
                      setFieldValue,
                      touched,
                      isValid,
                      errors
                    }) => (
                      <Form className="d-flex flex-column flex-grow-1 theme-from">
                        <Row className="gx-xl-5">
                        {/* FIRST NAME */}
                          <Col sm={6} md={6} lg={4} className="mb-4 pb-1">
                            <InputText
                              controlId="firstName"
                              label="First Name"
                              value={values?.firstName}
                              name="firstName"
                              maxLength="70"
                              errorsField={errors?.firstName}
                              touched={touched?.firstName}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              className={"w-100 form-control"}
                              variant="outlined"
                            />
                          </Col>
                          {/* LAST NAME */}
                          <Col sm={6} md={6} lg={4} className="mb-4 pb-1">
                            <InputText
                              controlId="lastName"
                              label="Last Name"
                              value={values?.lastName}
                              name="lastName"
                              maxLength="70"
                              errorsField={errors?.lastName}
                              touched={touched?.lastName}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              className={"w-100 form-control"}
                              variant="outlined"
                            />
                          </Col>
                          {/* EMAIL */}
                          <Col sm={6} md={6} lg={4} className="mb-4 pb-1">
                            <InputText
                              type="email"
                              controlId="email"
                              label="Email"
                              value={values?.email}
                              name="email"
                              maxLength="70"
                              errorsField={errors?.email}
                              touched={touched?.email}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              className={"w-100 form-control"}
                              variant="outlined"
                            />
                          </Col>
                        </Row>
                        <Row className="gx-xl-5">
                        {/* ROLE DROPDOWN */}
                          <Col sm={6} md={6} lg={4} className="mb-4 pb-1">
                            <CustomSelect
                              options={roleList}
                              controlId="roleId"
                              label="Select the Role"
                              value={values?.roleId}
                              name="roleId"
                              variant="outlined"
                              errorsField={errors?.roleId}
                              touched={touched?.roleId}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              onChange={handleChange}
                            />
                          </Col>
                          {/* LANGUAGE DROPDOWN */}
                          <Col sm={6} md={6} lg={4} className="mb-4 pb-1">
                            <CustomSelect
                              options={[
                                { label: 'English', value: 'en' },
                                { label: 'Arabic', value: 'ar' },

                              ]}
                              controlId="langKey"
                              label="Language"
                              value={values?.langKey}
                              name="langKey"
                              variant="outlined"
                              errorsField={errors?.langKey}
                              touched={touched?.langKey}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              onChange={handleChange}
                            />
                          </Col>
                          {/*CREATE PASSWORD */}
                          <Col sm={6} md={6} lg={4} className="mb-4 pb-1">
                            <InputText
                              type="password"
                              controlId="password"
                              label="Password"
                              value={values?.password}
                              name="password"
                              maxLength="70"
                              errorsField={errors?.password}
                              touched={touched?.password}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              className={"w-100 form-control"}
                              variant="outlined"
                            />
                          </Col>
                        </Row>
                        <Row className="gx-xl-5">
                        {/* CONFIRM PASSWORD */}
                          <Col sm={6} md={6} lg={4} className="mb-4 pb-1">
                            <InputText
                              type="password"
                              controlId="confirmPassword"
                              label="Confirm Password"
                              value={values?.confirmPassword}
                              name="confirmPassword"
                              maxLength="70"
                              errorsField={errors?.confirmPassword}
                              touched={touched?.confirmPassword}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              className={"w-100 form-control"}
                              variant="outlined"
                            />
                          </Col>
                        </Row>
                        <Row className="gx-xl-5">
                        {/* ABOUT TEXT IN ENGLISH */}
                          <Col sm={6} md={6} lg={6} className="mb-4 pb-1">
                            <InputText
                              controlId="about_en"
                              label="About (en)"
                              value={values?.about_en}
                              name="about_en"
                              maxLength="70"
                              errorsField={errors?.about_en}
                              touched={touched?.about_en}
                              handleChange={handleChange}
                              className={'h-auto w-100'}
                              multiline={true}
                              rows={4}
                              handleBlur={handleBlur}
                              variant="outlined" />
                          </Col>
                           {/* ABOUT TEXT IN ARABIC */}
                          <Col sm={6} md={6} lg={6} className="mb-4 pb-1">
                            <InputText
                              controlId="about_ar"
                              label="About (ar)"
                              value={values?.about_ar}
                              name="about_ar"
                              dir="rtl"
                              maxLength="70"
                              errorsField={errors?.about_ar}
                              touched={touched?.about_ar}
                              handleChange={handleChange}
                              className={'h-auto w-100'}
                              multiline={true}
                              rows={4}
                              handleBlur={handleBlur}
                              variant="outlined" />
                          </Col>
                        </Row>
                        <Stack direction="horizontal" gap={3} className="my-3">
                          <Button
                            variant="primary"
                            onClick={handleSubmit}
                            className="rounded-xs fs-15"
                            type="submit"
                            disabled={isSubmitting}
                          >Save</Button>
                          {/* NAVIGATE TO BACK PAGE */}
                          <Button variant="outline-secondary"
                            onClick={() => { navigate(-1) }}
                            className="rounded-xs fs-15">Cancel</Button> 
                        </Stack>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </Card>}
      </div>

    </div>
  )
}

export default Add
