import { ForgetPassword } from "../pages/Auth/ForgetPassword";
import { Login } from "../pages/Auth/Login";
import { ResetPassword } from "../pages/Auth/ResetPassword";
import { VerifyAccount } from "../pages/Auth/verifyAccount";
// import { MultifactorAuth } from "../pages/Auth/MultifactorAuth";
const basePath = process.env.REACT_APP_BASE_URL;

/**
 * Public Routes Available in App
 * @type {{}}
 */
export const publicRoutes = [
  {
    path: `/`,
    element: <Login />,
  },
  {
    path: `/login`,
    element: <Login />,
  },
  {
    path: `/forgot-password`,
    element: <ForgetPassword />,
  },
  {
    path:  `/reset-password`,
    element: <ResetPassword />,
  },
  {
    path:  `/verify-account`,
    element: <VerifyAccount />,
  },
];

export const nameBasedRoutes = {
  home: {
    name: "Home",
    path: `/app/dashboard`,
  },
  login: {
    name: "Login",
    path: `/login`,
  },
  forgotPassword: {
    name: "Forgot Passowrd",
    path: `/forgot-password`,
  },
  resetPassword: {
    name: "Reset Passowrd",
    path: `/reset-password`,
  }

}