import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row, Stack } from "react-bootstrap";
import { MdOutlineFileUpload } from "react-icons/md";
import InputText from "../../../components/common/InputText";
import { CustomSelect } from "../../../components/common/CustomSelect";
import { useNavigate } from "react-router-dom";
import { uploadBuildValidationSchema } from "../../../validations/ValidationSchema";
import { buildUploadApi, getLatestBuildApi } from "../../../services/buildUpload.service";
import { TfiAndroid } from "react-icons/tfi";
import { toast } from "react-toastify";
import { FaApple } from "react-icons/fa";
import { Radio } from "antd";
import LoaderMu from "../../../components/common/LoaderMu";

const BuildAdd = () => {

  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)

  const [buildData, setBuildData] = useState({})

  const [buildPlatform, setBuildPlatform] = useState("ANDROID")

  // FILE CHANGE
  const handleFileChange = (e, setFieldValue, setFieldError) => {
    const file = e.target.files[0];

    if (file) {
      const fileExtension = file.name.split('.').pop().toLowerCase();

      if (fileExtension === 'apk') {
        setFieldValue('platform', 'ANDROID');  // Automatically set platform to Android
        setFieldError('browseFile', '');
        setFieldValue('browseFile', file)
      } else if (fileExtension === 'ipa') {
        setFieldValue('platform', 'IOS');  // Automatically set platform to iOS
        setFieldError('browseFile', '');
        setFieldValue('browseFile', file);
      } else {
        setFieldError('browseFile', 'Please upload a valid APK or IPA file.');
        setFieldValue('platform', '');  // Automatically set platform to ''
        e.target.value = '';  // Reset the file input
      }
    } else {
      setFieldError('browseFile', 'Please upload a valid APK or IPA file.');
      setFieldValue('platform', '');  // Automatically set platform to ''
    }
  };

  // HANDLE FORM SUBMIT
  const handleSubmit = (values) => {

    setLoading(true)
    const formData = new FormData();

    // Append title fields
    formData.append('browseFile', values?.browseFile);
    formData.append('version', values?.version);
    formData.append('platform', values?.platform);

    // Append content fields
    formData.append('description[en]', values?.description_en);
    formData.append('description[ar]', values?.description_ar);

    buildUploadApi(formData).then((response) => {
      fetchLatestBuild()
      toast.success(response?.data?.message ?? "New build uploaded.")
    }).catch((error) => {
      if (error?.status == 500) {
        toast.error('Unauthorized or server issue. Please refresh or try later. ')
      } else {
        toast.error(
          error?.error_description
            ? error?.error_description
            : error?.details ? error?.details : error?.message
        );
      }
    }).finally(() => {
      setLoading(false)
    })
  }

  // GET LATEST BUILD DATA
  const fetchLatestBuild = () => {
    getLatestBuildApi(buildPlatform).then((response) => {
      setLoading(true)
      setBuildData(response?.data)
    }).catch((error) => {
      setBuildData({})
      // toast.error(
      //   error?.error_description
      //     ? error?.error_description
      //     : error?.details ? error?.details : error?.message
      // );
    }).finally(() => {
      setLoading(false)
    })
  }


  useEffect(() => {
    fetchLatestBuild()
  }, [buildPlatform])

  return <div className="d-flex flex-column pageContainer p-3  overflow-auto overflow-x-hidden">
    <LoaderMu loading={loading} />
    <div className="flex-grow-1 pageContent position-relative pt-4">
      {
        <Card className="h-100 bg-white shadow border-0 theme-card-cover mb-5">
          <div className="theme-card-header px-1">
            <Stack direction="horizontal" gap={2} className="flex-wrap justify-content-between px-3 pt-3 pb-2 align-items-start">
              <h5 className="mb-0 position-relative fw-medium">
                <div className="align-items-center bg-primary d-inline-flex h-60 justify-content-center  position-absolute rounded start-0 text-white theme-icon-box w-60 z-1">
                  <span className="theme-icon-box-inner"> {buildPlatform === "ANDROID" ? <TfiAndroid size={36} /> : <FaApple size={36}/>} </span>
                </div>
                Current Build
              </h5>
              <Radio.Group onChange={(e)=>setBuildPlatform(e?.target?.value)} defaultValue="ANDROID">
                <Radio.Button value="ANDROID">Android</Radio.Button>
                <Radio.Button value="IOS">IOS</Radio.Button>
              </Radio.Group>
            </Stack>
          </div>
          <div className="flex-grow-1 d-flex flex-column px-1">
            <div className="p-3 d-flex flex-column h-100">
              <Row>
                <Col sm={6} lg={6}>
                  <p className='fs-14 mb-3'>Platform</p>
                  <p className="fw-500 fs-16">{buildData?.platform ?? <span className='text-danger'>NA</span>}</p>
                </Col>
                <Col sm={6} lg={6}>
                  <p className='fs-14 mb-3'>Version</p>
                  <p className="fw-500 fs-16">{buildData?.version ?? <span className='text-danger'>NA</span>}</p>
                </Col>
                <Col sm={12} lg={6}>
                  <p className='fs-14 mb-3'>Description (English)</p>
                  <p className="fw-500 fs-16">{buildData?.description?.en ?? <span className='text-danger'>NA</span>}</p>
                </Col>
                <Col sm={12} lg={6}>
                  <p className='fs-14 mb-3'>Description (Arabic)</p>
                  <p className="fw-500 fs-16">{buildData?.description?.ar ?? <span className='text-danger'>NA</span>}</p>
                </Col>
              </Row>
            </div>
          </div>
        </Card>
      }

      <Card className="h-100 bg-white shadow border-0 theme-card-cover">
        <div className="theme-card-header px-1">
          <Stack direction="horizontal" gap={2} className="flex-wrap justify-content-between px-3 pt-3 pb-2 align-items-start">
            <h5 className="mb-0 position-relative fw-medium">
              <div className="align-items-center bg-primary d-inline-flex h-60 justify-content-center  position-absolute rounded start-0 text-white theme-icon-box w-60 z-1">
                <span className="theme-icon-box-inner"><MdOutlineFileUpload size={36} /></span>
              </div>
              Upload Build
            </h5>
          </Stack>
        </div>
        <div className="flex-grow-1 d-flex flex-column px-1">
          <div className="p-3 d-flex flex-column h-100">
            <Formik
              initialValues={{
                browseFile: '',
                version: 0,
                platform: '',
                description_en: '',
                description_ar: ''
              }}
              validationSchema={uploadBuildValidationSchema}
              onSubmit={(values, actions) => {
                actions.setSubmitting(false)
                handleSubmit(values, actions)
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                setFieldValue,
                setFieldError,
                touched,
                isValid,
                errors
              }) => (
                <Form className="d-flex flex-column flex-grow-1 theme-from">
                  <Row className="gx-xl-5 align-items-end">

                    <Col sm={12} md={6} lg={6} className="mb-4 pb-1">
                      <label htmlFor="fileUpload">Upload File</label>
                      <input
                        type="file"
                        name="browseFile"
                        className='form-control'
                        // errorsField={errors?.browseFile}
                        touched={touched?.browseFile}
                        onBlur={handleBlur}
                        onChange={(e) => handleFileChange(e, setFieldValue, setFieldError)}
                        accept={'.apk,.ipa'}
                      />
                      {errors?.browseFile && <p className='text-danger answer-type-error fs-11'>{errors?.browseFile}</p>}
                    </Col>
                  </Row>
                  {/* <p className='text-danger answer-type-error fs-11'>{getIn(errors, `image`)}</p> */}
                  <Row className="gx-xl-5">
                    <Col sm={6} md={6} lg={6} className="mb-4 pb-1">
                      <InputText
                        type="number"
                        controlId="version"
                        label="Version"
                        value={values?.version}
                        name="version"
                        maxLength="70"
                        errorsField={errors?.version}
                        touched={touched?.version}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        className={"w-100 form-control"}
                        variant="outlined"
                      />
                    </Col>
                    <Col sm={12} md={6} lg={6} className="mb-4 pb-1">
                      <CustomSelect
                        options={[
                          { label: 'Android', value: 'ANDROID' },
                          { label: 'IOS', value: 'IOS' },
                        ]}
                        controlId="platform"
                        label="Platform"
                        value={values?.platform}
                        name="platform"
                        variant="outlined"
                        disabled={true}
                        errorsField={errors?.platform}
                        touched={touched?.platform}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>
                  <Row className="gx-xl-5">
                    {/* ABOUT TEXT IN ENGLISH */}
                    <Col sm={6} md={6} lg={6} className="mb-4 pb-1">
                      <InputText
                        controlId="description_en"
                        label="Description (en)"
                        value={values?.description_en}
                        name="description_en"
                        maxLength="70"
                        errorsField={errors?.description_en}
                        touched={touched?.description_en}
                        handleChange={handleChange}
                        className={'h-auto w-100'}
                        multiline={true}
                        rows={4}
                        handleBlur={handleBlur}
                        variant="outlined" />
                    </Col>
                    {/* ABOUT TEXT IN ARABIC */}
                    <Col sm={6} md={6} lg={6} className="mb-4 pb-1">
                      <InputText
                        controlId="description_ar"
                        label="Description (ar)"
                        value={values?.description_ar}
                        name="description_ar"
                        dir="rtl"
                        maxLength="70"
                        errorsField={errors?.description_ar}
                        touched={touched?.description_ar}
                        handleChange={handleChange}
                        className={'h-auto w-100'}
                        multiline={true}
                        rows={4}
                        handleBlur={handleBlur}
                        variant="outlined" />
                    </Col>
                  </Row>
                  <Stack direction="horizontal" gap={3} className="my-3">
                    <Button
                      variant="primary"
                      onClick={handleSubmit}
                      className="rounded-xs fs-15"
                      type="submit"
                    // disabled={isSubmitting ?? false}
                    >Save</Button>
                    <Button variant="outline-secondary"
                      onClick={() => { navigate(-1) }}
                      className="rounded-xs fs-15">Cancel</Button>
                  </Stack>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Card>
    </div>
  </div>
};

export default BuildAdd;
