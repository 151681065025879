import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Listing from './List/Listing'
import Edit from './edit/Edit'

export const Directorate =()=> {
    return (
        <Routes>
            <Route path="/" element={<Listing />} />
            <Route path="/edit/:id" element={<Edit/>}/>
        </Routes>
    )
}

export const nameBasedRoutes = {
    directorate: {
        name: "Directorate",
        path: `/app/directorate`,
    },
    editGovernorate: {
        name: "EditDirectorate",
        path: `/app/directorate/edit`
    }
}
