import { Autocomplete, Checkbox, TextField } from '@mui/material'
import React from 'react'

function MultiSelect({ label, placeholder, options, value, handleChange, minWidth, name, variant, errorsField, touched, disabled }) {
    return (
        <Autocomplete
            multiple
            name={name}
            value={value}
            onChange={handleChange}
            disableCloseOnSelect
            options={options ?? []}
            isOptionEqualToValue={(option, value) =>
                option?.value === value?.value && option?.label === value?.label
            }
            getOptionLabel={(option) => option?.label}
            disabled={disabled ? true : false}
            className=''
            renderInput={(params) => (
                <TextField {...params} label={label} placeholder={placeholder} helperText={errorsField ? errorsField : ""} error={errorsField ? true : false} />
            )}
        />
    )
}

export default MultiSelect
