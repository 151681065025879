import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Add from './add/Add'
import Listing from './List/Listing'
import Edit from './edit/Edit'
import { Error404 } from '../Error404'

export const SystemUsers =()=> {
    return (
        <Routes>
            <Route path="/" element={<Listing />} />
            <Route path="/add" element={<Add />} />
            <Route path="/edit/:id" element={<Edit/>}/>
            <Route path="*" element={<Error404/>}/>
        </Routes>
    )
}
export const nameBasedRoutes = {
    SystemUsers: {
        name: "SystemUsers",
        path: `/app/users`,
    },
    addUser: {
        name: "addUser",
        path: `/app/users/add`
    },
    editUser: {
        name: "editUser",
        path: `/app/users/edit`
    }
}
