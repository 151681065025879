import React from 'react'
import { List } from './List'
import { Route, Routes } from 'react-router-dom'
import { Add } from './Add'
import { DetailsConfig } from './DetailsConfig'
import { AmenitiesConfig } from './AmenitiesConfig'
import { Edit } from './Edit'
export const PropertyType = () => {
    return (
        <Routes>
            <Route element={<List />} path="/" />
            <Route path="/add" element={<Add />} />
            <Route path="/edit/:id" element={<Edit />} />
            <Route path="/details-config/:id" element={<DetailsConfig/>}/>
            <Route path="/amenities-config/:id" element={<AmenitiesConfig/>}/>
        </Routes>
    )
}
export const nameBasedRoutes = {
    List : {
        name : "List",
        path : "/app/property-type"
    },
    Add : {
        name : "Add",
        path : "/app/property-type/add"
    },
    Edit : {
        name : "Edit",
        path : "/app/property-type/edit/:id"
    },
    detailsConfig : {
        name : "DetailsConfig",
        path : "/app/property-type/details-config"
    },
    amenitiesConfig : {
        name : "AmenitiesConfig",
        path : "/app/property-type/amenities-config"
    }
}