import React from 'react';
import { CSVLink } from 'react-csv';
import { FaDownload } from "react-icons/fa6";
// COMMON CSV EXPORT BUTTON
const DownloadCSVButton = ({ csvData ,filename}) => {
    return (
        <CSVLink data={csvData} filename={filename ?? 'data.csv'} className='btn btn-theme-outline fw-bold'>
            <FaDownload className='me-2 mb-1'/>
            Download CSV
        </CSVLink>
    );
};

export default DownloadCSVButton;
