import { GET_LATEST_BUILD, UPLOAD_BUILD } from "../constants/paths"
import { axios } from "./axios"

// UPLOAD NEW BUILD API
export const buildUploadApi = (data) => {
  const response = axios.post(UPLOAD_BUILD, data)
  return response
}

// GET LATEST BUILD API
export const getLatestBuildApi = (platform) => {
  const response = axios.get(`${GET_LATEST_BUILD}?platform=${platform}`)
  return response
}