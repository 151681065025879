import React, { useEffect, useState } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { toast } from 'react-toastify';
import { capitalizeAndSeparate, usersCountChart } from '../../services/dashboardService';
import { Bar } from 'react-chartjs-2';
import DownloadCSVButton from './DownloadCSVButton';
ChartJS.register( CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend);


const UsersCountChart = ({startDate ,endDate}) => {

    const [chartData, setChartData] = useState({})

    const fetchUsersData = () => {
        const params = {
            startDate: startDate ,
			endDate: endDate
        }
        usersCountChart(params)
            .then((response) => {
                setChartData(response)
            })
            .catch(error => {
                 if(error?.status == 500){
                toast.error('Unauthorized or server issue. Please refresh or try later. ')
            }else{
                 toast.error(
          error?.error_description
            ? error?.error_description
            : error?.details ? error?.details : error?.message
        );
            }
            })
    }

    useEffect(() => {
        fetchUsersData()
    }, [startDate ,endDate])

    // CHART LABELS

    const label_keys = Object.keys(chartData).filter(key => key !== 'startDate' && key !== 'endDate');

    // CAPITALIZE AND SEPARATE KEY FOR CSV AND CHART LABELS
    const convertedLabels = label_keys.map((key)=>capitalizeAndSeparate(key))
    
    // CHART DATA
    const data = {
        labels: convertedLabels,
        datasets: [
            {
                // label: 'Users',
                data: label_keys.map(label => chartData[label]),
                backgroundColor: [
                    'rgba(203, 88, 167, 0.5882352941)', // Red for New Users
                   
                ],
                borderColor: [
                    'rgba(203, 88, 167, 1)',
                ],
                borderWidth: 1
            }
        ],
    };

    // CHART OPTIONS
    const options = {
        indexAxis: 'y',
        elements: {
          bar: {
            borderWidth: 2,
          },
        },
        responsive: true,
        plugins: {
          legend: false,
        
        },
    };
    // GENERATE CSV DATA
    const csvData = chartData ? [
        convertedLabels,
        label_keys?.map(label => chartData[label])
    ] : [];
    return <div className="h-100 card p-3">
        <div className="d-flex justify-content-between">
            <h5 className="mb-0 position-relative fs-22  text-black fw-medium">Users</h5>
            {
                csvData && csvData?.length > 0 ? <div>
                    <DownloadCSVButton csvData={csvData} filename={'usersCount.csv'} />
                </div> : ""
            }
        </div>
        <div className='h-100'>
            <Bar options={options} data={data} />
        </div>
    </div>
}

export default UsersCountChart
