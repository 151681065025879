import { AGENCY_LIST_API,AGENCY_DETAILS_API, AGENT_LIST_API, AGENCY_APPROVE_DISAPPROVE_API,AGENCY_BLOCK_UNBLOCK_API,EDIT_AGENCY_API,LOGIN_AS_AGENCY_ADMIN, MARK_TRUSTED_AGENCY } from "../constants/paths";
import { axios } from "./axios";

// FETCH AGENCY LIST
export const fetchAgencyList = async (params) => {
    const response = await axios.get(AGENCY_LIST_API, { params });
    return response
}

// FETCH AGENCY DATA
export const fetchAgencyData = async (id) => {
    const response = await axios.get(`${AGENCY_DETAILS_API}/${id}`);
    return response?.data
}

// FETCH AGENCY LIST
export const fetchAgentList = async (params) => {
    const response = await axios.get(AGENT_LIST_API, { params });
    return response
}

// AGENCY ACCOUNT APPROVE AND DISAPPROVE API
export const AgencyAccountApproveDisapprove = async (data) => {
    const response = await axios.put(AGENCY_APPROVE_DISAPPROVE_API, data);
    return response?.data
}
// AGENCY BLOCK UNBLOCK API

export const AgencyBlockUnblockApi = async (agencyId,status)=>{
    const response = await axios.get(`${AGENCY_BLOCK_UNBLOCK_API}/${agencyId}/${status}`);
    return response?.data
}
// EDIT AGENCY
export const EditAgencyApi = async (data)=>{
    const response = await axios.put(`${EDIT_AGENCY_API}`,data);
    return response?.data
}
// LOGIN AS AGENCY ADMIN
export const LoginAsAgencyAdminApi = async(agencyId) =>{
    const response = await axios.get(`${LOGIN_AS_AGENCY_ADMIN}/${agencyId}`);
    return response?.data 
}
// MARK AGENCY TRUSTED
export const markTrustedAgency = async(id,status) =>{
    const response = await axios.put(`${MARK_TRUSTED_AGENCY}/${id}?trusted=${status}`)
    return response?.data
}

