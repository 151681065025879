import { TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { Card, Stack, Row, Col } from 'react-bootstrap';
import { MdSupervisorAccount, MdClear } from "react-icons/md";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { MatDataGridWithPagination } from '../../../components/common/dataGrid/DataGridWithPagination';
import { ActionCell } from '../../../components/common/dataGrid/ActionCell';
import { getSystemUsersList, updateSystemUserStatus } from '../../../services/systemUserService';
import { toast } from 'react-toastify';
import LoaderMu from '../../../components/common/LoaderMu';
import { Switch } from 'antd';
import { nameBasedRoutes } from '../routes';
import { useSelector } from 'react-redux';
import NotAuthorized from '../../../components/common/NotAuthorized';
function Listing() {


  // MANAGING PERMISSIONS HERE

  const roleDetails = useSelector((state) => state?.rolesData?.masterPermissionResponseDTOList)

  // CHECK IF USER IS ADMIN

  const isAdmin = useSelector((state) => state?.adminData?.authorities?.includes('ROLE_ADMIN'))

  const [isAuthorized, setIsAuthorized] = useState(false)
  const [authPermissions, setAuthPermissions] = useState(null)


  useEffect(() => {

    if (roleDetails) {
      const permissions = roleDetails.find((detail) => {
        return detail.childPermissions.find((childPerm) => (
          childPerm?.key === "user_list" && childPerm?.status === true
        ))
      })
      if (permissions) {
        setIsAuthorized(true)
        // Create an object from childPermissions
        const permissionsObject = {};
        permissions?.childPermissions.forEach(permission => {
          permissionsObject[permission.title.toLowerCase()] = permission.status;
        });
        setAuthPermissions(permissionsObject)
      } else {
        setIsAuthorized(false)
      }
    } else {
      setIsAuthorized(true)
    }

  }, [roleDetails])


  // FOR MANAGING PAGINATION USING URL
  const location = useLocation();

  const params = new URLSearchParams(location?.search)

  const page = params.get("page") ?? 0; // GET CURRENT PAGE FROM URL
  const search = params.get("search") ?? '' // GET SEARCH VALUE FROM URL

  const navigate = useNavigate()

  const [loading, setLoading] = useState(true)
  const [usersList, setUsersList] = useState([])
  const [searchValue, setSearchValue] = useState(search ?? '');
  const [pageSize, setPageSize] = useState(10)
  const [totalResults, setTotalResults] = useState(0);
  const [sortingData, setSortingData] = useState({});


  // HANDLING SEARCHING 
  const handleValueChange = (value) => {
    setSearchValue(value);
    if (value !== "") {
      navigate(`${nameBasedRoutes?.SystemUsers?.path}?page=${page}&search=${encodeURIComponent(value)}`)
    } else {
      navigate(`${nameBasedRoutes?.SystemUsers?.path}?page=${page}`)
    }

  };
  //HANDLING PAGE CHANGE
  const handlePageChange = (value) => {
    navigate(`${nameBasedRoutes?.SystemUsers?.path}?page=${value?.page}${search !== '' ? '&search=' + search : ''}`)
    setPageSize(value?.pageSize)
  }
  // HANDLING SORT MODAL CHANGE
  const handleSortModelChange = (data) => {
    setSortingData(data?.length > 0 ? data[0] : {})
  }

  // CLEAR SEARCH INPUT BOX
  const handleClear = (value) => {
    if (search !== "") {
      navigate(`${nameBasedRoutes?.SystemUsers?.path}?page=${page}`)
    }
    setSearchValue("")
  };

  // COLUMNS FOR DATA TABLE
  const columns = [
    {
      field: "id",
      headerName: "S. No.",
      editable: false,
      flex: 0.05,
      minWidth: 100,
      sortable: false,
      renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1 + ((page * pageSize))
    },
    {
      field: "firstName",
      headerName: "First Name",
      editable: false,
      flex: 0.6,
      minWidth: 150,
      sortable: true,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      editable: false,
      flex: 0.6,
      minWidth: 150,
      sortable: true,
    },
    {
      field: "email",
      headerName: "Email",
      editable: false,
      flex: 0.6,
      minWidth: 220,
      sortable: true,
    },
    {
      field: "roles",
      headerName: "Role",
      editable: false,
      flex: 0.6,
      minWidth: 150,
      sortable: true,
      renderCell: (props) => {
        return <span>{props?.row?.roles[0]?.name}</span>
      }
    },
    {
      field: "status",
      headerName: "Status",
      editable: false,
      sortable: false,
      flex: 0.6,
      minWidth: 150,
      renderCell: (props) => {
        return <Switch checked={props?.row?.status === "PENDING" || props?.row?.status === "DISABLED" ? false : true} checkedChildren={props?.row?.status} unCheckedChildren={props?.row?.status}
          className='fw-bold' onChange={(value) => { handleUserStatus(value, props?.row?.id) }} />
      }
    },
    {
      field: "action",
      headerName: "Action",
      editable: false,
      flex: 0.2,
      minWidth: 120,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (props) => {
        return (
          <ActionCell
            controlId="app/users"
            isViewEnable={false}
            isEditEnable={(isAdmin || authPermissions === null) ? true : authPermissions?.edit}
            redirectID={props.row.id}
            rowData={props.row}
            pageNumber={page}
            searchedValue={searchValue}
          />
        );
      },
      // renderCell: (props) => { return <ActionCell controlId='app/artical' isViewEnable={true} isEditEnable={false} isDeleteEnable={true}  deleteFunctionName={deleteRow} isLogInEnable={true} redirectID={props.row.id} rowData={props.row} pageNumber={page} searchedValue={selectedValue}  /> }
    },]

  // GET USERS DATA LIST FOR TABLE
  const fetchUsersData = () => {
    setLoading(true)
    const params = {
      page: page ?? 0,
      size: pageSize ?? 10,
      search: searchValue ?? '',
    }
    if (sortingData && Object.keys(sortingData).length !== 0) {
      params["sort"] = `${sortingData?.field},${sortingData?.sort?.toUpperCase()}`
    }
    getSystemUsersList(params).then(response => {
      setLoading(false)
      setTotalResults(response?.headers["x-total-count"])
      setUsersList(response?.data)
    }).catch((error) => {
      setLoading(false)
      if (error?.status == 500) {
        toast.error('Unauthorized or server issue. Please refresh or try later. ')
      } else {
         toast.error(
          error?.error_description
            ? error?.error_description
            : error?.details ? error?.details : error?.message
        );
      }
    })
  }

  // UPDATE ENABLED / DISABLED STATUS TOGGLE
  const handleUserStatus = (value, id) => {
    setLoading(true)

    let status = value === true ? 'ENABLED' : 'DISABLED'

    updateSystemUserStatus(id, status).then(response => {
      setLoading(false)
      fetchUsersData()
      toast.success(response?.message ?? 'Status updated!')
    }).catch((error) => {
      setLoading(false)
      if (error?.status == 500) {
        toast.error('Unauthorized or server issue. Please refresh or try later. ')
      } else {
         toast.error(
          error?.error_description
            ? error?.error_description
            : error?.details ? error?.details : error?.message
        );
      }
    })

  }
  useEffect(() => {
    // CALLING FUNCTION FOR FETCH DATA
    if (isAuthorized) {
      fetchUsersData()
    } else {
      setLoading(false)
    }
  }, [page, pageSize, searchValue, sortingData, isAuthorized])


  return (
    <div className="d-flex flex-column pageContainer p-3 h-100">
      <LoaderMu loading={loading} />
      <div className="flex-grow-1 h-100 pageContent position-relative pt-4 overflow-auto">
        {
          !isAuthorized && !loading ?
            <NotAuthorized /> :
            <Card className="h-100 bg-white shadow border-0 theme-card-cover">
              <div className="theme-card-header px-1">
                <Stack
                  direction="horizontal"
                  gap={2}
                  className="flex-wrap justify-content-between px-3 pt-3 pb-2 align-items-start"
                >
                  <h5 className="mb-0 position-relative fs-18 text-black fw-medium">
                    <div className="align-items-center bg-primary d-inline-flex h-60 justify-content-center  position-absolute rounded start-0 text-white theme-icon-box w-60 z-1">
                      <MdSupervisorAccount size={40} />
                    </div>
                    Manage System Users
                  </h5>
                  {/* ONLY AUTHORIZED USER CAN ADD NEW SYSTEM USER */}
                  {
                    (isAdmin || (authPermissions !== null && authPermissions?.add === true)) ? <Link className='btn btn-primary' to={nameBasedRoutes?.addUser?.path} variant="primary">Add New</Link> : ""
                  }
                </Stack>
              </div>
              <div className="flex-grow-1 d-flex flex-column px-3 h-100 overflow-auto">
                <div className="theme-data-table-filter px-1">
                  <Row className="justify-content-end">
                    {/* SEARCH INPUT */}
                    <Col sm={5} lg={2} className="mb-3 pb-1">
                      <TextField
                        id="search-filter"
                        label="Search"
                        variant="standard"
                        fullWidth
                        value={searchValue}
                        onChange={(e) => {
                          handleValueChange(e.target.value);
                        }}
                      />
                      {/* CLEAR SEARCH VALUE ICON */}
                      <MdClear
                        className="clear-postion"
                        onClick={handleClear}
                      ></MdClear>
                    </Col>
                  </Row>
                </div>
                {/* RENDERING DATA TABLE COMPONENT */}
                <MatDataGridWithPagination
                  controlId={"id"}
                  columns={columns}
                  data={usersList ?? []}
                  onPaginationModelChange={handlePageChange}
                  pageSize={pageSize}
                  totalResults={totalResults}
                  currentPage={parseInt(page)}
                  handleSortModelChange={handleSortModelChange}
                // totalPages={totalPages}
                // rowsPerPage={rowPerPage}
                />
              </div>
            </Card>}
      </div>
    </div>
  )
}

export default Listing
