export const FORGOT_API = `/auth/forgot-password`;
export const RESET_API = `/auth/reset-password`;
export const REFRESH_TOKEN = `/auth/refresh-tokens`;
export const VERIFICATION = `/auth/mfa`;
export const LOGOUT = `/auth/logout`;
export const RESEND_CODE = `/auth/mfa-resend`;
export const GET_DASHBOARD_COUNT_API = `/dashboard/get-dashboard-count`;
export const LISTING_LEVEL_API = `/dashboard/level/listing`;
export const SET_NEW_PASSWORD_API = `/auth/change-password`;

export const GET_CMS_LIST_API = `/dashboard/cms/list`;
export const ADD_CMS_API = `/dashboard/cms/submit`;
export const VIEW_CMS_API = `/dashboard/cms/view`;


export const GET_CONTACTUS_LIST_API = `/dashboard/contact/list`;
export const REPLY_CONTACTUS_API = `/dashboard/contact/reply`;
export const VIEW_CONTACTUS_API = `/dashboard/contact/view`;
export const GET_QOUTES_LIST_API = `/dashboard/qoutes/list`;
export const SUBMIT_ARTICAL_API = `/dashboard/artical/submit`;
export const VIEW_ARTICAL_API = `/dashboard/artical/view`;
export const STATUS_ARTICAL_API = `/dashboard/artical/change-status`;
export const DELETE_ARTICAL_API = `/dashboard/artical/delete`;
export const IMAGE_UPLOAD_API = `/user/image/upload`
export const VIDEO_UPLOAD_API = `/user/video/upload`


// AMAKEN URLS
export const LOGIN_API = `/v1/sign-in`;
export const GET_USER_ROLE_DATA = `/v1/admin/account/role`

export const GET_ROLES_LIST = `/v1/admin/roles`
export const GET_ROLE_BY_ID = `/v1/admin/role`
export const UPDATE_ROLES_PERMISSION = `v1/admin/role-add`
export const GET_USER_INFO = `/v1/admin/account`
export const FORGOT_PASSWORD_API = `/v1/account/reset-password/init`
export const RESET_PASSWORD_API = `/v1/account/reset-password/finish`
export const GET_GOVERNORATE_LIST_API = `/v1/admin/governorate`
export const EDIT_GOVERNORATE_API = `/v1/admin/governorate-edit`
export const GET_DIRECTORATE_LIST_API = `/v1/admin/directorate`
export const UPDATE_DIRECTORATE_API = `/v1/admin/directorate-edit`
export const GET_VILLAGE_LIST_API = `/v1/admin/village`
export const UPDATE_VILLAGE_API = `/v1/admin/village-edit`
export const SAVE_AMENITY_API = `/v1/admin/amenity-add`
export const UPDATE_AMENITY_API = `/v1/admin/amenity-edit`
export const UPDATE_AMENITY_STATUS_API = `/v1/admin/amenity/status-update`
export const GET_AMENITY_LIST_API = `/v1/admin/amenity`

// PACKAGES

export const EDIT_SUBSCIPTION_API = `/v1/admin/subscription-manage/edit-plan`
export const CREATE_SUBSCIPTION_API = `/v1/admin/subscription-manage/create-plan`
export const GET_SUBSCIPTION_LIST_API = `/v1/admin/subscription-manage/fetch-all-plan`
export const GET_SPECIFIC_SUBSCIPTION_API = `/v1/admin/subscription-manage/get-plan/` // {id}
export const UPDATE_STATUS_SUBSCIPTION_API = `/v1/admin/subscription-manage/plan-enable-disable/` // {id}/{status}

export const VERIFY_ACCOUNT = `/v1/admin/account-activation-platform-user`

// SYSTEM USER'S APIS 

export const ADD_SYSTEM_USER =  `/v1/admin/system-user-add`
export const GET_ROLE_LIST = `/v1/admin/system-user-roles`
export const GET_SYSTEM_USER_LIST = `/v1/admin/system-user`
export const EDIT_SYSTEM_USER =  `/v1/admin/system-user-edit`
export const GET_SYSTEM_USER_BY_ID = `/v1/admin/system-user`
export const UPDATE_SYSTEM_USER_STATUS = `/v1/admin/enable-disable-system-user`
export const CHANGE_PASSWORD_SYSTEM_USER = `/v1/admin/system-user-change-password`


// PLATFORM USER'S APIS

export const UPDATE_WEBSITE_USER_API = `/v1/admin/user/user-enable-disable`
export const GET_WEBSITE_USERS_LIST_API = `/v1/admin/user/user-list`
export const GET_WEBSITE_USERS_DETAIL_API = `/v1/admin/user/user-detail`

// MANAGE AGENCY APIS

export const AGENCY_LIST_API = `/v1/admin/agency-list`
export const AGENCY_DETAILS_API = `/v1/admin/agency`
export const AGENT_LIST_API = `/v1/admin/agency-agent-list`
export const AGENCY_APPROVE_DISAPPROVE_API = `/v1/admin/agency-activation`
export const AGENCY_BLOCK_UNBLOCK_API = `/v1/admin/agency-enable-disable`
export const EDIT_AGENCY_API = `/v1/admin/agency-edit`
export const MARK_TRUSTED_AGENCY = `/v1/admin/agency/trusted`
export const LOGIN_AS_AGENCY_ADMIN = `/v1/admin/agency-sign-in`

// MANAGE CMS APIS

export const CMS_LIST_API = `/v1/admin/cms-list`
export const UPDATE_CMS_STATUS = `/v1/admin/cms-enable-disable`
export const GET_CMS_DATA_API = `/v1/admin/cms`
export const EDIT_CMS_DATA_API = `/v1/admin/cms-edit`



// MANAGE FAQ APIS
export const FAQ_CATEGORY_LIST = `/v1/admin/faq-category-list`
export const FAQ_LIST_API = `/v1/admin/faq-list`
export const ADD_FAQ_API = `/v1/admin/faq-create`
export const GET_FAQ_BY_ID_API = `/v1/admin/faq`
export const EDIT_FAQ_API = `/v1/admin/faq-edit`
export const FAQ_STATUS_UPDATE_API = `/v1/admin/faq-enable-disable`

// CUSTOM PACKAGES
export const CUSTOM_PLAN_AGENCY_LIST = `/v1/admin/subscription-manage/custom/fetch-all-agencies`
export const CUSTOM_PLAN_CREATE = `/v1/admin/subscription-manage/custom/create-plan`
export const CUSTOM_PLAN_LIST_API = `/v1/admin/subscription-manage/custom/fetch-all-plan`
export const CUSTOM_PLAN_DETAILS = `/v1/admin/subscription-manage/custom/get-plan`
export const CUSTOM_LIST_EDIT_API = `/v1/admin/subscription-manage/custom/edit-plan`
export const CUSTOM_PLAN_STATUS_UPDATE_API = `/v1/admin/subscription-manage/custom/plan-enable-disable`

// PROPERTY TYPE
export const ADD_PROPERTY_TYPE_API = `/v1/admin/property-type`
export const PROPERTY_TYPE_LISTING = `/v1/admin/property-type-list`
export const PROPERTY_DETAILS_API = `/v1/admin/property-type/detail`
export const SAVE_PROPERTY_TYPE = `/v1/admin/property-type-config`
export const PROPERTY_TYPE_STATUS = `/v1/admin/property-type/status`
export const SAVE_AMENITIES = `v1/admin/property-type/amenity-config`
export const AMENITIES_LIST = `/v1/admin/amenity-list`
export const EDIT_PROPERTY_TYPE = `/v1/admin/property-type-edit`
export const PROPERTY_TYPE_INFO = `/v1/admin/property-type/info`

// NOTIFICATION
export const ADD_NOTIFICATION = `/v1/admin/user/sent/push-notification`

// COMPLAINTS
export const COMPLAINT_LIST = `/v1/admin/complaint-property/list`
export const GENERAL_INFO_API = `/v1/admin/general-info`


// DASHBOARD API'S

export const MAIN_STATS_API = `/v1/admin/stat/main`
export const PROPERTY_CHART_API = `/v1/admin/stat/year-wise-property`
export const USERS_CHART_API = `/v1/admin/stat/year-wise-user`
export const PROPERTY_TYPE_CHART_API = `/v1/admin/stat/property-type`
export const USERS_COUNT_CHART_API = `/v1/admin/stat/user`

// REPORT API'S

export const AGENT_REPORT_API = `/v1/admin/report/download-agent`
export const PLATFORM_USER_REPORT = `/v1/admin/report/download-platform-user`
export const PROPERTY_REPORT_API = `/v1/admin/report/download-property`
export const PLAN_REPORT_API = `/v1/admin/report/download-plan`
export const SYSTEM_USER_REPORT_API = `/v1/admin/report/download-system-user`
export const AGENCY_REPORT_API = `/v1/admin/report/download-agency`
export const PLAN_LIST = `/v1/admin/subscription-manage/plan-list`

export const VILLAGE_SEARCH_API = `/v1/admin/village-search`
export const AGENCY_SEARCH_API = `/v1/admin/agency-search`

// BLOGS API'S

export const BLOG_LIST_API = `/v1/admin/blog-post-list`
export const BLOG_ADD_API = `/v1/admin/blog-post-add`
export const BLOG_EDIT_API = `/v1/admin/blog-post-edit`
export const BLOG_DETAILS_API = `/v1/admin/blog-post`
export const UPDATE_BLOG_STATUS = `/v1/admin/blog-post-enable-disable`

export const UPLOAD_BUILD = `/v1/admin/build-upload`
export const GET_LATEST_BUILD = `/v1/admin/build-latest`