import { TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { Card, Stack, Row, Col } from 'react-bootstrap';
import { MdSupervisorAccount, MdClear } from "react-icons/md";
import { Link } from 'react-router-dom';
import { MatDataGridWithPagination } from '../../../components/common/dataGrid/DataGridWithPagination';
import { ActionCell } from '../../../components/common/dataGrid/ActionCell';
import { getPackageListAPI } from '../../../services/packageServices';
import { toast } from 'react-toastify';
import LoaderMu from '../../../components/common/LoaderMu';
import { RiHistoryFill } from "react-icons/ri";

export const List = () => {


  const [loading, setLoading] = useState(false)
  const [auditList, setAuditList] = useState([])
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalResults, setTotalResults] = useState(0);
  const [sortingData, setSortingData] = useState({});

  // THIS IS FOR SEARCHING
  const handleValueChange = (value) => {
    setSearchValue(value);
  };

  // THIS IS FOR PAGE CHANGE
  const handlePageChange = (value) => {
    setCurrentPage(value?.page)
    setPageSize(value?.pageSize)
  }

  // HANDLE SORT MODAL CHANGE
  const handleSortModelChange = (data) => {
    // console.log(data)
    setSortingData(data?.length > 0 ? data[0] : {})
  }

  // CLEAR SEARCH INPUT BOX
  const handleClear = (value) => {
    setSearchValue("");
  };

  // COLUMNS FOR DATA TABLE
  const columns = [
    {
      field: "id",
      headerName: "S. No.",
      editable: false,
      flex: 0.05,
      minWidth: 100,
      sortable: false,
      renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1 + ((currentPage * pageSize))
    },
    {
      field: "title",
      headerName: "Listing title",
      editable: false,
      sortable: true,
      flex: 0.6,
      minWidth: 120,

    },
    {
      field: "Activity",
      headerName: "Activity",
      editable: false,
      flex: 0.2,
      minWidth: 120,
      sortable: true,
    },
    {
      field: "view",
      headerName: "View",
      editable: false,
      flex: 0.2,
      minWidth: 120,
      sortable: false,
    }
  ]

  // GET DATA LIST FOR TABLE
  const fetchUsersData = () => {
    setLoading(true)
    // ?search=&page=0&size=10&sort=createdDate,DESC
    const params = {
      page: currentPage ?? 0,
      size: pageSize ?? 10,
      search: searchValue ?? '',
    }
    if (sortingData && Object.keys(sortingData).length !== 0) {
      params["sort"] = `${sortingData?.field},${sortingData?.sort?.toUpperCase()}`
    }
    getPackageListAPI(params).then(response => {
      setLoading(false)
      setTotalResults(response?.headers["x-total-count"])
      setAuditList(response?.data)
    }).catch((error) => {
      setLoading(false)
       if(error?.status == 500){
                toast.error('Unauthorized or server issue. Please refresh or try later. ')
            }else{
                 toast.error(
          error?.error_description
            ? error?.error_description
            : error?.details ? error?.details : error?.message
        );
            }
    })
  }


  useEffect(() => {
    // CALLING FUNCTION FOR FETCH DATA
    // fetchUsersData()
  }, [currentPage, pageSize, searchValue, sortingData])

  return (
    <div className="d-flex flex-column pageContainer p-3 h-100">
      <LoaderMu loading={loading} />
      <div className="flex-grow-1 h-100 pageContent position-relative pt-4 overflow-auto">
        <Card className="h-100 bg-white shadow border-0 theme-card-cover">
          <div className="theme-card-header px-1">
            <Stack
              direction="horizontal"
              gap={2}
              className="flex-wrap justify-content-between px-3 pt-3 pb-2 align-items-start"
            >
              <h5 className="mb-0 position-relative fs-18 text-black fw-medium">
                <div className="align-items-center bg-primary d-inline-flex h-60 justify-content-center  position-absolute rounded start-0 text-white theme-icon-box w-60 z-1">
                  <RiHistoryFill size={40} />
                </div>
                Audit
              </h5>
            </Stack>
          </div>
          <div className="flex-grow-1 d-flex flex-column px-3 h-100 overflow-auto">
            <div className="theme-data-table-filter px-1">
              <Row className="justify-content-end">
                <Col sm={5} lg={2} className="mb-3 pb-1">
                  <TextField
                    id="search-filter"
                    label="Search"
                    variant="standard"
                    fullWidth
                    value={searchValue}
                    onChange={(e) => {
                      handleValueChange(e.target.value);
                    }}
                  />
                  <MdClear
                    className="clear-postion"
                    onClick={handleClear}
                  ></MdClear>
                </Col>
              </Row>
            </div>
            <MatDataGridWithPagination
              controlId={"id"}
              columns={columns}
              data={auditList ?? []}
              onPaginationModelChange={handlePageChange}
              pageSize={pageSize}
              totalResults={totalResults}
              currentPage={currentPage}
              handleSortModelChange={handleSortModelChange}
            // totalPages={totalPages}
            // rowsPerPage={rowPerPage}
            />
          </div>
        </Card>
      </div>
    </div>
  )
}

