import { Route, Routes } from 'react-router-dom';
import { AllList } from './List/List';

import  Edit from './Edit/Edit';
import { Error404 } from '../Error404';


export const Governorate = () => {
    return (  
        <Routes>
            <Route path="/" element={<AllList />} />
            <Route path="/edit/:id" element={<Edit />} />
            <Route path="*" element={<Error404/>}/>
        </Routes>
    )
};

export const nameBasedRoutes = {
    governorate: {
        name: "Governorate",
        path: `/app/governorate`,
    },
    editGovernorate: {
        name: "EditGovernorate",
        path: `/app/governorate/edit`
    }
}