import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Add from './add/Add'
import Listing from './List/Listing'
import Edit from './edit/Edit'

export const Package =()=> {
    return (
        <Routes>
            <Route path="/" element={<Listing />} />
            <Route path="/add" element={<Add />} />
            <Route path="/edit/:id" element={<Edit/>}/>
        </Routes>
    )
}
export const nameBasedRoutes = {
    packageList: {
        name: "package",
        path: `/app/package`,
    },
    viewPackage: {
        name: "View Packages",
        path: `/app/package/view`
    },
    editPackage: {
        name: "Edit Packages",
        path: `/app/package/edit`
    }
}


