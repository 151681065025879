import { Autocomplete, TextField } from '@mui/material'
import React from 'react'
import { Form } from 'react-bootstrap'

const TagsInput = ({ controlId, tags, handleChange, label, placeholder, errorsField, touched, dir , handleBlur }) => {
  return (
    <div className='position-relative'>
      <Autocomplete
        dir={dir}
        style={{ margin: "10px 0" }}
        multiple
        id={controlId}
        options={tags}
        freeSolo
        autoComplete
        onChange={handleChange}
        value={tags}
        // error={errorsField || touched}
        // helperText={errorsField || touched}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            onBlur={handleBlur}
            helperText={errorsField && touched ? errorsField : ""}
            error={errorsField && touched}
          // placeholder={placeholder}
          />
        )}
      />
      <Form.Control.Feedback type="invalid">
        {errorsField}
      </Form.Control.Feedback>
    </div>
  )
}

export default TagsInput