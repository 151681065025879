import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { List } from './List'
import Add from './Add'
import Edit from './Edit'

export const Custom_package = () => {
  return (
    <Routes>
      <Route path="/" element={<List />} />
      <Route path="/add" element={<Add />} />
      <Route path="/edit/:id" element={<Edit />} />
    </Routes>
  )
}

export const nameBasedRoutes = {
  custom_package: {
      name: "List Custom Packages",
      path: `/app/custom-package`,
  },
  viewCustom_package: {
      name: "View Custom Packages",
      path: `/app/custom-package/view`
  },
  editCustom_package: {
      name: "Edit Custom Packages",
      path: `/app/custom-package/edit`
  }
}
