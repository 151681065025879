import React, { useEffect, useState } from 'react'
import { Form, Formik, getIn } from 'formik'
import { Button, Card, Col, Row, Stack } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import { FaUserEdit } from "react-icons/fa";
import InputText from '../../../components/common/InputText'
import { getGovernorateById } from '../../../services/governorateServices'
import { toast } from 'react-toastify'
import { updateGovernorateSchema } from '../../../validations/ValidationSchema'
import axios from "axios"
import { EDIT_GOVERNORATE_API } from '../../../constants/paths';
import storage from "../../../helpers/storage";
import { Image } from 'antd';
import LoaderMu from '../../../components/common/LoaderMu';
import { useSelector } from 'react-redux';
import NotAuthorized from '../../../components/common/NotAuthorized';

function Edit() {

  // MANAGING PERMISSIONS HERE

  const roleDetails = useSelector((state) => state?.rolesData?.masterPermissionResponseDTOList)

  const [isAuthorized, setIsAuthorized] = useState(false)

  useEffect(() => {

    if (roleDetails) {
      const permissions = roleDetails.find((detail) => {
        return detail.childPermissions.find((childPerm) => (
          childPerm?.key === "governorate_edit" && childPerm?.status === true
        ))
      })
      if (permissions) {
        setIsAuthorized(true)
      } else {
        setIsAuthorized(false)
      }
    } else {
      setIsAuthorized(true)
    }

  }, [roleDetails])

  const navigate = useNavigate()
  const [selectedFile, setSelectedFile] = useState('');
  const [loading, setLoading] = useState(false)

  const [initialValues, setInitialValues] = useState({
    governorate_en: "",
    governorate_ar: "",
    capital_en: "",
    capital_ar: "",
    image: ""
  })

  const { id } = useParams()

  // add header
  function authRequestInterceptor(config) {
    const token = storage.getToken()
    if (token) {
      config.headers.authorization = `Bearer ${token}`
    }
    config.headers['Mode'] = 'no-cors';
    config.headers['Device-Type'] = 'BROWSER';
    config.headers["Accept-Language"] = "en";

    return config
  }

  // Set image in field
  const handleUploadImage = async (event, setFieldValue) => {
    setSelectedFile(event.target.files[0]);
    setFieldValue('image', event.target.files[0])
    //console.log('selectedFile',selectedFile)
  }

  // FORM SUBMIT FOR CREATE PLATFORM USER
  const handleSubmit = async (values, actions) => {
    setLoading(true)
    const formData = new FormData();
    // Apply authorization header to formData using the interceptor function
    const config = authRequestInterceptor({
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    axios.interceptors.request.use(authRequestInterceptor)

    if (selectedFile && selectedFile !== '') {
      formData.append('image', selectedFile ?? null)
    }
    ;
    formData.append('id', id);
    formData.append('capital[en]', values?.capital_en);
    formData.append('capital[ar]', values?.capital_ar);
    formData.append('title[ar]', values?.governorate_ar);
    formData.append('title[en]', values?.governorate_en);

    try {
      const api_url = process.env.REACT_APP_API_URL + EDIT_GOVERNORATE_API;
      const response = await axios.put(api_url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (response.data) {
        toast.success(response?.data?.message)
        navigate(-1)
        // navigate(nameBasedRoutes?.governorate?.path)
      } else {
        toast.error(
          response?.data?.error_description
            ? response?.data?.error_description
            : response?.data?.message
        );
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
       if(error?.status == 500){
                toast.error('Unauthorized or server issue. Please refresh or try later. ')
            }else{
                 toast.error(
          error?.error_description
            ? error?.error_description
            : error?.details ? error?.details : error?.message
        );
            }
    }
  }

  // FETCH GOVERNORATE DATA FOR INITIAL FORM
  const fetchGovernorateData = () => {
    setLoading(true)
    // GETTING DATA FROM API
    getGovernorateById(id).then(response => {
      setLoading(false)
      setInitialValues({
        governorate_en: response?.title?.en,
        governorate_ar: response?.title?.ar,
        capital_en: response?.capital?.en,
        capital_ar: response?.capital?.ar,
        imageUrl: response?.imageUrl,
        image: response?.image,

      })
    }).catch((error) => {
      setLoading(false)
       if(error?.status == 500){
                toast.error('Unauthorized or server issue. Please refresh or try later. ')
            }else{
                 toast.error(
          error?.error_description
            ? error?.error_description
            : error?.details ? error?.details : error?.message
        );
            }
    })
  }
  // IF USER IS AUTHORIZED THEN ONLY GET GOVERNORATE DATA
  useEffect(() => {
    if (isAuthorized) {
      fetchGovernorateData()
    } else {
      setLoading(false)
    }
  }, [isAuthorized])

  return (
    <div className="d-flex flex-column pageContainer p-3 h-100 overflow-auto">
      <LoaderMu loading={loading} />
      {
        !isAuthorized && !loading ?
          <NotAuthorized /> :

          <div className="flex-grow-1 pageContent position-relative pt-4">
            {/* {JSON.stringify(data)} */}
            <Card className="h-100 bg-white shadow border-0 theme-card-cover">
              <div className="theme-card-header px-1">
                <Stack direction="horizontal" gap={2} className="flex-wrap justify-content-between px-3 pt-3 pb-2 align-items-start">
                  <h5 className="mb-0 position-relative fw-medium">
                    <div className="align-items-center bg-primary d-inline-flex h-60 justify-content-center  position-absolute rounded start-0 text-white theme-icon-box w-60 z-1">
                      <span className="theme-icon-box-inner"><FaUserEdit size={36} /></span>
                    </div>
                    Edit Governorate
                  </h5>
                </Stack>
              </div>
              <div className="flex-grow-1 d-flex flex-column px-1">
                <div className="p-3 d-flex flex-column h-100">
                  <Formik
                    enableReinitialize={true}
                    validationSchema={updateGovernorateSchema}
                    initialValues={initialValues}
                    onSubmit={(values, actions) => {
                      actions.setSubmitting(false)
                      handleSubmit(values, actions)
                    }}
                  >
                    {({
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      values,
                      setFieldValue,
                      touched,
                      isValid,
                      errors
                    }) => (
                      <Form className="d-flex flex-column flex-grow-1 theme-from">
                        <Row className="gx-xl-5">
                          <Col sm={6} md={6} lg={6} className="mb-4 pb-1">
                            <InputText
                              type="governorate_en"
                              controlId="governorate_en"
                              label="Governorate english"
                              value={values?.governorate_en}
                              name="governorate_en"
                              maxLength="70"
                              errorsField={errors?.governorate_en}
                              touched={touched?.governorate_en}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              className={"w-100 form-control"}
                              variant="outlined"
                            />
                          </Col>
                          <Col sm={6} md={6} lg={6} className="mb-4 pb-1">
                             <div>
                              <InputText
                                dir="rtl"
                                type="governorate_ar"
                                controlId="governorate_ar"
                                label="Governorate arabic"
                                value={values?.governorate_ar}
                                name="governorate_ar"
                                maxLength="70"
                                errorsField={errors?.governorate_ar}
                                touched={touched?.governorate_ar}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                className={"w-100 form-control"}
                                variant="outlined"
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row className="gx-xl-5">
                          <Col sm={6} md={6} lg={6} className="mb-4 pb-1">
                            <InputText
                              type="capital_en"
                              controlId="capital_en"
                              label="Capital english"
                              value={values?.capital_en}
                              name="capital_en"
                              maxLength="70"
                              errorsField={errors?.capital_en}
                              touched={touched?.capital_en}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              className={"w-100 form-control"}
                              variant="outlined"
                            />
                          </Col>
                          <Col sm={6} md={6} lg={6} className="mb-4 pb-1">
                             <div>
                              <InputText
                                dir="rtl"
                                type="capital_ar"
                                controlId="capital_ar"
                                label="Capital arabic"
                                value={values?.capital_ar}
                                name="capital_ar"
                                maxLength="70"
                                errorsField={errors?.capital_ar}
                                touched={touched?.capital_ar}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                className={"w-100 form-control"}
                                variant="outlined"
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row className="gx-xl-5">
                          <Col sm={6} md={6} lg={"auto"} className="my-1">
                            <label className='d-block mb-1'>Upload Governorate Image</label>
                            <label className='position-relative'>
                              <input
                                type="file"
                                name="image"
                                className='form-control'
                                onChange={(e) => { handleUploadImage(e, setFieldValue) }}
                                // className='opacity-0 position-absolute start-0 w-64' 
                                id='browse'
                              />
                            </label>
                            <p className='text-danger answer-type-error fs-11'>{getIn(errors, `image`)}</p>
                          </Col>
                          <Col sm={3} md={3} lg={3} className="my-1">
                            <div className='height-100 width-100 d-flex justify-content-center align-items-center profilebg rounded-2 me-3'>
                              {values.imageUrl ? <Image src={values?.imageUrl} className='height-100' /> : ''}
                            </div>
                          </Col>
                        </Row>

                        <Stack direction="horizontal" gap={3} className="my-3">
                          <Button
                            variant="primary"
                            onClick={handleSubmit}
                            className="rounded-xs fs-15"
                            type="submit"
                            disabled={loading}
                          >Update</Button>
                          <Button variant="outline-secondary"
                            onClick={() => { navigate(-1) }}
                            className="rounded-xs fs-15">Cancel</Button>
                        </Stack>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </Card>
          </div>

      }

    </div>
  )
}

export default Edit
