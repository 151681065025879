
import { DataGrid} from '@mui/x-data-grid';
import React from 'react'
import './datagrid.scss';
import { Empty } from 'antd';

export const MatDataGridWithPagination = ({ controlId,
  getRowId,
  columns,
  data,
  sortModel,
  totalResults,
  columnGroupingModel,
  currentPage,
  pageSize,
  handlePageSizeChange,
  pagination,
  totalPages,
  onPaginationModelChange,
  handleSortModelChange
}) => {
  const NoRowsFoundMessage = () => {
    return (
      <Empty/>
      // <div style={{ textAlign: 'center', padding: '16px' }}>
      //   No records found.
      // </div>
    );
  };


  return (
    <div className="dataGridMain flex-fill w-100">
      <DataGrid
        rows={data}
        columns={columns}
        getRowId={getRowId}
        initialState={{
          sorting: {
            sortModel: sortModel,
          },
          pagination: {
            paginationModel: {
              page: currentPage,
              pageSize: pageSize,
            }
          },
        }}
        onPageSizeChange={handlePageSizeChange}
        pageSizeOptions={[10, 25, 50, 100]}
        rowsPerPage ={pageSize}
        disableSelectionOnClick
        // onPageChange={onPaginationModelChange}
        onPaginationModelChange={onPaginationModelChange}
        onSortModelChange={handleSortModelChange}
        disableColumnMenu={true}
        slots={{
          noRowsOverlay: NoRowsFoundMessage,
        }}
        localeText={{
          noRowsLabel: '', // Set an empty string to hide the default message
        }}
        paginationMode="server"
        sortingMode='client'  // client/server
        pagination={pagination}
        pageSize={pageSize}
        rowCount={totalResults ? parseInt(totalResults) : 0}
        experimentalFeatures={{ columnGrouping: true }}
        columnGroupingModel={columnGroupingModel}
        sortModel={sortModel}
      />
    
    </div>
  )
}
