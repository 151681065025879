import React, { useState } from 'react'
import { Button, Card, Col, Row, Stack } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { IoChevronBack } from "react-icons/io5";
import { IoIosBusiness } from "react-icons/io";
import DefaultImg from "../../assets/images/default-image.jpg";
import { FaFacebookSquare, FaLinkedin, FaInstagram } from "react-icons/fa";
import { BsGlobe } from "react-icons/bs";
import { Popconfirm } from 'antd';
import { toast } from 'react-toastify';
import { FaCircleCheck } from "react-icons/fa6";
import DisapproveModal from './DisapproveModal';
import { MdBlock } from "react-icons/md";
import { AgencyAccountApproveDisapprove, AgencyBlockUnblockApi, LoginAsAgencyAdminApi } from '../../services/agencyService';
import LoaderMu from '../common/LoaderMu';
import CommercialRegFileModal from './CommercialRegFileModal';

function AgencyProfileInfo({ agencyData }) {

	const [modalOpen, setIsModalOpen] = useState(false) // DISAPPROVE MODAL STATE
	const [CRfileOpen, setCRfileOpen] = useState(false) // COMMERCIAL REGISTRATION FILE MODAL STATE
	const [loading, setLoading] = useState(false)

	const navigate = useNavigate()

	// IF USER APPROVE THE AGENCY
	const handleApprove = () => {
		setLoading(true)
		const data = {
			agencyId: agencyData?.id ? parseInt(agencyData?.id) : null,
			status: "ACTIVE",
			reason: "Approved"
		}
		AgencyAccountApproveDisapprove(data).then((response) => {
			setLoading(false)
			navigate(-1)
			toast.success(response?.message ?? "Agency account approved successfully.")
		}).catch((error) => {
			setLoading(false)
			 if(error?.status == 500){
                toast.error('Unauthorized or server issue. Please refresh or try later. ')
            }else{
                 toast.error(
          error?.error_description
            ? error?.error_description
            : error?.details ? error?.details : error?.message
        );
            }
		})
	}
	// IF USER BLOCK OR UNBLOCK THE AGENCY
	const handleBlockUnblock = (statusCode , status) => {
		setLoading(true)
		const data = {
			agencyId: agencyData?.id ? parseInt(agencyData?.id) : null,
			status: statusCode
		}
		AgencyBlockUnblockApi(data?.agencyId, data?.status).then((response) => {
			setLoading(false)
			navigate(-1)
			toast.success(response?.message ?? "Agency account approved successfully.")
		}).catch((error) => {
			setLoading(false)
			 if(error?.status == 500){
                toast.error('Unauthorized or server issue. Please refresh or try later. ')
            }else{
                 toast.error(
          error?.error_description
            ? error?.error_description
            : error?.details ? error?.details : error?.message
        );
            }
		})
	}
	// HANDLE AGENCY LOGIN AS ADMIN
	const handleAgencyLogin = () => {
		LoginAsAgencyAdminApi(agencyData?.id).then((response) => {
			window.open(`${process.env.REACT_APP_AGENCY_WEB_URL}/?t=${response?.id_token}`, '_blank');
		}).catch((error) => {
			if(error?.status == 500){
                toast.error('Unauthorized or server issue. Please refresh or try later. ')
            }else{
                 toast.error(
          error?.error_description
            ? error?.error_description
            : error?.details ? error?.details : error?.message
        );
            }
		})
	}
	return (
		<div>
			<LoaderMu loading={loading} />
			<Card className="bg-white shadow border-0 theme-card-cover">
				<div className="theme-card-header px-1 border-bottom border-light">
					<Stack
						direction="horizontal"
						gap={2}
						className="flex-wrap justify-content-between px-3 pt-3 pb-2 align-items-start"
					>
						<h5 className="mb-0 position-relative fs-18 text-black fw-medium">
							<div className="align-items-center bg-primary d-inline-flex h-60 justify-content-center  position-absolute rounded start-0 text-white theme-icon-box w-60 z-1">
								<IoIosBusiness size={40} />
							</div>
							Agency Details
						</h5>
						<div>
							{/* IF AGENCY IN ACTIVE THEN ONLY SHOW BLOCK BUTTON */}
							{
								agencyData?.status && agencyData?.status === "ACTIVE" ?
									<>
										<Popconfirm
											title="Block Agency"
											description="Are you sure to block this agency?"
											onConfirm={() => handleBlockUnblock(3,"SUSPENDED")} // IF USER WANTS TO BLOCK
											// onCancel={}
											icon={<MdBlock size={30} className='me-2 text-danger' />}
											okText="Yes"
											cancelText="No"
										>
											<button className="btn btn-primary fw-500 me-2 my-1">
												Block Agency
											</button>
										</Popconfirm>
										<Button className='btn btn-light text-white fw-bold me-2' onClick={handleAgencyLogin}>Login as Agency</Button>
									</>
									: ""
							}

							{/* IF AGENCY IS SUSPENDED OR BLOCKED THEN ONLY SHOW BLOCKED BUTTON */}
							{
								agencyData?.status && agencyData?.status === "SUSPENDED" ?
									<Popconfirm
										title="Unblock Agency"
										description="Are you sure to unblock this agency?"
										onConfirm={() => handleBlockUnblock(1,"ACTIVE")} // IF USER WANTS TO ACTIVE /UBLOCK AGENCY
										// onCancel={}
										icon={<MdBlock size={30} className='me-2 text-danger' />}
										okText="Yes"
										cancelText="No"
									>
										<button className="btn btn-primary fw-500 me-2 my-1">
											Unblock Agency
										</button>
									</Popconfirm>
									: ""}

							<Button variant="secondary"
								onClick={() => { navigate(-1) }}
								className="rounded-xs fs-15 text-white fw-bold"> <IoChevronBack size={20} className='mb-1' /> Back</Button>
							{/* <Link className='btn btn-secondary text-white fw-bold my-1' to="/app/agency"><IoChevronBack size={20} className='mb-1' />Back</Link> */}
						</div>
					</Stack>
				</div>
				<div className="flex-grow-1 d-flex flex-column px-3 py-3 h-100 overflow-auto">
					<Row>
						<Col sm={5} lg={2}>
							{
								agencyData?.logo && agencyData?.logo !== null ?
									<img src={agencyData?.logo} className='img-fluid' /> : <img src={DefaultImg} className='img-fluid' />
							}
							<Row className='mt-4 justify-content-around'>
								{
									agencyData?.facebookUrl && agencyData?.facebookUrl !== null ?
										<Col sm={2}>
											<a href={agencyData?.facebookUrl} target="_blank" className='text-dark'><FaFacebookSquare size={20} /></a>
										</Col> : ""
								}
								{
									agencyData?.instagramUrl && agencyData?.instagramUrl !== null ? <Col sm={2}>
										<a href={agencyData?.instagramUrl} target="_blank" className='text-dark'><FaInstagram size={20} /></a>
									</Col> : ""
								}
								{
									agencyData?.linkedinUrl && agencyData?.linkedinUrl !== null ?
										<Col sm={2}>
											<a href={agencyData?.linkedinUrl} target="_blank" className='text-dark'><FaLinkedin size={20} /></a>
										</Col> : ""
								}
								{
									agencyData?.websiteUrl && agencyData?.websiteUrl !== null ?
										<Col sm={2}>
											<Link to={agencyData?.websiteUrl} className='text-dark'><BsGlobe size={20} /></Link>
										</Col> : ""
								}
							</Row>
						</Col>
						<Col sm={12} lg={10}>
							<Row>
								<Col sm={5} lg={4}>
									<p className='fs-14 mb-3'>Agency Name</p>
									<p className="fw-500 fs-16">{agencyData?.name ?? " "}</p>
								</Col>
								<Col sm={5} lg={4}>
									<p className='fs-14 mb-3'>Contact Name</p>
									<p className="fw-500 fs-16">{agencyData?.contactName ?? " "}</p>
								</Col>
								<Col sm={5} lg={4}>
									<p className='fs-14 mb-3'>Email</p>
									<p className="fw-500 fs-16">{agencyData?.email ?? " "}</p>
								</Col>
							</Row>
							<Row className='pt-2'>
								<Col sm={5} lg={4}>
									<p className='fs-14 mb-3'>Address</p>
									<p className="fw-500 fs-16">{agencyData?.address ?? " "}</p>
								</Col>
								<Col sm={5} lg={4}>
									<p className='fs-14 mb-3'>National Id</p>
									<p className="fw-500 fs-16">{agencyData?.companyNationalId ?? " "}</p>
								</Col>
								<Col sm={5} lg={4}>
									<p className='fs-14 mb-3'>Mobile No</p>
									<p className="fw-500 fs-16">{agencyData?.mobileNumber ?? <span className='text-danger'>NA</span>}</p>
								</Col>
							</Row>
							<Row >
								<Col sm={5} lg={4} className='pt-2'>
									<p className='fs-14 mb-3'>Admin Name</p>
									<p className={`fw-500 fs-16 `}>{agencyData?.agencyAdmin?.firstName ?? ""} {agencyData?.agencyAdmin?.lastName ?? ""}</p>
								</Col>
								<Col sm={5} lg={4} className='pt-2'>
									<p className='fs-14 mb-3'>Admin Email</p>
									<p className={`fw-500 fs-16 `}>{agencyData?.agencyAdmin?.email ?? ""} </p>
								</Col>
								<Col sm={5} lg={4} className='pt-2'>
									<p className='fs-14 mb-3'>Status</p>
									<p className={`fw-bold fs-16 ${agencyData?.status === 'ACTIVE' ? 'text-dark' : 'text-danger'}`}>{agencyData?.status ?? ""}</p>
								</Col>
								{
									agencyData?.about?.en && agencyData?.about?.en !== null ?
										<Col sm={5} lg={4} className='pt-2'>
											<p className='fs-14 mb-3'>About (English)</p>
											<p className='fs-16 text-justify'>{agencyData?.about?.en ?? ""}</p>
										</Col> : ""
								}
								{
									agencyData?.about?.ar && agencyData?.about?.ar !== null ?
										<Col sm={5} lg={4} className='pt-2'>
											<p className='fs-14 mb-3'>About (Arabic)</p>
											<p className='fs-16 text-justify'>{agencyData?.about?.ar ?? ""}</p>
										</Col> : ""
								}
							</Row>
						
							<Row>
								{/* OPEN COMMERCIAL REGISTRATION FILE PREVIEW */}
								<Col sm={5} lg={4}>
									<Button className='btn btn-primary mb-2' onClick={() => { setCRfileOpen(true) }}>Commercial Registration</Button>
								</Col>
							</Row>
						</Col>
					</Row>
					{/* IF AGENCY IS NOT ACTIVE THEN ONLY SHOW THIS BUTTONS */}
					{/* APPROVE OR DISAPPROVE   */}

					{agencyData?.status ? agencyData?.status === "INACTIVE" ? <Row className="pt-3 border-top border-light">
						<Col lg={"auto"}>
							{/* APPROVE CONFIRMATION */}
							<Popconfirm
								title="Approve Agency"
								description="Are you sure to approve this agency?"
								onConfirm={handleApprove} // IF USER WANTS TO APPROVE
								// onCancel={}
								icon={<FaCircleCheck size={30} className='me-2 text-success' />}
								okText="Yes"
								cancelText="No"
							>
								<button className="btn btn-primary text-white fw-500">
									Approve
								</button>
							</Popconfirm>
							{/* OPEN DISAPPROVE MODAL */}
							<button className="btn btn-secondary text-white fw-500 ms-2" onClick={() => { setIsModalOpen(true) }}>
								Reject
							</button>
						</Col>
					</Row> : "" : ""}
					{/* MODAL WILL OPEN On REJECT */}
					<DisapproveModal agencyId={agencyData?.id} isModalOpen={modalOpen} setModalOpen={setIsModalOpen} />
					<CommercialRegFileModal filePath={agencyData?.commercialRegistration} isFileOpen={CRfileOpen} setIsFileOpen={setCRfileOpen} />
				</div>
			</Card>
		</div>
	)
}

export default AgencyProfileInfo
