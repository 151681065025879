import React, { useEffect, useState } from 'react'
import { Button, Card, Stack } from 'react-bootstrap'
import { PiFilesLight } from 'react-icons/pi'
import { agentReportApi } from '../../services/reportService'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import NotAuthorized from '../../components/common/NotAuthorized'

const AgentReport = () => {
	// MANAGING USER PERMISSIONS HERE

	const roleDetails = useSelector((state) => state?.rolesData?.masterPermissionResponseDTOList)

	const [isAuthorized, setIsAuthorized] = useState(false)

	useEffect(() => {
		if (roleDetails) {
			const permissions = roleDetails.find((detail) => {
				return detail.childPermissions.find((childPerm) => (
					childPerm?.key === "reports_agent" && childPerm?.status === true
				))
			})
			if (permissions) {
				setIsAuthorized(true)
			} else {
				setIsAuthorized(false)
			}
			setLoading(false)
		} else {
			setIsAuthorized(true)
			setLoading(false)
		}

	}, [roleDetails])

	const [loading, setLoading] = useState(true)

	// HANDLE EXCEL REPORT DOWNLOAD
	const handleReportDownload = () => {
		setLoading(true)
		// CALL API HERE TO GET FILE DATA
		agentReportApi().then((response) => {
			if (response?.data) {
				const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
				const blobUrl = window.URL.createObjectURL(blob);

				const tempLink = document.createElement('a');
				tempLink.href = blobUrl;
				tempLink.setAttribute('download', 'Agent.xlsx');

				// Append the link to the document body before clicking it
				document.body.appendChild(tempLink);

				tempLink.click();

				// Clean up by revoking the Blob URL
				window.URL.revokeObjectURL(blobUrl);

				// Remove the link from the document body after clicking
				document.body.removeChild(tempLink);
			} else {
				throw new Error('Response data is empty.');
			}
		}).catch((error) => {
			 if(error?.status == 500){
                toast.error('Unauthorized or server issue. Please refresh or try later. ')
            }else{
                 toast.error(
          error?.error_description
            ? error?.error_description
            : error?.details ? error?.details : error?.message
        );
            }
		}).finally(() => {
			setLoading(false)
		})
	}
	return (
		<div className="d-flex flex-column pageContainer p-3 h-100">
			{
				!loading && !isAuthorized ? <NotAuthorized /> :
					<div className="flex-grow-1 h-100 pageContent position-relative pt-4 overflow-auto">
						<Card className=" bg-white shadow border-0 theme-card-cover">
							<div className="theme-card-header px-1">
								<Stack
									direction="horizontal"
									gap={2}
									className="flex-wrap justify-content-between px-3 pt-3 pb-2 align-items-start"
								>
									<h5 className="mb-0 position-relative fs-18 text-black fw-medium">
										<div className="align-items-center bg-primary d-inline-flex h-60 justify-content-center  position-absolute rounded start-0 text-white theme-icon-box w-60 z-1">
											<PiFilesLight size={40} />
										</div>
										Agent Report
									</h5>
								</Stack>
							</div>
							<div className='py-5 flex-grow-1 d-flex flex-column px-3 h-100 overflow-auto'>
								<div className="row">
									<div className="col-auto">
										<Button
											className="rounded-xs fs-15"
											type="submit"
											variant="primary" onClick={handleReportDownload}
											disabled={loading ?? false}>
											Download Report
										</Button>
									</div>
								</div>
							</div>
						</Card>
					</div>}
		</div>
	)
}

export default AgentReport