import React, { useEffect, useState } from 'react'
import AgencyProfileInfo from '../../../components/agencyDetails/AgencyProfileInfo';
import { fetchAgencyData } from '../../../services/agencyService';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import AgentListing from '../../../components/agencyDetails/AgentListing';
import LoaderMu from '../../../components/common/LoaderMu';
import { useSelector } from 'react-redux';
import NotAuthorized from '../../../components/common/NotAuthorized';

export const AgencyDetails = () => {

  // MANAGING USER PERMISSIONS HERE

  const roleDetails = useSelector((state) => state?.rolesData?.masterPermissionResponseDTOList)

  const [isAuthorized, setIsAuthorized] = useState(false)

  useEffect(() => {
    if (roleDetails) {
      const permissions = roleDetails.find((detail) => {
        return detail.childPermissions.find((childPerm) => (
          childPerm?.key === "agency_detail" && childPerm?.status === true
        ))
      })
      if (permissions) {
        setIsAuthorized(true)
      } else {
        setIsAuthorized(false)
      }
    } else {
      setIsAuthorized(true)
    }

  }, [roleDetails])

  const { id } = useParams()
  const [agencyData, setAgencyData] = useState({})
  const [loading, setLoading] = useState(true)
  const getData = () => {
    // CALLING API FOR AGENCY DATA
    fetchAgencyData(id).then((response) => {
      setLoading(false)
      // SET AGENCY DATA IN STATE
      setAgencyData(response)
    }).catch((error) => {
      setLoading(false)
       if(error?.status == 500){
                toast.error('Unauthorized or server issue. Please refresh or try later. ')
            }else{
                 toast.error(
          error?.error_description
            ? error?.error_description
            : error?.details ? error?.details : error?.message
        );
            }
    })
  }
  // FETCH AGENCY DATA
  useEffect(() => {
    if (isAuthorized) {
      getData()
    } else {
      setLoading(false)
    }
  }, [id, isAuthorized])
  return (
    <div className="d-flex flex-column pageContainer p-3 h-100">
      {
        !isAuthorized && !loading ?
          <NotAuthorized />
          : <div className="flex-grow-1 pageContent position-relative pt-4 overflow-auto">
            {/* AGENCY PROFILE INFO CARD */}
            {
              loading ?
                <LoaderMu loading={loading} /> : <AgencyProfileInfo agencyData={agencyData} />
            }
            {
              agencyData && agencyData?.status !== "INACTIVE" ?
                <AgentListing /> : ""
            }
          </div>
      }
    </div>
  )
}