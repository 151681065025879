import { axios } from "./axios"
import{
    GET_DIRECTORATE_LIST_API,
    UPDATE_DIRECTORATE_API,

} from "../constants/paths" 
/******
 * Get Directorate list api call
 * @param  data
 * @returns {Promise<any>}
 */
export const getDirectorateListAPI = (params)=> {
    return axios.get(`${GET_DIRECTORATE_LIST_API}`,{params})  
}

// GET Directorate BY ID
export const getDirectorateById = async (id) => {
    const response = await axios.get(`${GET_DIRECTORATE_LIST_API}/${id}`);
    return response?.data
}

// update directorate
export const updateDirectorateAPI = data => {
    return axios.put(UPDATE_DIRECTORATE_API, data)  
}