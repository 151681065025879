import { AGENCY_REPORT_API, AGENCY_SEARCH_API, AGENT_REPORT_API, PLAN_LIST, PLAN_REPORT_API, PLATFORM_USER_REPORT, PROPERTY_REPORT_API, SYSTEM_USER_REPORT_API, VILLAGE_SEARCH_API } from "../constants/paths";
import { axios } from "./axios";

// AGENT REPORT API
export const agentReportApi = async () => {
    const response = await axios.get(AGENT_REPORT_API, { responseType: 'arraybuffer' })

    return response
}


// PLATFORM USER REPORT API
export const platformUserReportApi = async (queryParams) => {
    const response = await axios.get(`${PLATFORM_USER_REPORT}?${queryParams}`, { responseType: 'arraybuffer', })
    return response
}

// PLATFORM USER REPORT API
export const propertyReportApi = async (params) => {
    const response = await axios.get(PROPERTY_REPORT_API, { params, responseType: 'arraybuffer' })
    return response
}

// VILLAGE SEARCH API
export const villageSearchApi = async (params) => {
    const response = await axios.get(VILLAGE_SEARCH_API, { params })
    return response
}

// AGENCY SEARCH API
export const agencySearchApi = async (params) => {
    const response = await axios.get(AGENCY_SEARCH_API, { params })
    return response
}

// AGENCY SEARCH API
export const planReportApi = async () => {
    const response = await axios.get(PLAN_REPORT_API, { responseType: 'arraybuffer' })
    return response
}

// SYSTEM USER REPORT API
export const systemUserReportApi = async (params) => {
    const response = await axios.get(SYSTEM_USER_REPORT_API, { params, responseType: 'arraybuffer' })
    return response
}

// AGENCY REPORT API
export const agencyReportApi = async (params) => {
    const response = await axios.get(AGENCY_REPORT_API, { params, responseType: 'arraybuffer' })
    return response
}

// PLAN LIST API
export const planListApi = async()=>{
    const response = await axios.get(PLAN_LIST)
    return response?.data
}