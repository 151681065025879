import { Form, Formik, getIn } from 'formik'
import React, { useEffect, useState } from 'react'
import { Button, Card, Col, FormLabel, Row, Stack } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { FaQuestionCircle } from "react-icons/fa";
import InputText from '../../../components/common/InputText';
import { CustomSelect } from '../../../components/common/CustomSelect';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { AddFaqApi, FaqCategoryListApi } from '../../../services/faqService';
import { toast } from 'react-toastify';
import { addFaqValidationSchema } from '../../../validations/ValidationSchema';
import LoaderMu from '../../../components/common/LoaderMu';
function AddFaq() {

  const navigate = useNavigate()

  const [loading, setLoading] = useState(true)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const [faqCategories, setFaqCategories] = useState([])

  // ON FORM SUBMIT
  const handleSubmit = (values, actions) => {
    setIsSubmitting(true)
    const formData = {
      question: {
        en: values?.question_en,
        ar: values?.question_ar
      },
      description: {
        en: values?.description_en,
        ar: values?.description_ar
      },
      faqCategoryId: values?.category
    }
    //CALLING ADD FAQ API  
    AddFaqApi(formData).then(response => {
      navigate(-1)
      toast.success(response?.message ?? 'Faq created successfully.')
    }).catch((error) => {
       if(error?.status == 500){
                toast.error('Unauthorized or server issue. Please refresh or try later. ')
            }else{
                 toast.error(
          error?.error_description
            ? error?.error_description
            : error?.details ? error?.details : error?.message
        );
            }
    }).finally(() => {
      setIsSubmitting(false)
    })
  }

  // REACT QUILL TEXT EDITOR MODULES
  const modules = {
    toolbar: [
      //   [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
      //   [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' },
      { 'indent': '-1' }, { 'indent': '+1' }],
      //   ['link', 'image',],
      ['link'],
      [{ 'script': 'sub' }, { 'script': 'super' }], // Add super and sub options here
      ['clean']
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };
  const formats = [
    // 'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike',
    'list', 'bullet', 'indent',
    // 'link', 'image', 'video',
    'link',
    'script', // Include the script format
  ];

  // FETCH FAQ CATEGORY
  const getFaqCategory = () => {
    setLoading(true)
    const params = {}
    // CALLING API FOR FETCH CATEGORY DATA
    FaqCategoryListApi(params).then(response => {
      let categories = []
      if (response?.length > 0) {
        response?.forEach((category) => {
          categories.push({ value: category?.id, label: category?.name?.en })
        })
        setFaqCategories(categories)
      }
      // setFaqCategories(response)
    }).catch((error) => {
       if(error?.status == 500){
                toast.error('Unauthorized or server issue. Please refresh or try later. ')
            }else{
                 toast.error(
          error?.error_description
            ? error?.error_description
            : error?.details ? error?.details : error?.message
        );
            }
    }).finally(() => {
      setLoading(false)
    })
  }

  // GET FAQ CATEGORIES ON PAGE MOUNT
  useEffect(() => {
    getFaqCategory()
  }, [])

  return (
    <div className="d-flex flex-column pageContainer p-3 h-100 overflow-auto">
      <LoaderMu loading={loading}/> 
      <div className="flex-grow-1 pageContent position-relative pt-4">
        {/* {JSON.stringify(data)} */}
        <Card className="h-100 bg-white shadow border-0 theme-card-cover">
          <div className="theme-card-header px-1">
            <Stack direction="horizontal" gap={2} className="flex-wrap justify-content-between px-3 pt-3 pb-2 align-items-start">
              <h5 className="mb-0 position-relative fw-medium">
                <div className="align-items-center bg-primary d-inline-flex h-60 justify-content-center  position-absolute rounded start-0 text-white theme-icon-box w-60 z-1">
                  <span className="theme-icon-box-inner"><FaQuestionCircle size={36} /></span>
                </div>
                Add Faq
              </h5>
            </Stack>
          </div>
          <div className="flex-grow-1 d-flex flex-column px-1">
            <div className="p-3 d-flex flex-column h-100">
              <Formik
                validationSchema={addFaqValidationSchema}
                initialValues={
                  {
                    question_en: "",
                    question_ar: "",
                    description_en: "",
                    description_ar: "",
                    category: ""
                  }
                }
                onSubmit={(values, actions) => {
                  actions.setSubmitting(false)
                  handleSubmit(values, actions)
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  setFieldValue,
                  touched,
                  isValid,
                  errors
                }) => (
                  <Form className="d-flex flex-column flex-grow-1 theme-from">
                    <Row className="gx-xl-5">
                      <Col sm={12} md={6} lg={6} className="mb-4 pb-1">
                        <InputText
                          controlId="question_en"
                          label="Question (English)"
                          value={values?.question_en}
                          name="question_en"
                          maxLength="70"
                          errorsField={errors?.question_en}
                          touched={touched?.question_en}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          className={"w-100 form-control"}
                          variant="outlined"
                        />
                      </Col>
                      <Col sm={12} md={6} lg={6} className="mb-4 pb-1">
                         <div>
                          <InputText
                            controlId="question_ar"
                            label="Question (Arabic)"
                            dir="rtl"
                            value={values?.question_ar}
                            name="question_ar"
                            maxLength="70"
                            errorsField={errors?.question_ar}
                            touched={touched?.question_ar}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            className={"w-100 form-control"}
                            variant="outlined"
                          />
                        </div>
                      </Col>
                      <Col sm={12} md={6} lg={6} className="mb-4 pb-1">
                        <CustomSelect
                          options={faqCategories ?? []}
                          controlId="category"
                          label="Category"
                          value={values?.category}
                          name="category"
                          errorsField={errors?.category}
                          touched={touched?.category}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          onChange={handleChange}
                          variant="outlined"
                        />
                      </Col>
                      <Col sm={12} md={12} lg={12} className="mb-4 pb-1">
                        <FormLabel className="mb-1 fw-normal labelColor fs-13">Description (English)</FormLabel>
                        <ReactQuill
                          modules={modules}
                          formats={formats}
                          name="description_en"
                          theme="snow"
                          value={values?.description_en}
                          onChange={(value) => setFieldValue('description_en', value)}
                        />
                        <span className='text-danger answer-type-error fs-11'>{getIn(errors, `description_en`)}</span>
                      </Col>
                      <Col sm={12} md={12} lg={12} className="mb-4 pb-1" dir="rtl">
                        <FormLabel className="mb-1 fw-normal labelColor fs-13">Description (Arabic)</FormLabel>
                        <ReactQuill
                          modules={modules}
                          formats={formats}
                          name="description_ar"
                          theme="snow"
                          className='rtl-textEditor'
                          value={values?.description_ar}
                          onChange={(value) => setFieldValue('description_ar', value)}
                        />
                        <span className='text-danger answer-type-error fs-11'>{getIn(errors, `description_ar`)}</span>
                      </Col>
                    </Row>
                    <Stack direction="horizontal" gap={3} className="my-3">
                      <Button
                        variant="primary"
                        onClick={handleSubmit}
                        className="rounded-xs fs-15"
                        type="submit"
                        disabled ={isSubmitting ?? false}
                      >Save</Button>
                      <Button variant="outline-secondary"
                        onClick={() => { navigate(-1) }}
                        className="rounded-xs fs-15">Cancel</Button>
                    </Stack>
                  </Form>
                )}
              </Formik> 
            </div>
          </div>
        </Card>
      </div>

    </div>
  )
}

export default AddFaq
