import React, { useEffect, useState } from 'react'
import { Form, Formik } from 'formik'
import { Button, Card, Col, FormLabel, Row, Stack } from 'react-bootstrap'
import { Link, useNavigate, useParams } from 'react-router-dom'
import InputText from '../../../components/common/InputText'
import { toast } from 'react-toastify'
import { propertyType } from '../../../validations/ValidationSchema'
import { Switch } from 'antd';
import { CustomSelect } from '../../../components/common/CustomSelect';
import { LuBuilding2 } from "react-icons/lu";
import { editPropertyTypeApi, fetchPropertyTypeInfo, propertyDetailsApi } from '../../../services/propertyType';
import LoaderMu from '../../../components/common/LoaderMu';
import { useSelector } from 'react-redux'
import NotAuthorized from '../../../components/common/NotAuthorized'

export const Edit = () => {
  // MANAGING PERMISSIONS HERE

  const roleDetails = useSelector((state) => state?.rolesData?.masterPermissionResponseDTOList)

  const [isAuthorized, setIsAuthorized] = useState(false)

  useEffect(() => {

    if (roleDetails) {
      const permissions = roleDetails.find((detail) => {
        return detail.childPermissions.find((childPerm) => (
          childPerm?.key === "property_type_edit" && childPerm?.status === true
        ))
      })
      if (permissions) {
        setIsAuthorized(true)
      } else {
        setIsAuthorized(false)
      }
    } else {
      setIsAuthorized(true)
    }

  }, [roleDetails])

  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [iconClassList, setIconClassList] = useState([])

  const { id } = useParams()

  const [initialValues, setInitialValues] = useState({
    title_en: "",
    title_ar: "",
    category: "",
    status: false,
    iconClassName: ""
  })

  // FORM SUBMIT FOR ADD PROPERTY TYPE
  const handleSubmit = (values) => {
    setIsSubmitting(true)
    const formData = {
      id: parseInt(id),
      title: { en: values?.title_en, ar: values?.title_ar },
      status: values?.status ?? false,
      propertyCategory: values?.category,
      iconClassName: values?.iconClassName ?? ''
    }
    // CALLING API FOR ADD PROPERTY TYPE
    editPropertyTypeApi(formData).then((response) => {
      setIsSubmitting(false)
      // navigate(nameBasedRoutes?.List?.path)
      navigate(-1)
      toast.success(response?.message ?? 'PropertyType updated successfully')
    }).catch((error) => {
      setIsSubmitting(false)
       if(error?.status == 500){
                toast.error('Unauthorized or server issue. Please refresh or try later. ')
            }else{
                 toast.error(
          error?.error_description
            ? error?.error_description
            : error?.details ? error?.details : error?.message
        );
            }
    })
  }

  // FUNCTION FOR FETCH PROPERTY LIST 
  const fetchPropertyDetails = () => {
    setLoading(true)
    // CALLING API SERVICE FOR ROLE DATA
    propertyDetailsApi(id)
      .then(async (response) => {
        setInitialValues({
          title_en: response?.title?.en,
          title_ar: response?.title?.ar,
          category: response?.propertyCategory,
          status: response?.status,
          iconClassName: response?.iconClassName ?? ''
        })
        // SET DATA IN STATE
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };
  // FUNCTION FOR FETCH PROPERTY LIST 
  const fetchPropertyTypeIcons = () => {
    setLoading(true)
    // CALLING API SERVICE FOR ROLE DATA
    fetchPropertyTypeInfo()
      .then((response) => {
        setIconClassList(response?.iconList)
        // SET DATA IN STATE
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  useEffect(() => {
    if (isAuthorized) {
      fetchPropertyDetails()
    }else{
      setLoading(false)
    }

  }, [id, isAuthorized])

  useEffect(() => {
    if(isAuthorized){
      fetchPropertyTypeIcons()
    }else{
      setLoading(false)
    }
  }, [isAuthorized])
  return (
    <div className="d-flex flex-column pageContainer p-3 h-100 overflow-auto">
      <LoaderMu loading={loading} />
      <div className="flex-grow-1 pageContent position-relative pt-4">
      {
          !isAuthorized && !loading ?
            <NotAuthorized /> :
        <Card className="h-100 bg-white shadow border-0 theme-card-cover">
          <div className="theme-card-header px-1">
            <Stack direction="horizontal" gap={2} className="flex-wrap justify-content-between px-3 pt-3 pb-2 align-items-start">
              <h5 className="mb-0 position-relative fw-medium">
                <div className="align-items-center bg-primary d-inline-flex h-60 justify-content-center  position-absolute rounded start-0 text-white theme-icon-box w-60 z-1">
                  <span className="theme-icon-box-inner"><LuBuilding2 size={36} /></span>
                </div>
                Edit Property type
              </h5>
            </Stack>
          </div>
          <div className="flex-grow-1 d-flex flex-column px-1">
            <div className="p-3 d-flex flex-column h-100">
              <Formik
                enableReinitialize={true}
                validationSchema={propertyType}
                initialValues={initialValues}
                onSubmit={(values, actions) => {
                  actions.setSubmitting(false)
                  handleSubmit(values)
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  setFieldValue,
                  touched,
                  isValid,
                  errors
                }) => (
                  <Form className="d-flex flex-column flex-grow-1 theme-from">
                    <Row className="gx-xl-5">
                      <Col sm={6} md={6} lg={6} className="mb-4 pb-1">
                        <InputText
                          type="title_en"
                          controlId="title_en"
                          label="Title (English)"
                          value={values?.title_en}
                          name="title_en"
                          maxLength="70"
                          errorsField={errors?.title_en}
                          touched={touched?.title_en}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          className={"w-100 form-control"}
                          variant="outlined"
                        />
                      </Col>
                      <Col sm={6} md={6} lg={6} className="mb-4 pb-1">
                         <div>
                          <InputText
                            dir="rtl"
                            type="title_ar"
                            controlId="title_ar"
                            label="Title (Arabic)"
                            value={values?.title_ar}
                            name="title_ar"
                            maxLength="70"
                            errorsField={errors?.title_ar}
                            touched={touched?.title_ar}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            className={"w-100 form-control"}
                            variant="outlined"
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row className="gx-xl-5">
                      <Col sm={12} md={6} lg={6} className="mb-4 pb-1">
                        <CustomSelect
                          options={[
                            { label: 'Commercial', value: 'COMMERCIAL' },
                            { label: 'Residential', value: 'RESIDENTIAL' },
                          ]}
                          controlId="category"
                          label="Category"
                          value={values?.category}
                          name="category"
                          variant="outlined"
                          errorsField={errors?.category}
                          touched={touched?.category}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </Col>
                      <Col sm={12} md={6} lg={6} className="mb-4 pb-1">
                        <CustomSelect
                          options={iconClassList ?? []}
                          controlId="iconClassName"
                          label="Icon"
                          value={values?.iconClassName}
                          name="iconClassName"
                          variant="outlined"
                          errorsField={errors?.iconClassName}
                          touched={touched?.iconClassName}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </Col>
                      <Col sm={6} md={6} lg={6} className="mb-4 pb-1">
                        <FormLabel className='fs-6 fw-500'>Status</FormLabel>
                        <div>
                          <Switch
                            id="status"
                            checked={values?.status}
                            checkedChildren={"TRUE"}
                            unCheckedChildren={"FALSE"}
                            className='fw-bold'
                            onChange={(value) => { setFieldValue("status", value) }}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Stack direction="horizontal" gap={3} className="my-3">
                      <Button
                        variant="primary"
                        onClick={handleSubmit}
                        className="rounded-xs fs-15"
                        type="submit"
                        disabled={isSubmitting ?? false}
                      >Save</Button>
                      {/* <Link to="/app/property-type" className="btn btn-outline-secondary rounded-xs fs-15">Cancel</Link> */}
                      <Button variant="outline-secondary"
                        onClick={() => { navigate(-1) }}
                        className="rounded-xs fs-15">Cancel</Button>
                    </Stack>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </Card>}
      </div>
    </div>
  )
}

