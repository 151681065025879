import React, { useEffect, useState } from 'react'
import { Form, Formik } from 'formik'
import { Button, Card, Col, FormLabel, Row, Stack } from 'react-bootstrap'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { FaUserEdit } from "react-icons/fa";
import InputText from '../../../components/common/InputText'
import { agencyDropdownListApi, customPlanEditApi, customPlanDetailsApi } from '../../../services/packageServices'
import { toast } from 'react-toastify'
import { editCustomPackageSchema } from '../../../validations/ValidationSchema'
import { Switch } from 'antd';
import LoaderMu from '../../../components/common/LoaderMu';
import MultiSelect from '../../../components/common/MultiSelect';
import { CustomSelect } from '../../../components/common/CustomSelect';
import { useSelector } from 'react-redux';
import NotAuthorized from '../../../components/common/NotAuthorized';

function Edit() {

	// MANAGING PERMISSIONS HERE

	const roleDetails = useSelector((state) => state?.rolesData?.masterPermissionResponseDTOList)

	const [isAuthorized, setIsAuthorized] = useState(false)

	useEffect(() => {
		if (roleDetails) {
			const permissions = roleDetails.find((detail) => {
				return detail.childPermissions.find((childPerm) => (
					childPerm?.key === "subscription_edit" && childPerm?.status === true
				))
			})
			if (permissions) {
				setIsAuthorized(true)
			} else {
				setIsAuthorized(false)
			}
		} else {
			setIsAuthorized(true)
		}
	}, [roleDetails])


	const navigate = useNavigate()
	const [loading, setLoading] = useState(true)
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [agencyList, setAgencyList] = useState([])

	const { id } = useParams()

	const [initialValues, setInitialValues] = useState({
		agencyIds: [],
		planName: "",

		// MONTHLY PLAN
		monthly_price: "",
		monthly_noOfActiveAgent: "",
		monthly_noOfActiveProperty: "",
		monthly_noOfActiveFeaturedProperty: "",
		monthly_noOf3dViewUpload: "",
		// monthly_dashboard: "NONE",
		// monthly_featuredAgency: false,
		monthly_noOfActiveFeaturedProperty: "",
		monthly_maxFeaturedProperty: "",
		monthly_costPerFeaturedProperty: "",
		monthly_noOf3dViewUpload: "",
		monthly_max3DViewUpload: "",
		monthly_costPer3dView: "",
		// YEARLY PLAN
		yearly_price: "",
		yearly_noOfActiveAgent: "",
		yearly_noOfActiveProperty: "",

		yearly_noOfActiveFeaturedProperty: "",
		yearly_maxFeaturedProperty: "",
		yearly_costPerFeaturedProperty: "",

		yearly_noOf3dViewUpload: "",
		yearly_max3DViewUpload: "",
		yearly_costPer3dView: "",
		// yearly_dashboard: "NONE",
		// yearly_featuredAgency: false,
	})
	// FORM SUBMIT FOR EDIT CUSTOM PLAN
	const handleSubmit = (values) => {

		// setIsSubmitting(true)
		const subscriptionLimitManageList = [
			{
				id: values?.monthly_id,
				price: parseFloat(values.monthly_price, 10),
				validityType: 'MONTHLY',
				noOfActiveAgent: parseInt(values.monthly_noOfActiveAgent, 10),
				noOfActiveProperty: parseInt(values.monthly_noOfActiveProperty, 10),
				noOfActiveFeaturedProperty: parseInt(values.monthly_noOfActiveFeaturedProperty, 10),
				noOf3dViewUpload: values?.monthly_noOf3dViewUpload ? parseInt(values.monthly_noOf3dViewUpload, 10) : 0,
				dashboard: "NONE",
				// featuredAgency: values.monthly_featuredAgency,
				maxNoOfActiveFeaturedProperty: parseInt(values?.monthly_maxFeaturedProperty, 10),
				maxNoOf3dViewUpload: parseInt(values?.monthly_max3DViewUpload, 10),
				additionalCostPer3dViewUpload: values?.monthly_costPer3dView !== null && values?.monthly_costPer3dView !== undefined
					? parseFloat(values?.monthly_costPer3dView).toFixed(2)
					: null,
				additionalCostPerFeaturedProperty: values?.monthly_costPerFeaturedProperty !== null && values?.monthly_costPerFeaturedProperty !== undefined
					? parseFloat(values?.monthly_costPerFeaturedProperty).toFixed(2)
					: null,
			},
			{
				id: values?.yearly_id,
				price: parseFloat(values?.yearly_price, 10).toFixed(),
				validityType: 'YEARLY',
				noOfActiveAgent: parseInt(values?.yearly_noOfActiveAgent, 10),
				noOfActiveProperty: parseInt(values?.yearly_noOfActiveProperty, 10),
				noOfActiveFeaturedProperty: parseInt(values?.yearly_noOfActiveFeaturedProperty, 10),
				noOf3dViewUpload: values?.yearly_noOf3dViewUpload ? parseInt(values?.yearly_noOf3dViewUpload, 10) : 0,
				dashboard: "NONE",
				// featuredAgency: values.yearly_featuredAgency,
				maxNoOfActiveFeaturedProperty: parseInt(values?.yearly_maxFeaturedProperty, 10),
				maxNoOf3dViewUpload: parseInt(values?.yearly_max3DViewUpload, 10),
				additionalCostPer3dViewUpload: values?.yearly_costPer3dView !== null && values?.yearly_costPer3dView !== undefined
					? parseFloat(values?.yearly_costPer3dView).toFixed(2)
					: null,
				additionalCostPerFeaturedProperty: values?.yearly_costPerFeaturedProperty !== null && values?.yearly_costPerFeaturedProperty !== undefined
					? parseFloat(values?.yearly_costPerFeaturedProperty).toFixed(2)
					: null,
			}
		];

		const agency_ids = values?.agencyIds?.map((val) => {
			return val.value
		})

		const formData = {
			id: parseInt(id),
			agencyIds: agency_ids,
			planName: values.planName,
			subscriptionLimitManageList: subscriptionLimitManageList
		}
		// CALLING API FOR CREATE CUSTOM PLAN
		customPlanEditApi(formData).then((response) => {
			navigate(-1)
			toast.success(response?.message)
		}).catch((error) => {
			if (error?.status == 500) {
				toast.error('Unauthorized or server issue. Please refresh or try later. ')
			} else {
				toast.error(
					error?.error_description
						? error?.error_description
						: error?.details ? error?.details : error?.message
				);
			}
		}).finally(() => {
			setIsSubmitting(false)
		})
	}
	// HANDLE USER STATUS TOGGLE
	const handleUserStatus = (value, setFieldValue) => {
		let dashboardStatus = value === true ? 'FULL' : 'PARTIAL'
		setFieldValue('dashboard', dashboardStatus)
	}

	// FETCH DATA
	const getAgencyListApi = () => {
		setLoading(true)
		// GETTING DATA FROM API
		agencyDropdownListApi().then(response => {
			var agencies = response?.map((element) => {
				return { label: element?.name, value: element?.id }
			})
			setAgencyList(agencies)
			setLoading(false)
		}).catch((error) => {
			if (error?.status == 500) {
				toast.error('Unauthorized or server issue. Please refresh or try later. ')
			} else {
				toast.error(
					error?.error_description
						? error?.error_description
						: error?.details ? error?.details : error?.message
				);
			}
		}).finally(() => {
			setLoading(false)
		})
	}
	// FETCH DATA
	const fetchCustomPlanDetails = () => {
		setLoading(true)
		// GETTING DATA FROM API
		customPlanDetailsApi(id).then(response => {
			const agencyIds = response?.agencies?.map((agency) => {
				return { label: agency?.name, value: agency?.id }
			}) ?? []

			const monthly = response?.subscriptionLimitManageList.filter((element) => element?.validityType === "MONTHLY")
			const yearly = response?.subscriptionLimitManageList.filter((element) => element?.validityType === "YEARLY")


			setInitialValues({
				planName: response?.planName,
				agencyIds: agencyIds,

				// MONTHLY PLAN
				monthly_id: monthly[0]?.id ?? "",
				monthly_price: monthly[0]?.price ?? "",
				monthly_noOfActiveAgent: monthly[0]?.noOfActiveAgent ?? "",
				monthly_noOfActiveProperty: monthly[0]?.noOfActiveProperty ?? "",

				monthly_noOfActiveFeaturedProperty: monthly[0]?.noOfActiveFeaturedProperty ?? "",
				monthly_maxFeaturedProperty: monthly[0]?.maxNoOfActiveFeaturedProperty ?? "",
				monthly_costPerFeaturedProperty: monthly[0]?.additionalCostPerFeaturedProperty ?? "",

				monthly_noOf3dViewUpload: monthly[0]?.noOf3dViewUpload ?? "",
				monthly_max3DViewUpload: monthly[0]?.maxNoOf3dViewUpload ?? "",
				monthly_costPer3dView: monthly[0]?.additionalCostPer3dViewUpload ?? "",

				// monthly_featuredAgency: monthly[0]?.featuredAgency,
				// YEARLY PLAN
				yearly_id: yearly[0]?.id ?? "",
				yearly_price: yearly[0]?.price ?? "",
				yearly_noOfActiveAgent: yearly[0]?.noOfActiveAgent ?? "",
				yearly_noOfActiveProperty: yearly[0]?.noOfActiveProperty ?? "",

				yearly_noOfActiveFeaturedProperty: yearly[0]?.noOfActiveFeaturedProperty ?? "",
				yearly_maxFeaturedProperty: yearly[0]?.maxNoOfActiveFeaturedProperty ?? "",
				yearly_costPerFeaturedProperty: yearly[0]?.additionalCostPerFeaturedProperty ?? "",

				yearly_noOf3dViewUpload: yearly[0]?.noOf3dViewUpload ?? "",
				yearly_max3DViewUpload: yearly[0]?.maxNoOf3dViewUpload ?? "",
				yearly_costPer3dView: yearly[0]?.additionalCostPer3dViewUpload ?? "",

				// yearly_featuredAgency: yearly[0]?.featuredAgency,
			})
			setLoading(false)
		}).catch((error) => {
			if (error?.status == 500) {
				toast.error('Unauthorized or server issue. Please refresh or try later. ')
			} else {
				toast.error(
					error?.error_description
						? error?.error_description
						: error?.details ? error?.details : error?.message
				);
			}
		}).finally(() => {
			setLoading(false)
		})
	}
	// IF USER IS AUTHORIZED THEN ONLY FETCH CUSTOM PLAN DETAILS
	useEffect(() => {
		if (isAuthorized) {
			getAgencyListApi()
			fetchCustomPlanDetails()
		} else {
			setLoading(false)
		}
	}, [isAuthorized])
	return (
		<div className="d-flex flex-column pageContainer p-3 h-100 overflow-auto">
			<LoaderMu loading={loading} />
			<div className="flex-grow-1 pageContent position-relative pt-4">
				{
					!isAuthorized && !loading ?
						<NotAuthorized /> :
						<Card className="h-100 bg-white shadow border-0 theme-card-cover">
							<div className="theme-card-header px-1">
								<Stack direction="horizontal" gap={2} className="flex-wrap justify-content-between px-3 pt-3 pb-2 align-items-start">
									<h5 className="mb-0 position-relative fw-medium">
										<div className="align-items-center bg-primary d-inline-flex h-60 justify-content-center  position-absolute rounded start-0 text-white theme-icon-box w-60 z-1">
											<span className="theme-icon-box-inner"><FaUserEdit size={36} /></span>
										</div>
										Edit Custom Package
									</h5>
								</Stack>
							</div>
							<div className="flex-grow-1 d-flex flex-column px-1">
								<div className="p-3 d-flex flex-column h-100">
									<Formik
										enableReinitialize={true}
										validationSchema={editCustomPackageSchema}
										initialValues={initialValues}
										onSubmit={(values, actions) => {
											actions.setSubmitting(false)
											handleSubmit(values)
										}}
									>
										{({
											handleSubmit,
											handleChange,
											handleBlur,
											values,
											setFieldValue,
											touched,
											isValid,
											errors
										}) => (
											<Form className="d-flex flex-column flex-grow-1 theme-from">
												<Row className="gx-xl-5">
													{/* AGENCY ID'S */}
													<Col sm={6} md={6} lg={6} className="mb-4 pb-1">
														<MultiSelect
															options={agencyList}
															className="selectpicker"
															controlId="agencyIds"
															label={"Select Agency"}
															value={values?.agencyIds}
															name="agencyIds"
															errorsField={errors.agencyIds}
															handleChange={(event, newValue) => {
																setFieldValue("agencyIds", newValue)
															}
															}
															handleBlur={handleBlur}
														/>
													</Col>
													{/* PLAN NAME */}
													<Col sm={6} md={6} lg={6} className="mb-4 pb-1">
														<InputText
															type="text"
															controlId="planName"
															label="Plan Name"
															value={values?.planName}
															name="planName"
															maxLength="70"
															errorsField={errors?.planName}
															touched={touched?.planName}
															handleChange={handleChange}
															handleBlur={handleBlur}
															className={"w-100 form-control"}
															variant="outlined"
														/>
													</Col>
												</Row>
												{/* MONTHLY PLAN */}
												<Row>
													<Col sm={12}>
														<p className='fs-18 fw-500'>Monthly Plan</p>
													</Col>
												</Row>
												<Row className="gx-xl-5">
													{/* MONTHLY PRICE */}
													<Col md={4} className="mb-4 pb-1">
														<InputText
															type="number"
															controlId="monthly_price"
															label="Price"
															value={values?.monthly_price}
															name="monthly_price"
															maxLength="70"
															errorsField={errors?.monthly_price}
															touched={touched?.monthly_price}
															handleChange={handleChange}
															handleBlur={handleBlur}
															className={"w-100 form-control"}
															variant="outlined"
														/>
													</Col>
													{/* NO OF ACTIVE AGENT */}
													<Col md={4} className="mb-4 pb-1">
														<InputText
															type="number"
															controlId="monthly_noOfActiveAgent"
															label="Number of active agent"
															value={values?.monthly_noOfActiveAgent}
															name="monthly_noOfActiveAgent"
															maxLength="70"
															errorsField={errors?.monthly_noOfActiveAgent}
															touched={touched?.monthly_noOfActiveAgent}
															handleChange={handleChange}
															handleBlur={handleBlur}
															className={"w-100 form-control"}
															variant="outlined"
														/>
													</Col>
													{/* NO OF ACTIVE PROPERTY */}
													<Col md={4} className="mb-4 pb-1">
														<InputText
															type="number"
															controlId="monthly_noOfActiveProperty"
															label="Number of active property"
															value={values?.monthly_noOfActiveProperty}
															name="monthly_noOfActiveProperty"
															maxLength="70"
															errorsField={errors?.monthly_noOfActiveProperty}
															touched={touched?.monthly_noOfActiveProperty}
															handleChange={handleChange}
															handleBlur={handleBlur}
															className={"w-100 form-control"}
															variant="outlined"
														/>
													</Col>
												</Row>
												{/* FEATURED PROPERTY */}
												<Row className="gx-xl-5">
													{/* NO OF ACTIVE FEATURED PROPERTY */}
													<Col md={4} className="mb-4 pb-1">
														<InputText
															type="number"
															controlId="monthly_noOfActiveFeaturedProperty"
															label="Number of featured property"
															value={values?.monthly_noOfActiveFeaturedProperty}
															name="monthly_noOfActiveFeaturedProperty"
															maxLength="70"
															errorsField={errors?.monthly_noOfActiveFeaturedProperty}
															touched={touched?.monthly_noOfActiveFeaturedProperty}
															handleChange={handleChange}
															handleBlur={handleBlur}
															className={"w-100 form-control"}
															variant="outlined"
														/>
													</Col>
													{/* NO OF MAXIMUM FEATURED PROPERTY */}
													<Col md={4} className="mb-4 pb-1">
														<InputText
															type="number"
															controlId="monthly_maxFeaturedProperty"
															label="Maximum Featured Property"
															value={values?.monthly_maxFeaturedProperty}
															name="monthly_maxFeaturedProperty"
															maxLength="70"
															errorsField={errors?.monthly_maxFeaturedProperty}
															touched={touched?.monthly_maxFeaturedProperty}
															handleChange={handleChange}
															handleBlur={handleBlur}
															className={"w-100 form-control"}
															variant="outlined"
														/>
													</Col>
													{/* EXTRA COST PER FEATURED PROPERTY */}
													<Col md={4} className="mb-4 pb-1">
														<InputText
															type="number"
															controlId="monthly_costPerFeaturedProperty"
															label="Extra Cost/Featured Property"
															value={values?.monthly_costPerFeaturedProperty}
															name="monthly_costPerFeaturedProperty"
															maxLength="70"
															errorsField={errors?.monthly_costPerFeaturedProperty}
															touched={touched?.monthly_costPerFeaturedProperty}
															handleChange={(event) => setFieldValue("monthly_costPerFeaturedProperty", event?.target?.value)}
															handleBlur={handleBlur}
															className={"w-100 form-control"}
															variant="outlined"
														/>
													</Col>

												</Row>
												{/* 3D VIEW */}
												<Row className="gx-xl-5">
													{/* NO OF 3D VIEW UPLOAD */}
													<Col md={4} className="mb-4 pb-1">
														<InputText
															type="number"
															controlId="monthly_noOf3dViewUpload"
															label="Number of 3D view"
															value={values?.monthly_noOf3dViewUpload}
															name="monthly_noOf3dViewUpload"
															maxLength="70"
															errorsField={errors?.monthly_noOf3dViewUpload}
															touched={touched?.monthly_noOf3dViewUpload}
															handleChange={handleChange}
															handleBlur={handleBlur}
															className={"w-100 form-control"}
															variant="outlined"
														/>
													</Col>
													{/* MAXIMUM 3D VIEW UPLOAD */}
													<Col md={4} className="mb-4 pb-1">
														<InputText
															type="number"
															controlId="monthly_max3DViewUpload"
															label="Maximum 3D view"
															value={values?.monthly_max3DViewUpload}
															name="monthly_max3DViewUpload"
															maxLength="70"
															errorsField={errors?.monthly_max3DViewUpload}
															touched={touched?.monthly_max3DViewUpload}
															handleChange={handleChange}
															handleBlur={handleBlur}
															className={"w-100 form-control"}
															variant="outlined"
														/>
													</Col>
													{/* EXTRA COST PER FEATURED PROPERTY */}
													<Col md={4} className="mb-4 pb-1">
														<InputText
															type="number"
															controlId="monthly_costPer3dView"
															label="Extra Cost/3D view"
															value={values?.monthly_costPer3dView}
															name="monthly_costPer3dView"
															maxLength="70"
															errorsField={errors?.monthly_costPer3dView}
															touched={touched?.monthly_costPer3dView}
															handleChange={handleChange}
															handleBlur={handleBlur}
															className={"w-100 form-control"}
															variant="outlined"
														/>
													</Col>
													{/* <Col sm={12} md={6} lg={4} className="mb-4 pb-1">
                            <CustomSelect
                              options={[
                                { label: 'None', value: 'NONE' },
                                { label: 'Full', value: 'PARTIAL' },
                                { label: 'Partial', value: 'FULL' },
                              ]}
                              controlId="monthly_dashboard"
                              label="Dashboard"
                              value={values?.monthly_dashboard}
                              name="monthly_dashboard"
                              variant="outlined"
                              errorsField={errors?.monthly_dashboard}
                              touched={touched?.monthly_dashboard}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              onChange={handleChange}
                            />
                          </Col> */}
												</Row>
												{/* <Row className="gx-xl-5">
													<Col sm={6} md={6} lg={2} className="mb-4 pb-1">
														<FormLabel className='fs-16 fw-500'>
															Featured Agency <span className='fs-12'>(ON/OFF)</span>
														</FormLabel>
														<div>
															<Switch
																id="monthly_featuredAgency"
																checked={values?.monthly_featuredAgency}
																checkedChildren={"ON"}
																unCheckedChildren={"OFF"}
																className='fw-bold'
																onChange={(value) => { setFieldValue("monthly_featuredAgency", value) }}
																size={"large"}
															/>
														</div>
													</Col>
												</Row> */}
												{/* YEARLY PLAN */}
												<Row className="gx-xl-5">
													<Col sm={12}>
														<p className='fs-18 fw-500'>Yearly Plan</p>
													</Col>
												</Row>
												{/* YEARLY PRICE */}
												<Row className="gx-xl-5">
													<Col md={4} className="mb-4 pb-1">
														<InputText
															type="number"
															controlId="yearly_price"
															label="Price"
															value={values?.yearly_price}
															name="yearly_price"
															maxLength="70"
															errorsField={errors?.yearly_price}
															touched={touched?.yearly_price}
															handleChange={handleChange}
															handleBlur={handleBlur}
															className={"w-100 form-control"}
															variant="outlined"
														/>
													</Col>
													{/* NO OF ACTIVE AGENT */}
													<Col md={4} className="mb-4 pb-1">
														<InputText
															type="number"
															controlId="yearly_noOfActiveAgent"
															label="Number of active agent"
															value={values?.yearly_noOfActiveAgent}
															name="yearly_noOfActiveAgent"
															maxLength="70"
															errorsField={errors?.yearly_noOfActiveAgent}
															touched={touched?.yearly_noOfActiveAgent}
															handleChange={handleChange}
															handleBlur={handleBlur}
															className={"w-100 form-control"}
															variant="outlined"
														/>
													</Col>
													{/* NO OF ACTIVE PROPERTY */}
													<Col md={4} className="mb-4 pb-1">
														<InputText
															type="number"
															controlId="yearly_noOfActiveProperty"
															label="Number of active property"
															value={values?.yearly_noOfActiveProperty}
															name="yearly_noOfActiveProperty"
															maxLength="70"
															errorsField={errors?.yearly_noOfActiveProperty}
															touched={touched?.yearly_noOfActiveProperty}
															handleChange={handleChange}
															handleBlur={handleBlur}
															className={"w-100 form-control"}
															variant="outlined"
														/>
													</Col>
												</Row>
												{/* FEATURED PROPERTY */}
												<Row className="gx-xl-5">
													{/* NO OF FEATURED PROPERTY */}
													<Col md={4} className="mb-4 pb-1">
														<InputText
															type="number"
															controlId="yearly_noOfActiveFeaturedProperty"
															label="Number of featured property"
															value={values?.yearly_noOfActiveFeaturedProperty}
															name="yearly_noOfActiveFeaturedProperty"
															maxLength="70"
															errorsField={errors?.yearly_noOfActiveFeaturedProperty}
															touched={touched?.yearly_noOfActiveFeaturedProperty}
															handleChange={handleChange}
															handleBlur={handleBlur}
															className={"w-100 form-control"}
															variant="outlined"
														/>
													</Col>
													{/* NO OF MAXIMUM FEATURED PROPERTY */}
													<Col md={4} className="mb-4 pb-1">
														<InputText
															type="number"
															controlId="yearly_maxFeaturedProperty"
															label="Maximum Featured Property"
															value={values?.yearly_maxFeaturedProperty}
															name="yearly_maxFeaturedProperty"
															maxLength="70"
															errorsField={errors?.yearly_maxFeaturedProperty}
															touched={touched?.yearly_maxFeaturedProperty}
															handleChange={handleChange}
															handleBlur={handleBlur}
															className={"w-100 form-control"}
															variant="outlined"
														/>
													</Col>
													{/* EXTRA COST PER FEATURED PROPERTY */}
													<Col md={4} className="mb-4 pb-1">
														<InputText
															type="number"
															controlId="yearly_costPerFeaturedProperty"
															label="Extra Cost/Featured Property"
															value={values?.yearly_costPerFeaturedProperty}
															name="yearly_costPerFeaturedProperty"
															maxLength="70"
															errorsField={errors?.yearly_costPerFeaturedProperty}
															touched={touched?.yearly_costPerFeaturedProperty}
															handleChange={handleChange}
															handleBlur={handleBlur}
															className={"w-100 form-control"}
															variant="outlined"
														/>
													</Col>
												</Row>
												{/* 3D VIEW */}
												<Row className="gx-xl-5">
													{/* NO OF 3D VIEW UPLOAD */}
													<Col md={4} className="mb-4 pb-1">
														<InputText
															type="number"
															controlId="yearly_noOf3dViewUpload"
															label="Number of 3D view"
															value={values?.yearly_noOf3dViewUpload}
															name="yearly_noOf3dViewUpload"
															maxLength="70"
															errorsField={errors?.yearly_noOf3dViewUpload}
															touched={touched?.yearly_noOf3dViewUpload}
															handleChange={handleChange}
															handleBlur={handleBlur}
															className={"w-100 form-control"}
															variant="outlined"
														/>
													</Col>
													{/* MAXIMUM 3D VIEW UPLOAD */}
													<Col md={4} className="mb-4 pb-1">
														<InputText
															type="number"
															controlId="yearly_max3DViewUpload"
															label="Maximum 3D view"
															value={values?.yearly_max3DViewUpload}
															name="yearly_max3DViewUpload"
															maxLength="70"
															errorsField={errors?.yearly_max3DViewUpload}
															touched={touched?.yearly_max3DViewUpload}
															handleChange={handleChange}
															handleBlur={handleBlur}
															className={"w-100 form-control"}
															variant="outlined"
														/>
													</Col>
													{/* EXTRA COST PER FEATURED PROPERTY */}
													<Col md={4} className="mb-4 pb-1">
														<InputText
															type="number"
															controlId="yearly_costPer3dView"
															label="Extra Cost/3D view"
															value={values?.yearly_costPer3dView}
															name="yearly_costPer3dView"
															maxLength="70"
															errorsField={errors?.yearly_costPer3dView}
															touched={touched?.yearly_costPer3dView}
															handleChange={handleChange}
															handleBlur={handleBlur}
															className={"w-100 form-control"}
															variant="outlined"
														/>
													</Col>
													{/* <Col sm={6} md={4} className="mb-4 pb-1">
                            <CustomSelect
                              options={[
                                { label: 'None', value: 'NONE' },
                                { label: 'Full', value: 'PARTIAL' },
                                { label: 'Partial', value: 'FULL' },
                              ]}
                              controlId="yearly_dashboard"
                              label="Dashboard"
                              value={values?.yearly_dashboard}
                              name="yearly_dashboard"
                              variant="outlined"
                              errorsField={errors?.yearly_dashboard}
                              touched={touched?.yearly_dashboard}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              onChange={handleChange}
                            />
                          </Col> */}
												</Row>
												{/* FEATURED AGENCY */}
												{/* <Row className="gx-xl-5">
													<Col sm={6} md={6} lg={2} className="mb-4 pb-1">
														<FormLabel className='fs-16 fw-500'>
															Featured Agency <span className='fs-12'>(ON/OFF)</span>
														</FormLabel>
														<div>
															<Switch
																id="yearly_featuredAgency"
																checked={values?.yearly_featuredAgency}
																checkedChildren={"ON"}
																unCheckedChildren={"OFF"}
																className='fw-bold'
																onChange={(value) => { setFieldValue("yearly_featuredAgency", value) }}
																size={"large"}
															/>
														</div>
													</Col>
												</Row> */}
												{/* SUBMIT BUTTON */}
												<Stack direction="horizontal" gap={3} className="my-3">
													<Button
														variant="primary"
														onClick={handleSubmit}
														className="rounded-xs fs-15"
														type="submit"
														disabled={isSubmitting}
													>Save</Button>
													<Link onClick={() => navigate(-1)} className="btn btn-outline-secondary rounded-xs fs-15">Cancel</Link>
												</Stack>
											</Form>
										)}
									</Formik>
								</div>
							</div>
						</Card>}
			</div>
		</div>
	)
}

export default Edit
