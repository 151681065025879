import React from 'react'
import { Route, Routes } from 'react-router-dom'
import ApprovedAgency from './AgencyList/ApprovedAgency'
import NewRequest from './AgencyList/NewRequest'
import { AgencyDetails } from './Details'
import { EditAgency } from './Edit'
import { Error404 } from '../Error404'

export const Agency = () => {
  return (
    <Routes>
        <Route path="/" element={<ApprovedAgency/>}/>
        <Route path="/edit/:id" element={<EditAgency/>}/>
        <Route path="/new-request" element={<NewRequest/>}/>
        <Route path="/view/:id" element={<AgencyDetails/>}/>
        <Route path ="*" element={<Error404/>} />
    </Routes>
  )
}

export const nameBasedRoutes = {
  agency:{
    name : "ApprovedAgency",
    path : "/app/agency"
  },
  newAgenciesList : {
    name : "NewAgenciesList",
    path : "/app/agency/new-request"
  },
  agencyDetails : {
    name : "AgencyDetails",
    path : "/app/agency/view"
  }
}

