import { createSlice } from "@reduxjs/toolkit";
import storage from "../helpers/storage";
import { getAdminInfo } from "../services/userService";
import { getUserRoleDetails } from "../services/permissionService";
let initalToken = storage.getToken();

// An asynchronous function to fetch user data
const fetchAdminInitialData = async () => {
  try {
    // CHECK IF INITIAL TOKEN EXISTS OR NOT
    if (initalToken) {
      // GET ADMIN ACCOUNT INFO FOR INITIAL STATE
      const response = await getAdminInfo();
      // CHECK USER ROLE FOR RESTRICTIONS
      if (response?.data?.authorities?.includes("ROLE_SUB_ADMIN") && response?.data?.roles[0]?.id) {
        // IF ROLE IS SUB ADMIN THEN GET ROLE DETAILS (PERMISSIONS )
        try{
          const rolesData = await getUserRoleDetails(response?.data?.roles[0]?.id);
          if (rolesData) {
            return { adminInfo: response?.data, rolesData: rolesData?.data };
          } else {
            return { adminInfo: response?.data };
          }
        }catch(error){
          return { adminInfo: response?.data };
        }
      } else {
        return { adminInfo: response?.data };
      }
    } else {
      return null;
    }
  } catch (error) {
    console.error(error); // Log the error for debugging
    return null;
  }
};

// Fetch the user initial data before creating the slice
const adminInitialData = await fetchAdminInitialData();


const adminSlice = createSlice({
  name: "admin",
  initialState: {
    adminData: adminInitialData?.adminInfo ?? null,
    rolesData: adminInitialData?.rolesData,
    token: initalToken ?? null,
  },
  reducers: {
    setAdmin: (state, action) => {
      state.adminData = action.payload.adminData;
      state.rolesData = action.payload.rolesData ?? null
      if (action?.payload?.token) {
        state.token = action.payload.token;
      }
    },
    setRoleDetails : (state,action)=>{
      state.rolesData = action.payload.rolesData ?? null
    },

    logoutAdmin: (state) => {
      //  add storage here
      state.adminData = null;
      state.rolesData = null;
      state.token = null;
    },
  },
});

export const { setAdmin, setRoleDetails, logoutAdmin } = adminSlice?.actions;
export default adminSlice?.reducer;
