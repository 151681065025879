import { ADD_FAQ_API, EDIT_CMS_DATA_API, FAQ_CATEGORY_LIST, FAQ_LIST_API,GET_FAQ_BY_ID_API,FAQ_STATUS_UPDATE_API, EDIT_FAQ_API } from "../constants/paths"
import {axios} from "./axios"

// FAQ LISTING
export const FaqCategoryListApi = async({params}) =>{
    const response = await axios.get(FAQ_CATEGORY_LIST,{params})
    return response?.data
}

// FAQ LISTING
export const FaqListingApi = async(params) =>{
    const response = await axios.get(FAQ_LIST_API,{params})
    return response
}

// ADD FAQ
export const AddFaqApi = async(data) =>{
    const response = await axios.post(ADD_FAQ_API,data)
    return response?.data
}

// EDIT FAQ
export const EditFaqApi = async(data) =>{
    const response = await axios.put(EDIT_FAQ_API,data)
    return response?.data
}

// GET FAQ BY ID
export const GetFaqDataApi = async(id) =>{
    const response = await axios.get(`${GET_FAQ_BY_ID_API}/${id}`)
    return response?.data
}

// FAQ STATUS UPDATE API

export const FaqStatusUpdateApi = async(id,status) =>{
    const response = await axios.get(`${FAQ_STATUS_UPDATE_API}/${id}/${status}`)
    return response?.data
}