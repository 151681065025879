import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Add from './add/Add'
import Listing from './List/Listing'
import Edit from './edit/Edit'

export const Amenity =()=> {
    return (
        <Routes>
            <Route path="/" element={<Listing />} />
            <Route path="/add" element={<Add />} />
            <Route path="/edit/:id" element={<Edit/>}/>
        </Routes>
    )
}


export const nameBasedRoutes = {
    amenity: {
        name: "Amenity",
        path: `/app/amenity`,
    },
    editAmenity: {
        name: "EditAmenity",
        path: `/app/amenity/edit`
    }
}
