import React, { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import { FaBuilding } from "react-icons/fa";
import { BsPersonSquare } from "react-icons/bs";
import { MdSupervisorAccount } from "react-icons/md";
import { toast } from 'react-toastify';
import { mainStatsApi } from '../../services/dashboardService';


const MainStats = ({startDate ,endDate}) => {

	const [statsData, setStatsData] = useState({})
	const [loading, setLoading] = useState(true)
	


	// FUNCTION FOR FETCH MAIN STATS
	const fetchMainStats = () => {
		const params = {
			startDate: startDate ,
			endDate: endDate
		}
		mainStatsApi(params)
			.then((response) => {
				setStatsData(response)
			})
			.catch(error => {
				 if(error?.status == 500){
                toast.error('Unauthorized or server issue. Please refresh or try later. ')
            }else{
                 toast.error(
          error?.error_description
            ? error?.error_description
            : error?.details ? error?.details : error?.message
        );
            }
			}).finally(() => {
				setLoading(false)
			})
	}


	useEffect(() => {
		fetchMainStats()
	}, [startDate, endDate])

	return (
		<div className="pb-3">
			
			<div className="row g-3 text-center justify-content-center">
				<div className="col-xl col-md-4 col-sm-6 h-100">
					<Card className="blue_2_bg text-white">
						<Card.Body>
							<div className="bg-white-tr rounded-circle h-64 w-64 m-auto d-flex align-items-center justify-content-center">
								<MdSupervisorAccount className="text-white" size={36} />
							</div>
							<Card.Title className="fs-30 mt-3 mb-2">
								{statsData?.newPlatformUsers}
							</Card.Title>
							<Card.Text className="fs-16 fw-medium">
								New Platform Users
							</Card.Text>
						</Card.Body>
					</Card>
				</div>
				<div className="col-xl col-md-4 col-sm-6 h-100">
					<Card className=" bg-purple text-white">
						<Card.Body>
							<div className="bg-white-tr rounded-circle h-64 w-64 m-auto d-flex align-items-center justify-content-center">
								<MdSupervisorAccount className="text-white" size={36} />
							</div>
							<Card.Title className="fs-30 mt-3 mb-2">
								{statsData?.totalRegisteredUsers}
							</Card.Title>
							<Card.Text className="fs-16 fw-medium">
								Total Registered Users
							</Card.Text>
						</Card.Body>
					</Card>
				</div>
				<div className="col-xl col-md-4 col-sm-6 h-100">
					<Card className="dark_bg text-white">
						<Card.Body>
							<div className="bg-white-tr rounded-circle h-64 w-64 m-auto d-flex align-items-center justify-content-center">
								<BsPersonSquare className="text-white" size={38} />
							</div>
							<Card.Title className="fs-30 mt-3 mb-2">
								{statsData?.newAgencies}
							</Card.Title>
							<Card.Text className="fs-16 fw-medium">
								New Agency
							</Card.Text>
						</Card.Body>
					</Card>
				</div>
				<div className="col-xl col-md-4 col-sm-6 h-100">
					<Card className="bgred text-white">
						<Card.Body>
							<div className="bg-white-tr rounded-circle h-64 w-64 m-auto d-flex align-items-center justify-content-center">
								<BsPersonSquare className="text-white" size={38} />
							</div>
							<Card.Title className="fs-30 mt-3 mb-2">
								{statsData?.totalAgencies}
							</Card.Title>
							<Card.Text className="fs-16 fw-medium">
								Total Agency
							</Card.Text>
						</Card.Body>
					</Card>
				</div>
				<div className="col-xl col-md-4 col-sm-6 h-100">
					<Card className="bggreen text-white">
						<Card.Body>
							<div className="bg-white-tr rounded-circle h-64 w-64 m-auto d-flex align-items-center justify-content-center">
								<FaBuilding className="text-white" size={36} />
							</div>
							<Card.Title className="fs-30 mt-3 mb-2">
								{statsData?.newProperties}
							</Card.Title>
							<Card.Text className="fs-16 fw-medium">
								New Properties
							</Card.Text>
						</Card.Body>
					</Card>
				</div>
				<div className="col-xl col-md-4 col-sm-6 h-100">
					<Card className="bgorange text-white">
						<Card.Body>
							<div className="bg-white-tr rounded-circle h-64 w-64 m-auto d-flex align-items-center justify-content-center">
								<FaBuilding className="text-white" size={36} />
							</div>
							<Card.Title className="fs-30 mt-3 mb-2">

								{statsData?.totalProperties}
							</Card.Title>
							<Card.Text className="fs-16 fw-medium">
								Total Properties
							</Card.Text>
						</Card.Body>
					</Card>
				</div>
				<div className="col-xl col-md-4 col-sm-6 h-100">
					<Card className="bgdarkgreen text-white">
						<Card.Body>
							<div className="bg-white-tr rounded-circle h-64 w-64 m-auto d-flex align-items-center justify-content-center">
								<FaBuilding className="text-white" size={36} />
							</div>
							<Card.Title className="fs-30 mt-3 mb-2">
								{statsData?.activeProperties}
							</Card.Title>
							<Card.Text className="fs-16 fw-medium">
								Active Properties
							</Card.Text>
						</Card.Body>
					</Card>
				</div>

			</div>
		</div>
	)
}

export default MainStats