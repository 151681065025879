import { TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { Card, Stack, Row, Col } from 'react-bootstrap';
import { MdSupervisorAccount, MdClear } from "react-icons/md";
import { useLocation, useNavigate } from 'react-router-dom';
import { MatDataGridWithPagination } from '../../../components/common/dataGrid/DataGridWithPagination';
import { ActionCell } from '../../../components/common/dataGrid/ActionCell';
import { getVillageListAPI } from '../../../services/villageService';
import { toast } from 'react-toastify';
import LoaderMu from '../../../components/common/LoaderMu';
import { nameBasedRoutes } from '../routes';
import { useSelector } from 'react-redux';
import NotAuthorized from '../../../components/common/NotAuthorized';


export const AllList = () => {

  // MANAGING USER PERMISSIONS HERE
  const roleDetails = useSelector((state) => state?.rolesData?.masterPermissionResponseDTOList)
  const [isAuthorized, setIsAuthorized] = useState(false)
  const [authPermissions, setAuthPermissions] = useState(null)

  useEffect(() => {
    if (roleDetails) {
      const permissions = roleDetails.find((detail) => {
        return detail.childPermissions.find((childPerm) => (
          childPerm?.key === "village_list" && childPerm?.status === true
        ))
      })
      if (permissions) {
        setIsAuthorized(true)
        // Create an object from childPermissions
        const permissionsObject = {};
        permissions?.childPermissions.forEach(permission => {
          permissionsObject[permission.title.toLowerCase()] = permission.status;
        });
        setAuthPermissions(permissionsObject)
      } else {
        setIsAuthorized(false)
      }
    } else {
      setIsAuthorized(true)
    }
  }, [roleDetails])

  // FOR MANAGING PAGINATION USING URL
  const location = useLocation();

  const params = new URLSearchParams(location?.search)

  const currentPage = params.get("page") ?? 0;
  const search = params.get("search") ?? ''
  const navigate = useNavigate()

  const [loading, setLoading] = useState(true)
  const [villageList, setVillageList] = useState([])
  const [searchValue, setSearchValue] = useState(search ?? '');
  const [pageSize, setPageSize] = useState(10)
  const [totalResults, setTotalResults] = useState(0);
  const [sortingData, setSortingData] = useState({});

  // THIS IS FOR SEARCHING
  const handleValueChange = (value) => {
    setSearchValue(value);
    if (value !== "") {
      navigate(`${nameBasedRoutes.village?.path}?page=${currentPage}&search=${encodeURIComponent(value)}`)
    } else {
      navigate(`${nameBasedRoutes.village?.path}?page=${currentPage}`)
    }
  };

  // THIS IS FOR PAGE CHANGE
  const handlePageChange = (value) => {
    navigate(`${nameBasedRoutes.village?.path}?page=${value?.page}${search !== '' ? '&search=' + search : ''}`)
    setPageSize(value?.pageSize)
  }

  // HANDLE SORT MODAL CHANGE
  const handleSortModelChange = (data) => {
    setSortingData(data?.length > 0 ? data[0] : {})
  }

  // CLEAR SEARCH INPUT BOX
  const handleClear = (value) => {
    setSearchValue("");
    if (search !== "") {
      navigate(`${nameBasedRoutes.village?.path}?page=${currentPage}`)
    }
  };

  // COLUMNS FOR DATA TABLE
  const columns = [
    {
      field: "id",
      headerName: "S. No.",
      editable: false,
      flex: 0.05,
      minWidth: 100,
      sortable: false,
      renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1 + ((currentPage * pageSize))
    },
    {
      field: 'village',
      headerName: 'Village',
      editable: false,
      flex: 0.6,
      minWidth: 150,
      sortable: true,
    },
    {
      field: 'villageCode',
      headerName: 'Village Code',
      editable: false,
      sortable: false,
      flex: 0.6,
      minWidth: 150,
    },
    {
      field: 'governorate',
      headerName: 'Governorate',
      editable: false,
      flex: 0.6,
      minWidth: 150,
      sortable: true,
    },
    {
      field: 'directorate',
      headerName: 'Directorate',
      editable: false,
      flex: 0.6,
      minWidth: 150,
      sortable: true,
    },
    {
      field: "action",
      headerName: "Action",
      editable: false,
      flex: 0.2,
      minWidth: 120,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (props) => {
        return (
          <ActionCell
            controlId="app/village"
            isViewEnable={false}
            isEditEnable={authPermissions === null ? true  : authPermissions?.edit}
            redirectID={props.row.id}
            rowData={props.row}
            pageNumber={currentPage}
            searchedValue={searchValue}
          />
        );
      },
    },]

  // GET USERS DATA LIST FOR TABLE
  const fetchVillageData = () => {
    setLoading(true)
    // ?search=&page=0&size=10&sort=createdDate,DESC
    const params = {
      page: currentPage ?? 0,
      size: pageSize ?? 10,
      search: searchValue ?? ''
    }
    if (sortingData && Object.keys(sortingData).length !== 0) {
      //params["sort"] = `${sortingData?.field},${sortingData?.sort?.toUpperCase()}`
      //params["sort"] = `title,${sortingData?.sort?.toUpperCase()}`
      // sortKey=village&sortOrder=ASC/DESC
      params["sortKey"] = `${sortingData?.field}`
      params["sortOrder"] = `${sortingData?.sort?.toUpperCase()}`
    }
    getVillageListAPI(params).then(response => {
      setLoading(false)
      setTotalResults(response?.headers["x-total-count"])
      setVillageList(response?.data)
    }).catch((error) => {
      setLoading(false)
       if(error?.status == 500){
                toast.error('Unauthorized or server issue. Please refresh or try later. ')
            }else{
                 toast.error(
          error?.error_description
            ? error?.error_description
            : error?.details ? error?.details : error?.message
        );
            }
    })
  }

  // ON COMPONENT RENDER AND DEPENDENCY STATE CHANGED
  useEffect(() => {
    // CALLING FUNCTION FOR FETCH DATA
    if (isAuthorized) {
      fetchVillageData()
    } else {
      setLoading(false)
    }
  }, [currentPage, pageSize, searchValue, sortingData, isAuthorized])

  return (
    <div className="d-flex flex-column pageContainer p-3 h-100">
      <LoaderMu loading={loading} />
      <div className="flex-grow-1 h-100 pageContent position-relative pt-4 overflow-auto">
        {
          !isAuthorized && !loading ?
            <NotAuthorized /> :
            <Card className="h-100 bg-white shadow border-0 theme-card-cover">
              <div className="theme-card-header px-1">
                <Stack
                  direction="horizontal"
                  gap={2}
                  className="flex-wrap justify-content-between px-3 pt-3 pb-2 align-items-start"
                >
                  <h5 className="mb-0 position-relative fs-18 text-black fw-medium">
                    <div className="align-items-center bg-primary d-inline-flex h-60 justify-content-center  position-absolute rounded start-0 text-white theme-icon-box w-60 z-1">
                      <MdSupervisorAccount size={40} />
                    </div>
                    Village List
                  </h5>

                </Stack>
              </div>
              <div className="flex-grow-1 d-flex flex-column px-3 h-100 overflow-auto">
                <div className="theme-data-table-filter px-1">
                  <Row className="justify-content-end">
                    <Col sm={5} lg={2} className="mb-3 pb-1">
                      <TextField
                        id="search-filter"
                        label="Search"
                        variant="standard"
                        fullWidth
                        value={searchValue}
                        onChange={(e) => {
                          handleValueChange(e.target.value);
                        }}
                      />
                      <MdClear
                        className="clear-postion"
                        onClick={handleClear}
                      ></MdClear>
                    </Col>
                  </Row>
                </div>
                <MatDataGridWithPagination
                  controlId={"id"}
                  columns={columns}
                  data={villageList ?? []}
                  onPaginationModelChange={handlePageChange}
                  pageSize={pageSize}
                  totalResults={totalResults}
                  currentPage={currentPage}
                  handleSortModelChange={handleSortModelChange}
                // totalPages={totalPages}
                // rowsPerPage={rowPerPage}
                />
              </div>
            </Card>}
      </div>
    </div>
  )
}


