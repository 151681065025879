import React from 'react'
import AgentReport from './AgentReport';
import { Route, Routes } from 'react-router-dom';
import PlatformUsersReport from './PlatforUsersReport';
import PropertyReport from './PropertyReport';
import PlanReport from './PlanReport';
import SystemUserReport from './SystemUserReport';
import AgencyReport from './AgencyReport';
import { Error404 } from '../Error404';


export const Reports = () => {
  return (
    <Routes>
      <Route path="/agent" element={<AgentReport />} />
      <Route path="/platform-user" element={<PlatformUsersReport />} />
      <Route path="/property" element={<PropertyReport />} />
      <Route path="/plan" element={<PlanReport/>}/>
      <Route path="/system-user" element={<SystemUserReport/>}/>
      <Route path="/agency" element={<AgencyReport/>}/>
      <Route path="*" element={<Error404/>}/>
    </Routes>
  );
}