import { TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { Card, Stack, Row, Col } from 'react-bootstrap';
import { MdOutlineReportGmailerrorred, MdClear } from "react-icons/md";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { MatDataGridWithPagination } from '../../components/common/dataGrid/DataGridWithPagination';
import { toast } from 'react-toastify';
import LoaderMu from '../../components/common/LoaderMu';
import { nameBasedProtectedRoutes } from '../../router/protected';
import { complaintListApi, generalInfoApi } from '../../services/complaintService';
import moment from 'moment';
import { IoMdEye } from "react-icons/io";
import DetailsModal from './DetailsModal';
import { nameBasedRoutes } from '../Agency/routes';
import { useSelector } from 'react-redux';
import NotAuthorized from '../../components/common/NotAuthorized';
function Compliant() {

  // MANAGING PERMISSIONS HERE

  const roleDetails = useSelector((state) => state?.rolesData?.masterPermissionResponseDTOList)
  const [isAuthorized, setIsAuthorized] = useState(false)
  const [authPermissions, setAuthPermissions] = useState(null)

  useEffect(() => {
    if (roleDetails) {
      const permissions = roleDetails.find((detail) => {
        return detail.childPermissions.find((childPerm) => (
          childPerm?.key === "complaints_list" && childPerm?.status === true
        ))
      })
      if (permissions) {
        setIsAuthorized(true)
        // Create an object from childPermissions
        const permissionsObject = {};
        permissions?.childPermissions.forEach(permission => {
          permissionsObject[permission.title.toLowerCase()] = permission.status;
        });
        setAuthPermissions(permissionsObject)
      } else {
        setIsAuthorized(false)
      }
    } else {
      setIsAuthorized(true)
    }

  }, [roleDetails])

  // FOR MANAGING PAGINATION USING URL
  const location = useLocation();

  const params = new URLSearchParams(location?.search)

  const page = params.get("page") ?? 0;
  const search = params.get("search") ?? ''

  const navigate = useNavigate()

  const [loading, setLoading] = useState(true)
  const [complaintList, setComplaintList] = useState([])
  const [searchValue, setSearchValue] = useState(search ?? '');
  const [pageSize, setPageSize] = useState(10)
  const [totalResults, setTotalResults] = useState(0);
  const [sortingData, setSortingData] = useState({});
  const [generalInfo, setGeneralInfo] = useState({})

  const [openViewModal, setOpenViewModal] = useState(false)
  const [complaintDetails, setComplaintDetails] = useState({})

  // THIS IS FOR SEARCHING
  const handleValueChange = (value) => {
    setSearchValue(value);
    if (value !== "") {
      navigate(`${nameBasedProtectedRoutes?.complaint?.path}?page=${page}&search=${encodeURIComponent(value)}`)
    } else {
      navigate(`${nameBasedProtectedRoutes?.complaint?.path}?page=${page}`)
    }
  };
  // THIS IS FOR PAGE CHANGE
  const handlePageChange = (value) => {
    navigate(`${nameBasedProtectedRoutes?.complaint?.path}?page=${value?.page}${search !== '' ? '&search=' + search : ''}`)
    setPageSize(value?.pageSize)
  }
  // HANDLE SORT MODAL CHANGE
  const handleSortModelChange = (data) => {
    setSortingData(data?.length > 0 ? data[0] : {})
  }

  // CLEAR SEARCH INPUT BOX
  const handleClear = () => {
    setSearchValue("");
    if (search !== "") {
      navigate(`${nameBasedProtectedRoutes?.complaint?.path}?page=${page}`)
    }
  };

  // COLUMNS FOR DATA TABLE
  const columns = [
    {
      field: "id",
      headerName: "S. No.",
      editable: false,
      flex: 0.05,
      minWidth: 100,
      sortable: false,
      renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1 + ((page * pageSize))
    },
    {
      field: "complaintReason",
      headerName: "Complaint Reason",
      editable: false,
      flex: 0.6,
      minWidth: 220,
      sortable: false,
      renderCell: (props) => {
        return <span>{generalInfo[props.row.complaintReason]}</span>
      }
    },
    {
      field: "createdAt",
      headerName: "Complaint time",
      editable: false,
      flex: 0.6,
      minWidth: 220,
      sortable: true,
      renderCell: (props) => {
        return <span>{props?.row?.createdAt ? moment(props?.row?.createdAt).format('DD-MM-YYYY hh:mm:ss:A') : ''}</span>
      }
    },
    {
      field: "propertyTitle",
      headerName: "Property Title",
      editable: false,
      flex: 0.6,
      minWidth: 200,
      sortable: false,
      renderCell: (props) => {
        return <a href={`${process.env.REACT_APP_WEBSITE_WEB_URL}/property-details/${props?.row?.property?.id}`} className='text-black' target="_blank">{props?.row?.property?.title?.en ?? ''}</a>
      }
    },
    {
      field: "agencyName",
      headerName: "Agency Name",
      editable: false,
      flex: 0.6,
      minWidth: 150,
      sortable: false,
      renderCell: (props) => {
        return <Link to={`${nameBasedRoutes?.agencyDetails?.path}/${props?.row?.property?.agencyId}`} className='text-black'>{props?.row?.property?.agency?.name ?? ''}</Link>
      }
    },
    {
      field: "amakenId",
      headerName: "Amaken Id",
      editable: false,
      flex: 0.6,
      minWidth: 150,
      sortable: false,
      renderCell: (props) => {
        return <span>{props?.row?.property?.amakenId ?? ''}</span>
      }
    },
    {
      field: "email",
      headerName: "Email",
      editable: false,
      flex: 0.6,
      minWidth: 220,
      sortable: false,
    },
    {
      field: "action",
      headerName: "Action",
      editable: false,
      flex: 0.6,
      minWidth: 220,
      sortable: false,
      renderCell: (props) => {
        if (authPermissions !== null && authPermissions?.detail === true) {
          return <div><button className='btn' onClick={() => {
            setOpenViewModal(true)
            setComplaintDetails(props?.row)
          }}><IoMdEye /></button> </div>
        } else {
          return null
        }
      }
    }
  ]
  // GET USERS DATA LIST FOR TABLE
  const fetchComplaintList = () => {
    setLoading(true)
    // ?search=&page=0&size=10&sort=createdDate,DESC
    const params = {
      page: page ?? 0,
      size: pageSize ?? 10,
      search: searchValue ?? '',
    }
    if (sortingData && Object.keys(sortingData).length !== 0) {
      params["sort"] = `${sortingData?.field},${sortingData?.sort?.toUpperCase()}`
    }
    complaintListApi(params).then(response => {
      setLoading(false)
      setTotalResults(response?.headers["x-total-count"])
      setComplaintList(response?.data)
    }).catch((error) => {
      setLoading(false)
       if(error?.status == 500){
                toast.error('Unauthorized or server issue. Please refresh or try later. ')
            }else{
                 toast.error(
          error?.error_description
            ? error?.error_description
            : error?.details ? error?.details : error?.message
        );
            }
    })
  }

  // FETCH GENERAL INFO FOR COMPLAINT REASONS
  const fetchGeneralInfo = () => {
    generalInfoApi(params).then(response => {
      setGeneralInfo(response?.complaintReasonMap)
    }).catch((error) => {
       if(error?.status == 500){
                toast.error('Unauthorized or server issue. Please refresh or try later. ')
            }else{
                 toast.error(
          error?.error_description
            ? error?.error_description
            : error?.details ? error?.details : error?.message
        );
            }
    }).finally(() => {
      setLoading(false)
    })
  }

  useEffect(() => {
    // CALLING FUNCTION FOR FETCH DATA
    if (isAuthorized) {
      fetchComplaintList()
    } else {
      setLoading(false)
    }
  }, [page, pageSize, searchValue, sortingData, isAuthorized])

  useEffect(() => {
    fetchGeneralInfo()
  }, [])
  return (
    <div className="d-flex flex-column pageContainer p-3 h-100">
      <LoaderMu loading={loading} />
      <div className="flex-grow-1 h-100 pageContent position-relative pt-4 overflow-auto">
        {
          !isAuthorized && !loading ?
            <NotAuthorized /> :
            <Card className="h-100 bg-white shadow border-0 theme-card-cover">
              <div className="theme-card-header px-1">
                <Stack
                  direction="horizontal"
                  gap={2}
                  className="flex-wrap justify-content-between px-3 pt-3 pb-2 align-items-start"
                >
                  <h5 className="mb-0 position-relative fs-18 text-black fw-medium">
                    <div className="align-items-center bg-primary d-inline-flex h-60 justify-content-center  position-absolute rounded start-0 text-white theme-icon-box w-60 z-1">
                      <MdOutlineReportGmailerrorred size={40} />
                    </div>
                    Property Complaints
                  </h5>
                </Stack>
              </div>
              <div className="flex-grow-1 d-flex flex-column px-3 h-100 overflow-auto">
                <div className="theme-data-table-filter px-1">
                  <Row className="justify-content-end">
                    <Col sm={5} lg={2} className="mb-3 pb-1">
                      <TextField
                        id="search-filter"
                        label="Search"
                        variant="standard"
                        fullWidth
                        value={searchValue}
                        onChange={(e) => {
                          handleValueChange(e.target.value);
                        }}
                      />
                      <MdClear
                        className="clear-postion"
                        onClick={handleClear}
                      ></MdClear>
                    </Col>
                  </Row>
                </div>
                <MatDataGridWithPagination
                  controlId={"id"}
                  columns={columns}
                  data={complaintList ?? []}
                  onPaginationModelChange={handlePageChange}
                  pageSize={pageSize}
                  totalResults={totalResults}
                  currentPage={parseInt(page)}
                  handleSortModelChange={handleSortModelChange}
                // totalPages={totalPages}
                // rowsPerPage={rowPerPage}
                />
              </div>
            </Card>}
      </div>
      <DetailsModal isOpen={openViewModal} setIsOpen={setOpenViewModal} complaintDetails={complaintDetails} setComplaintDetails={setComplaintDetails} />
    </div>
  )
}
export default Compliant
