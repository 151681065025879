import React, { useEffect, useState } from 'react'
import { Form, Formik } from 'formik'
import { Button, Card, Col, Row, Stack } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import { FaUserEdit } from "react-icons/fa";
import InputText from '../../../components/common/InputText'
import { updateAmenityAPI, getAmenityById } from '../../../services/amenityService'
import { toast } from 'react-toastify'
import { updateAmenitySchema } from '../../../validations/ValidationSchema'
import { Switch } from 'antd';
import LoaderMu from '../../../components/common/LoaderMu';
import { useSelector } from 'react-redux';
import NotAuthorized from '../../../components/common/NotAuthorized';

function Edit() {

  // MANAGING USER PERMISSIONS HERE

  const roleDetails = useSelector((state) => state?.rolesData?.masterPermissionResponseDTOList)

  const [isAuthorized, setIsAuthorized] = useState(false)

  useEffect(() => {
    if (roleDetails) {
      const permissions = roleDetails.find((detail) => {
        return detail.childPermissions.find((childPerm) => (
          childPerm?.key === "amenity_edit" && childPerm?.status === true
        ))
      })
      if (permissions) {
        setIsAuthorized(true)
      } else {
        setIsAuthorized(false)
      }
    } else {
      setIsAuthorized(true)
    }

  }, [roleDetails])

  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const [initialValues, setInitialValues] = useState({
    amenity_en: "",
    amenity_ar: "",
    amenity_status: false
  })

  const { id } = useParams()

  // FORM SUBMIT FOR EDIT AMENITY
  const handleSubmit = (values, actions) => {
    setIsSubmitting(true)
    const formData = {
      id: id,
      title: { en: values?.amenity_en, ar: values?.amenity_ar },
      status: values?.amenity_status
    }
    // CALLING API FOR CREATE PLATFORM USER
    updateAmenityAPI(formData).then((response) => {
      setIsSubmitting(false)
      navigate(-1)
      toast.success(response?.data?.message)
    }).catch((error) => {
      setIsSubmitting(false)
       if(error?.status == 500){
                toast.error('Unauthorized or server issue. Please refresh or try later. ')
            }else{
                 toast.error(
          error?.error_description
            ? error?.error_description
            : error?.details ? error?.details : error?.message
        );
            }
    })
  }

  // TOGGLE AMENITY STATUS
  const handleAmenityStatus = (value, setFieldValue) => {
    setFieldValue('amenity_status', value)
  }

  // FETCH AMENITY DETAILS
  const getAmenityDetails = () => {
    // GETTING DATA FROM API
    getAmenityById(id).then(response => {
      setInitialValues({
        amenity_en: response?.title?.en,
        amenity_ar: response?.title?.ar,
        amenity_status: response?.status,
      })
      setLoading(false)
    }).catch((error) => {
       if(error?.status == 500){
                toast.error('Unauthorized or server issue. Please refresh or try later. ')
            }else{
                 toast.error(
          error?.error_description
            ? error?.error_description
            : error?.details ? error?.details : error?.message
        );
            }
    }).finally(() => {
      setLoading(false)
    })
  }

  // IS USER IS AUTHORIZED THEN ONLY GET AMENITY DATA
  useEffect(() => {
    if (isAuthorized) {
      getAmenityDetails()
    } else {
      setLoading(false)
    }
  }, [isAuthorized])

  return (
    <div className="d-flex flex-column pageContainer p-3 h-100 overflow-auto">
      <LoaderMu loading={loading} />
      <div className="flex-grow-1 pageContent position-relative pt-4">
        {
          !isAuthorized && !loading ?
            <NotAuthorized /> :
            <Card className="h-100 bg-white shadow border-0 theme-card-cover">
              <div className="theme-card-header px-1">
                <Stack direction="horizontal" gap={2} className="flex-wrap justify-content-between px-3 pt-3 pb-2 align-items-start">
                  <h5 className="mb-0 position-relative fw-medium">
                    <div className="align-items-center bg-primary d-inline-flex h-60 justify-content-center  position-absolute rounded start-0 text-white theme-icon-box w-60 z-1">
                      <span className="theme-icon-box-inner"><FaUserEdit size={36} /></span>
                    </div>
                    Edit Amenity
                  </h5>
                </Stack>
              </div>
              <div className="flex-grow-1 d-flex flex-column px-1">
                <div className="p-3 d-flex flex-column h-100">
                  <Formik
                    enableReinitialize={true}
                    validationSchema={updateAmenitySchema}
                    initialValues={initialValues}
                    onSubmit={(values, actions) => {
                      actions.setSubmitting(false)
                      handleSubmit(values, actions)
                    }}
                  >
                    {({
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      values,
                      setFieldValue,
                      touched,
                      isValid,
                      errors
                    }) => (
                      <Form className="d-flex flex-column flex-grow-1 theme-from">
                        <Row className="gx-xl-5">
                          <Col sm={6} md={6} lg={6} className="mb-4 pb-1">
                            <InputText
                              type="amenity_en"
                              controlId="amenity_en"
                              label="Amenity english"
                              value={values?.amenity_en}
                              name="amenity_en"
                              maxLength="70"
                              errorsField={errors?.amenity_en}
                              touched={touched?.amenity_en}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              className={"w-100 form-control"}
                              variant="outlined"
                            />
                          </Col>
                          <Col sm={6} md={6} lg={6} className="mb-4 pb-1">
                             <div>
                              <InputText
                                dir="rtl"
                                type="amenity_ar"
                                controlId="amenity_ar"
                                label="Amenity arabic"
                                value={values?.amenity_ar}
                                name="amenity_ar"
                                maxLength="70"
                                errorsField={errors?.amenity_ar}
                                touched={touched?.amenity_ar}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                className={"w-100 form-control"}
                                variant="outlined"
                              />
                            </div>

                          </Col>
                        </Row>
                        <Row className="gx-xl-5">
                          <Col sm={6} md={6} lg={6} className="mb-4 pb-1">
                            <Switch
                              id="amenity_status"
                              checked={values?.amenity_status}
                              checkedChildren={values?.amenity_status}
                              unCheckedChildren={values?.amenity_status}
                              className='fw-bold'
                              onChange={(value) => { handleAmenityStatus(value, setFieldValue) }}
                            />
                          </Col>
                        </Row>

                        <Stack direction="horizontal" gap={3} className="my-3">
                          <Button
                            variant="primary"
                            onClick={handleSubmit}
                            className="rounded-xs fs-15"
                            type="submit"
                            disabled={isSubmitting ?? false}
                          >Update</Button>
                          <Button variant="outline-secondary"
                            onClick={() => { navigate(-1) }}
                            className="rounded-xs fs-15">Cancel</Button>
                        </Stack>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </Card>}
      </div>
    </div>
  )
}

export default Edit
