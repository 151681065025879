import React, { useEffect, useState } from 'react'
import { Form, Formik } from 'formik'
import { Button, Card, Col, Row, Stack } from 'react-bootstrap'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { FaUserEdit } from "react-icons/fa";
import InputText from '../../../components/common/InputText'
import { updateWebsiteUsersAPI, getWebsiteUsersById } from '../../../services/userService'
import { toast } from 'react-toastify'
import { updateAmenitySchema } from '../../../validations/ValidationSchema'
import { Switch } from 'antd';
import Loader from "../../../components/common/Loader";
import { useSelector } from 'react-redux';

function Edit() {

  // MANAGING USER PERMISSIONS HERE

  const roleDetails = useSelector((state) => state?.rolesData?.masterPermissionResponseDTOList)

  const [isAuthorized, setIsAuthorized] = useState(false)

  useEffect(() => {

    if (roleDetails) {
      const permissions = roleDetails.find((detail) => {
        return detail.childPermissions.find((childPerm) => (
          childPerm?.key === "user_edit" && childPerm?.status === true
        ))
      })
      if (permissions) {
        setIsAuthorized(true)
      } else {
        setIsAuthorized(false)
      }
    } else {
      setIsAuthorized(true)
    }

  }, [roleDetails])

  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)

  const [initialValues, setInitialValues] = useState({
    email: "",
    firstName: "",
    lastName: "",
    imageUrl: "",
    mobileNumber: "",
    address: ""
  })

  const { id } = useParams()

  // FORM SUBMIT FOR CREATE PLATFORM USER
  const handleSubmit = (values, actions) => {
    const formData = {
      id: id,
      title: { en: values?.amenity_en, ar: values?.amenity_ar },
      status: values?.amenity_status
    }
    // CALLING API FOR CREATE PLATFORM USER
    updateWebsiteUsersAPI(formData).then((response) => {
      navigate(-1)
      toast.success(response?.data?.message)
    }).catch((error) => {
       if(error?.status == 500){
                toast.error('Unauthorized or server issue. Please refresh or try later. ')
            }else{
                 toast.error(
          error?.error_description
            ? error?.error_description
            : error?.details ? error?.details : error?.message
        );
            }
    })
  }


  // FETCH DATA
  const getUserData = () => {
    setLoading(true)
    console.log(id)
    // GETTING DATA FROM API
    let params = {
      'userId': id
    }
    getWebsiteUsersById(params).then(response => {
      setInitialValues({
        email: response?.data?.email,
        firstName: response?.data?.firstName,
        lastName: response?.data?.lastName,
        imageUrl: response?.data?.imageUrl,
        mobileNumber: response?.data?.mobileNumber,
        address: response?.data?.address
      })
      setLoading(false)
    }).catch((error) => {
       if(error?.status == 500){
                toast.error('Unauthorized or server issue. Please refresh or try later. ')
            }else{
                 toast.error(
          error?.error_description
            ? error?.error_description
            : error?.details ? error?.details : error?.message
        );
            }
    }).finally(() => {
      setLoading(false)

    })
  }

  // IF USER IS AUTHORIZED THEN ONLY GET USER DATA
  useEffect(() => {
    if (isAuthorized) {
      getUserData()
    } else {
      setLoading(false)
    }
  }, [isAuthorized])

  return (
    <>
      {loading ? <Loader /> :
        <div className="d-flex flex-column pageContainer p-3 h-100 overflow-auto">
          <div className="flex-grow-1 pageContent position-relative pt-4">
            {/* {JSON.stringify(data)} */}
            <Card className="h-100 bg-white shadow border-0 theme-card-cover">
              <div className="theme-card-header px-1">
                <Stack direction="horizontal" gap={2} className="flex-wrap justify-content-between px-3 pt-3 pb-2 align-items-start">
                  <h5 className="mb-0 position-relative fw-medium">
                    <div className="align-items-center bg-primary d-inline-flex h-60 justify-content-center  position-absolute rounded start-0 text-white theme-icon-box w-60 z-1">
                      <span className="theme-icon-box-inner"><FaUserEdit size={36} /></span>
                    </div>
                    User Detail
                  </h5>
                </Stack>
              </div>
              <div className="flex-grow-1 d-flex flex-column px-1">
                <div className="p-3 d-flex flex-column h-100">
                  <Formik
                    enableReinitialize={true}
                    validationSchema={updateAmenitySchema}
                    initialValues={initialValues}
                    onSubmit={(values, actions) => {
                      actions.setSubmitting(false)
                      handleSubmit(values, actions)
                    }}
                  >
                    {({
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      values,
                      setFieldValue,
                      touched,
                      isValid,
                      errors
                    }) => (
                      <Form className="d-flex flex-column flex-grow-1 theme-from">
                        <Row className="gx-xl-5">
                          <Col sm={6} md={6} lg={6} className="mb-4 pb-1">
                            <InputText
                              type="text"
                              controlId="firstName"
                              label="Firs tName"
                              value={values?.firstName}
                              name="firstName"
                              errorsField={errors?.amenity_en}
                              touched={touched?.amenity_en}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              className={"w-100 form-control"}
                              variant="outlined"
                            />
                          </Col>
                          <Col sm={6} md={6} lg={6} className="mb-4 pb-1">
                            <InputText
                              type="text"
                              controlId="lastName"
                              label="Last Name"
                              value={values?.lastName}
                              name="lastName"
                              errorsField={errors?.amenity_en}
                              touched={touched?.amenity_en}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              className={"w-100 form-control"}
                              variant="outlined"
                            />

                          </Col>
                        </Row>
                        <Row className="gx-xl-5">
                          <Col sm={6} md={6} lg={6} className="mb-4 pb-1">
                            <InputText
                              type="email"
                              controlId="email"
                              label="Email"
                              value={values?.email}
                              name="email"
                              errorsField={errors?.amenity_en}
                              touched={touched?.amenity_en}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              className={"w-100 form-control"}
                              variant="outlined"
                            />
                          </Col>
                          <Col sm={6} md={6} lg={6} className="mb-4 pb-1">
                            <InputText
                              type="text"
                              controlId="mobileNumber"
                              label="Mobile"
                              value={values?.mobileNumber}
                              name="mobileNumber"
                              errorsField={errors?.amenity_en}
                              touched={touched?.amenity_en}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              className={"w-100 form-control"}
                              variant="outlined"
                            />
                          </Col>
                          <Col sm={12} md={12} lg={12} className="mb-4 pb-1">
                            <InputText
                              type="text"
                              controlId="address"
                              label="Address"
                              value={values?.address}
                              name="address"
                              errorsField={errors?.amenity_en}
                              touched={touched?.amenity_en}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              className={"w-100 form-control"}
                              variant="outlined"
                            />
                          </Col>
                        </Row>
                        <Stack direction="horizontal" gap={3} className="my-3">
                          {/* <Button
                          variant="primary"
                          onClick={handleSubmit}
                          className="rounded-xs fs-15"
                          type="submit"
                        >Update</Button> */}
                          <Link to="/app/web-users" className="btn btn-outline-secondary rounded-xs fs-15">Cancel</Link>
                        </Stack>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </Card>
          </div>

        </div>
      }
    </>
  )
}

export default Edit
