import React from "react";
import BuildAdd from "./Add";
import { Route, Routes } from "react-router-dom";

const BuildUpload = () => {
  return <Routes>
    <Route path="/" element={<BuildAdd />} />
  </Routes>;
};

export default BuildUpload;
