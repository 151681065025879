import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Listing from './List/Listing'
import Edit from './edit/Edit'
import View from './View/View'

export const PlatformUsers =()=> {
    return (
        <Routes>
            <Route path="/" element={<Listing />} />
            <Route path="/view/:id" element={<View/>}/>
            <Route path="/edit/:id" element={<Edit/>}/>
        </Routes>
    )
}


export const nameBasedRoutes = {
    users: {
        name: "Web Users",
        path: `/app/platform-users`,
    },
    viewUsers: {
        name: "View Users",
        path: `/app/platform-users/view`
    },
    editUsers: {
        name: "Edit Users",
        path: `/app/platform-users/edit`
    }
}

