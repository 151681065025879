import { COMPLAINT_LIST, GENERAL_INFO_API } from "../constants/paths"
import { axios } from "./axios"

// GET COMPLAINT LIST 
export const complaintListApi = async(params)=>{
    const response = await axios.get(COMPLAINT_LIST,{params})
    return response
}

// GENERAL INFO API
export const generalInfoApi = async()=>{
    const response = await axios.get(GENERAL_INFO_API)
    return response?.data
}