import { ADD_SYSTEM_USER, EDIT_SYSTEM_USER, GET_SYSTEM_USER_LIST, GET_SYSTEM_USER_BY_ID, GET_ROLE_LIST, UPDATE_SYSTEM_USER_STATUS,CHANGE_PASSWORD_SYSTEM_USER } from "../constants/paths";
import { axios } from "./axios";

// PLATFORM USER SERVICES
export const addSystemUser = async (data) => {
    const response = await axios.post(ADD_SYSTEM_USER, data);
    return response?.data
}
// GET ROLES LIST
export const getRoleListData = async () => {
    const response = await axios.get(GET_ROLE_LIST);
    return response?.data
}
// GET PLATFORM USERS LIST
export const getSystemUsersList = async (params) => {
    const response = await axios.get(GET_SYSTEM_USER_LIST, { params });
    return response
}
// GET PLATFORM USER DATA BY ID
export const getSystemUserById = async (id) => {
    const response = await axios.get(`${GET_SYSTEM_USER_BY_ID}/${id}`);
    return response?.data
}
// API FOR EDIT PLATFORM USER
export const editSystemUser = async (data) => {
    const response = await axios.put(EDIT_SYSTEM_USER, data);
    return response?.data
}
// UPDATE PLATFORM USER STATUS
export const updateSystemUserStatus = async (id, status) => {
    const response = await axios.get(`${UPDATE_SYSTEM_USER_STATUS}/${id}?status=${status}`);
    return response?.data
}
// CHANGE PASSWORD PLATFORM USER IN EDIT FORM
export const changePasswordPlatformUser = async (data) => {
    const response = await axios.put(`${CHANGE_PASSWORD_SYSTEM_USER}`,data);
    return response?.data
}