import { Route, Routes } from 'react-router-dom';
import { AllList } from './List/List';

import  Edit from './Edit/Edit';


export const Village = () => {
    return (  
        <Routes>
            <Route path="/" element={<AllList />} />
            <Route path="/edit/:id" element={<Edit />} />
        </Routes>
    )
};

export const nameBasedRoutes = {
    village: {
        name: "Village",
        path: `/app/village`,
    },
    editVillage: {
        name: "EditVillage",
        path: `/app/village/edit`
    }
}