import React, { useEffect, useState } from "react";
import { Card, Stack } from "react-bootstrap";
import { MdSupervisorAccount } from "react-icons/md";
import { useLocation } from "react-router-dom";
import { ActionCell } from "../../../components/common/dataGrid/ActionCell";
import { getRolesList } from "../../../services/permissionService";
import { toast } from "react-toastify";
import LoaderMu from "../../../components/common/LoaderMu";
import { MatDataGridWithPagination } from "../../../components/common/dataGrid/DataGridWithPagination";

function RolesList() {

  
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [roleListdata, setRoleListdata] = useState([]);
  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [totalResults, setTotalResults] = useState(0);
  const [sortingData, setSortingData] = useState({});

  // THIS IS FOR PAGINATION IF REQUIRED ON THIS PAGE
  const [selectedValue, setSelectedValue] = useState(
    location?.state?.searchedValue ? location?.state?.searchedValue : ""
  );
  const [page] = useState(
    location?.state?.pageNumber ? location?.state?.pageNumber : "1"
  );
  // ON VALUE CHANGE IN SEARCH INPUT
  const handleValueChange = (value) => {
    setSelectedValue(value);
  };

  // THIS IS FOR PAGE CHANGE
  const handlePageChange = (value) => {
    setCurrentPage(value?.page)
    setPageSize(value?.pageSize)
  }

  // HANDLE SORT MODAL CHANGE
  const handleSortModelChange = (data) => {
    setSortingData(data?.length > 0 ? data[0] : {})
  }

  //  Get ROLES LIST DATA FROM API
  const fetchRolesList = () => {
    setLoading(true)
    const params = {
      page: currentPage ?? 0,
      size: pageSize ?? 10
    }
    if (sortingData && Object.keys(sortingData).length !== 0) {
      params["sort"] = `${sortingData?.field},${sortingData?.sort?.toUpperCase()}`
    }
    // CALLING API SERVICE
    getRolesList({ params }).then((response) => {
      // SET DATA IN ROLES DATA STATE
      setRoleListdata(response?.data);
      setTotalResults(response?.headers["x-total-count"])
      setLoading(false);
    }).catch((error) => {
      setLoading(false)
       if(error?.status == 500){
                toast.error('Unauthorized or server issue. Please refresh or try later. ')
            }else{
                 toast.error(
          error?.error_description
            ? error?.error_description
            : error?.details ? error?.details : error?.message
        );
            }
    });
  };
  //CALLING FETCH USER DATA
  useEffect(() => {
    fetchRolesList();
  }, []);
  // COLUMNS ARRAY FOR DATA TABLE
  const columns = [
    {
      field: "id",
      headerName: "S. No.",
      editable: false,
      flex: 0.05,
      minWidth: 100,
      sortable: false,
      renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1 + ((currentPage * pageSize))
    },
    {
      field: "title",
      headerName: "Title",
      editable: false,
      flex: 0.6,
      minWidth: 200,
      sortable : true
    },
    {
      field: "description",
      headerName: "Description",
      editable: false,
      sortable : true,
      flex: 0.6,
      minWidth: 300,
    },
    {
      field: "roleType",
      headerName: "Role Type",
      editable: false,
      sortable : false,
      flex: 0.6,
      minWidth: 150,
      maxWidth: 150,
    },
    {
      field: "action",
      headerName: "Action",
      editable: false,
      flex: 0.2,
      minWidth: 120,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (props) => {
        return (
          <ActionCell
            controlId="app/roles"
            isViewEnable={false}
            isEditEnable={true}
            redirectID={props.row.id}
            rowData={props.row}
            pageNumber={page}
            searchedValue={selectedValue}
          />
        );
      },
      // renderCell: (props) => { return <ActionCell controlId='app/artical' isViewEnable={true} isEditEnable={false} isDeleteEnable={true}  deleteFunctionName={deleteRow} isLogInEnable={true} redirectID={props.row.id} rowData={props.row} pageNumber={page} searchedValue={selectedValue}  /> }
    },
  ];
  return (
    <div className="d-flex flex-column pageContainer p-3 h-100">
      <LoaderMu loading={loading} />
      <div className="flex-grow-1 h-100 pageContent position-relative pt-4 overflow-auto">
        {/* CARD HEADER */}
        <Card className="h-100 bg-white shadow border-0 theme-card-cover">
          <div className="theme-card-header px-1">
            <Stack
              direction="horizontal"
              gap={2}
              className="flex-wrap justify-content-between px-3 pt-3 pb-2 align-items-start"
            >
              <h5 className="mb-0 position-relative fs-18 text-black fw-medium">
                <div className="align-items-center bg-primary d-inline-flex h-60 justify-content-center  position-absolute rounded start-0 text-white theme-icon-box w-60 z-1">
                  <MdSupervisorAccount size={40} />
                </div>
                Manage Role
              </h5>
            </Stack>
          </div>
          {
            <div className="flex-grow-1 mt-2 d-flex flex-column px-3 h-100 overflow-auto remove-pagination">
            
              {/* CALLING DATA TABLE COMPONENT HERE TO DISPLAY ROLES LIST DATA IN TABLE FORMAT */}
              <MatDataGridWithPagination
                controlId={"id"}
                columns={columns}
                data={roleListdata ?? []}
                rowsPerPage={10}
                onPaginationModelChange={handlePageChange}
                pageSize={pageSize}
                totalResults={totalResults}
                currentPage={currentPage}
                handleSortModelChange={handleSortModelChange}
              />
            </div>
          }
        </Card>
      </div>
    </div>
  );
}

export default RolesList;
