import React, { useState, useEffect } from 'react'
import { Button, Card, Col, Row, Stack } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaUserAlt } from "react-icons/fa";
import { Formik } from "formik";
import InputText from "../../components/common/InputText";
import { ProfileSchema } from '../../validations/ValidationSchema';
import { updateProfile } from '../../services/userService';
import { toast } from "react-toastify";
import Loader from "../../components/common/Loader";
import { useNavigate } from 'react-router-dom';
import Cookies from "universal-cookie";

export const Profile = () => {

  const cookies = new Cookies();
  cookies.set("page_heading", 'Change Password'); // page heading

  // data
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();

  const initialValues = {
    current_password: "",
    new_password: "",
    confirm_password: ""
  }

  // Submit onboarding
  const handleSubmit = async (value, actions) => {
    setLoading(true)
    const current_password = value.current_password
    const new_password = value.new_password
    const confirm_password = value.confirm_password

    updateProfile({
      currentPassword: current_password,
      password: new_password,
      user_id: '1',
    })
      .then(response => {
        setLoading(false)
        toast.success(response.message)
        //navigate("/app/dashboard");
      })
      .catch(error => {
        setLoading(false)
        toast.error(error?.response?.data?.message ?? error?.message)
      })
  }

  return (
    <div>
      {
        loading ? <Loader isLoading={loading} /> : <div className="d-flex flex-column pageContainer p-3 h-100 overflow-auto">
          <Formik
            validationSchema={ProfileSchema}
            initialValues={initialValues}
            onSubmit={(values, actions) => {
              handleSubmit(values, actions)
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              setFieldValue,
              touched,
              isValid,
              errors
            }) => (

              <div className="d-flex flex-column pageContainer p-3 h-100 overflow-auto">

                <div className="flex-grow-1 pageContent position-relative pt-4">
                  <Card className="h-100 bg-white shadow border-0 theme-card-cover">
                    <div className="theme-card-header px-1">
                      <Stack direction="horizontal" gap={2} className="flex-wrap justify-content-between px-3 pt-3 pb-2 align-items-start">
                        <h5 className="mb-0 position-relative fw-medium fs-18">
                          <div className="align-items-center bg-primary d-inline-flex h-60 justify-content-center  position-absolute rounded start-0 text-white theme-icon-box w-60 z-1">
                            <span className="theme-icon-box-inner"><FaUserAlt size={30} /></span>
                          </div>
                          Change Password
                        </h5>
                      </Stack>
                    </div>
                    <div className="flex-grow-1 d-flex flex-column px-1">
                      <div className="p-3 d-flex flex-column h-100">
                        <div className="d-flex flex-column flex-grow-1 theme-from">

                          <Row className="gx-xl-5">
                            <Col sm={6} md={6} lg={3} className="mb-4 pb-1">

                              <InputText
                                controlId="current_password"
                                label="Current Password"
                                value={values.current_password}
                                name="current_password"
                                maxLength="300"
                                errorsField={errors.current_password}
                                touched={touched.current_password}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                type='password'
                                className="w-100"
                              />
                            </Col>
                          </Row>
                          <Row className="gx-xl-5">
                            <Col sm={6} md={6} lg={3} className="mb-4 pb-1">
                              <InputText
                                controlId="new_password"
                                label="New Password"
                                value={values.new_password}
                                name="new_password"
                                maxLength="300"
                                errorsField={errors.new_password}
                                touched={touched.new_password}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                type='password'
                                className="w-100"
                              />
                            </Col>
                          </Row>
                          <Row className="gx-xl-5">
                            <Col sm={6} md={6} lg={3} className="mb-4 pb-1">
                              <InputText
                                controlId="confirm_password"
                                label="Confirm Password"
                                value={values.confirm_password}
                                name="confirm_password"
                                maxLength="300"
                                errorsField={errors.confirm_password}
                                touched={touched.confirm_password}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                type='password'
                                className="w-100"
                              />
                            </Col>
                          </Row>

                          <Stack direction="horizontal" gap={3} className="my-3">
                            <Button
                              variant="primary"
                              onClick={handleSubmit}
                              className="rounded-xs fs-15 min-w-82"

                            >Update</Button>

                            <Link to="/app/dashboard" className="btn btn-outline-secondary rounded-xs fs-15 min-w-88">Cancel</Link>
                          </Stack>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            )}
          </Formik>
        </div>
      }
    </div>
  )
}