import { CMS_LIST_API, UPDATE_CMS_STATUS,GET_CMS_DATA_API,EDIT_CMS_DATA_API } from "../constants/paths"
import {axios} from "./axios"

// FETCH CMS LIST DATA
export const cmsListApi = async(params)=>{
    const response = await axios.get(CMS_LIST_API,{params})
    return response
}

// UPDATE CMS STATUS
export const updateCmsStatusApi = async(id,status)=>{
    const response = await axios.get(`${UPDATE_CMS_STATUS}/${id}/${status}`)
    return response?.data
}

// FETCH CMS DATA

export const getCMSDataById = async (id)=>{
    const response = await axios.get(`${GET_CMS_DATA_API}/${id}`)
    return response?.data
}

// EDIT CMS DATA

export const editCMSDataApi = async (data)=>{
    const response = await axios.put(EDIT_CMS_DATA_API,data)
    return response?.data
}