
import { Modal } from 'antd';
import React from 'react'

function CommercialRegFileModal({ filePath, isFileOpen, setIsFileOpen }) {
    // FUNCTION FOR HANDLE MODAL CANCEL
    const handleCancel = () => {
        setIsFileOpen(false);
    };

    // Extract file extension
    const fileExtension = filePath?.split('.').pop().split('?')[0].toLowerCase();

    // Test if the file extension is an image
    const isImage = /(gif|jpe?g|tiff?|png|webp|bmp)$/i.test(fileExtension);

    return (
        <Modal
            title="Commercial Registration"
            open={isFileOpen}
            onCancel={handleCancel}
            footer={false}
            width={"80vw"}
            style={{
                top: 20,
            }}
        >
            <div className="iframe-wrapper h-80vh">
                {/* IF FILEPATH IS IMAGE THEN DISPLAY WITH IMG ICON OTHERWISE USE IFRAME */}
                {isImage ? (
                    <img src={filePath} alt="Dynamic Image" className='img-fluid' />
                ) : (
                    <iframe src={filePath} title="Dynamic Content" height={"100%"} width={"100%"}></iframe>
                )}
                {/* <iframe
                    src="https://stage-backend-public.fra1.digitaloceanspaces.com/agency/logo/1711507494677-5R1A0014.JPG"
                    // src={filePath}
                    sandbox="allow-same-origin allow-scripts"
                ></iframe> */}
            </div>
        </Modal>
    )
}

export default CommercialRegFileModal
