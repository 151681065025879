import { axios } from "./axios";
// AMAKEN WORKING

import {
  GET_ROLES_LIST,
  GET_ROLE_BY_ID,
  GET_USER_ROLE_DATA,
  UPDATE_ROLES_PERMISSION,
} from "../constants/paths";


export const getUserRoleDetails = async(id)=>{
  const response = await axios.get(`${GET_USER_ROLE_DATA}/${id}`);
  return response
}

// FETCH ROLES
export const getRolesList = async() => {
  const response = await axios.get(GET_ROLES_LIST);
  return response
};

export const getRoleById = async (params) => {
  const response = await axios.get(`${GET_ROLE_BY_ID}/${params?.id}`);
  return response?.data
};

export const updateRolesPermissions =async (data) => {
  const response = await axios.post(UPDATE_ROLES_PERMISSION, data);
  return response?.data
};
