import { axios } from "./axios"
import {
    UPDATE_AMENITY_API,
    GET_AMENITY_LIST_API,
    SAVE_AMENITY_API,
    UPDATE_AMENITY_STATUS_API,
} from "../constants/paths"


//  Get Amenity list api call

export const getAmenityListAPI = (params) => {
    return axios.get(`${GET_AMENITY_LIST_API}`, { params })
}

// GET AMENITY BY ID
export const getAmenityById = async (id) => {
    const response = await axios.get(`${GET_AMENITY_LIST_API}/${id}`);
    return response?.data
}

// UPDATE AMENITY API
export const updateAmenityAPI = data => {
    return axios.put(UPDATE_AMENITY_API, data)
}

// UPDATE AMENITY API STATUS
export const updateAmenityStatusAPI = data => {
    return axios.put(UPDATE_AMENITY_STATUS_API, data)
}

// SAVE AMENITY API
export const saveAmenityAPI = data => {
    return axios.post(SAVE_AMENITY_API, data)
}


