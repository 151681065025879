import { Button, Spinner, Stack } from "react-bootstrap"
import { ForgotPasswordSchema } from "../../../validations/Auth"
import { Form, Formik } from "formik"
import { AuthHeader } from "../authHeader/AuthHeader"
import { Link } from "react-router-dom"
import { useState } from "react"
import { toast } from "react-toastify"
import { forgotPassword } from "../../../services/authService"
import InputText from "../../../components/common/InputText"
import { useNavigate } from "react-router-dom"
import { nameBasedRoutes } from "../../../router/public"
import LoaderMu from "../../../components/common/LoaderMu"

export const ForgetPassword = () => {

  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const initialValues = {
    email: "",
  }

  // SUBMIT FORM
  const handleSubmit = async (email, actions) => {
    setLoading(true)
    const data = {
      email: email,
      panel: "ADMIN"
    }
    //FORGOT PASSWORD API SERVICE 
    forgotPassword(data)
      .then(response => {
        setLoading(false)
        navigate(nameBasedRoutes?.login?.path)
        toast.success(response?.message ?? "Forgot password mail sent successfully")
      })
      .catch(error => {
        setLoading(false)
        toast.error(error?.response?.data?.message)
      })
  }
  return (
    <div className="d-flex flex-column p-3 min-vh-100 w-100 theme-body-bg">
    {/* LOADER */}
      <LoaderMu loading={loading} />
      <div className="mw-450 mx-auto my-4 py-md-5 text-center w-100">
        <div className="bg-white d-inline-block mt-3 p-4 rounded-3 text-start w-100 shadow-sm">
          <div>
          {/* IMPORTING COMMON HEADER */}
            <div className="fw-normal text-center mb-3">
              <AuthHeader />
            </div>
            <h6 className="fw-normal text-center mb-3 h5 fw-bold heading">
              Forgot Password
            </h6>
            <p className="fw-normal text-center mb-3 fs-14 mw-290 m-auto lh-sm subheading">
              Enter your email address and we will send you a link to reset your password.
            </p>
            {/* FORGOT PASSWORD FORM */}
            <Formik
              validationSchema={ForgotPasswordSchema}
              initialValues={initialValues}
              onSubmit={(values, actions) => {
                actions.setSubmitting(false)
                handleSubmit(values.email, actions)
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors
              }) => (
                <Form>
                {/* EMAIL FIELD */}
                  <InputText
                    controlId="email"
                    label="Email Address *"
                    type="text"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    errorsField={errors.email}
                    touched={touched.email}
                    className="w-100 fs-16 mb-4 custom-text-field"
                    InputLabelProps={{
                      className: "fs-15 grey-color"
                    }}
                    value={values.email}
                    name="email"
                  />
                  <Stack gap={4} className="justify-content-center">
                    {loading ? <Button
                      className="fw-semibold fs-18 text-white min-h-48 mt-2 w-100"
                      variant="primary"
                      type="submit"
                      disabled={true}
                    >
                      <Spinner size="sm" />
                    </Button> : <Button
                      className="fw-semibold fs-18 text-white min-h-48 mt-2 w-100"
                      variant="primary"
                      type="submit"
                      onClick={handleSubmit}
                    >
                      Send
                    </Button>}
                    <Link
                      className="fs-14 text-decoration-none fw-bold d-flex justify-content-center"
                      to={nameBasedRoutes.login.path}
                    >
                      Back to Login
                    </Link>
                  </Stack>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  )
}
