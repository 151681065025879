import React from 'react'
import { Route, Routes } from 'react-router-dom'
import AddFaq from './Add'
import FaqList from './List'
import EditFaq from './Edit'


export const Faq =()=> {
  return (
    <Routes>
      <Route path="/" element={<FaqList/>}/>
      <Route path="/add" element ={<AddFaq/>}/>
      <Route path="/edit/:id" element ={<EditFaq/>}/>
    </Routes>
  )
}


export const nameBasedRoutes = {
  faq: {
      name: "Faq",
      path: `/app/faq`,
  },
  editFaq: {
      name: "EditFaq",
      path: `/app/faq/edit`
  }
}
