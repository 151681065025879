export const Footer = () => {
  const getCurrentYear = new Date().getFullYear()
  return (
    <footer className="bg-white fs-14 mt-auto px-3 py-2 d-none">
      <div className="py-1">
        Copyright &copy; {getCurrentYear}. All Rights Reserved
      </div>
    </footer>
  )
}
