import { Card } from "react-bootstrap";

import MainStats from "../../components/dashboard/MainStats";
import PropertiesChart from "./PropertiesChart";
import UsersChart from "./UsersChart";
import PropertyTypesChart from "./PropertyTypesChart";
import UsersCountChart from "./UsersCountChart";
import moment from 'moment/moment';
import dayjs from 'dayjs';
import { DatePicker } from 'antd';
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import NotAuthorized from "../../components/common/NotAuthorized";
const { RangePicker } = DatePicker;
export const Dashboard = () => {


  // MANAGING USER PERMISSIONS HERE
  // GET ROLE DETAILS
  const roleDetails = useSelector((state) => state?.rolesData?.masterPermissionResponseDTOList)
  // CHECK IF USER IS ADMIN OR NOT
  const isAdmin = useSelector((state) => state?.adminData?.authorities?.includes('ROLE_ADMIN'))

  const [isAuthorized, setIsAuthorized] = useState(false) // STATE FOR AUTH STATUS
  const [authPermissions, setAuthPermissions] = useState(null) // ARRAY FOR SET CHILD PERMISSIONS HERE
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (roleDetails) {
      const permissions = roleDetails?.find((detail) => {
        return detail?.parentPermissions?.key === "dashboard" && detail?.childPermissions?.some((childPerm) => (
          childPerm?.status === true
        ));
      });
      if (permissions) {
        setIsAuthorized(true)
        // Create an object from childPermissions
        const permissionsObject = {};
        permissions?.childPermissions?.forEach(permission => {
          permissionsObject[permission?.key] = permission?.status;
        });
        setAuthPermissions(permissionsObject)
      } else {
        setIsAuthorized(false)
        setLoading(false)
      }
      setLoading(false)
    } else {
      setIsAuthorized(true)
      setLoading(false)
    }
  }, [roleDetails])

  // SELECTED DATE FILTER
  const [selectedFilter, setSelectedFilter] = useState("this_year")

  const dateFormat = "YYYY-MM-DD";

  // First date of the year
  const firstDateOfYear = moment().startOf('year').format(dateFormat);

  // END DATE OF THE FILTER (TODAY)
  const lastDate = moment().format(dateFormat);

  // STATES FOR START AND END DATES
  const [startDate, setStartDate] = useState(firstDateOfYear)
  const [endDate, setEndDate] = useState(lastDate)


  // FOR SET DATE ACCORDING TO FILTER
  const handleDateSelection = (filter) => {
    setSelectedFilter(filter)
    const today = moment();

    switch (filter) {
      case 'this_year': {
        const startDate = moment().startOf('year').format("YYYY-MM-DD");
        const endDate = moment().format("YYYY-MM-DD");
        setStartDate(startDate);
        setEndDate(endDate);
        break;
      }
      case 'last_year': {
        const startDate = moment().subtract(1, 'year').startOf('year').format("YYYY-MM-DD");
        const endDate = moment().subtract(1, 'year').endOf('year').format("YYYY-MM-DD");
        setStartDate(startDate);
        setEndDate(endDate);
        break;
      }
      case 'last_week': {
        const previousSunday = moment().subtract(1, 'weeks').startOf('week');
        const previousSaturday = moment().subtract(1, 'weeks').endOf('week');
        setStartDate(previousSunday.format("YYYY-MM-DD"));
        setEndDate(previousSaturday.format("YYYY-MM-DD"));
        break;
      }
      case 'last_month': {
        const previousMonthStart = moment().subtract(1, 'months').startOf('month');
        const previousMonthEnd = moment().subtract(1, 'months').endOf('month');
        setStartDate(previousMonthStart.format("YYYY-MM-DD"));
        setEndDate(previousMonthEnd.format("YYYY-MM-DD"));
        break;
      }
      default:
        break;
    }
  }
  // HANDLE DATE RANGE SELECT AND CHECK DATE MUST NOT GREATER THAN TODAY'S DATE
  const handleDateRangeSelect = (date, dateString) => {
    setSelectedFilter('custom')
    if (dateString && dateString.length > 1 && dateString[0] !== '' && dateString[1] !== '') {
      const startDate = dateString[0];
      let endDate = dateString[1];
      const today = new Date();
      const end = new Date(endDate);

      // END DATE MUST NOT GREATER THAN TODAY'S DATE 
      if (end > today) {
        toast.warning("Dates greater than today are not permitted.") // Set end date to today's date
      } else {
        setStartDate(startDate);
        setEndDate(endDate);
      }
    }
  };
  return (
    <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
      <div className="flex-grow-1 overflow-auto pageContent">
        {
          !isAuthorized && !loading ? <NotAuthorized /> :
            <Card className="h-100 p-3 w-100 pageContentInner bg-transparent border-0">
              <div className="row mb-2">
                <div className="col-12 text-md-end">
                  {/* DATE RANGE PICKER FROM ANTD */}
                  <RangePicker
                    className={`${selectedFilter === 'custom' ? 'active' : ''} antd-rangePicker me-2`}
                    value={[dayjs(startDate, dateFormat), dayjs(endDate, dateFormat)]}
                    format={dateFormat}
                    onChange={handleDateRangeSelect}
                    footer={true}
                    allowClear={false}
                    maxDate={dayjs(new Date())}
                  />
                  <button className={`btn ${selectedFilter === "this_year" ? "btn-theme" : "btn-theme-outline"} me-2 my-2 fw-bold`} onClick={() => handleDateSelection('this_year')}>This Year</button>
                  <button className={`btn ${selectedFilter === "last_year" ? "btn-theme" : "btn-theme-outline"} me-2 my-2 fw-bold`} onClick={() => handleDateSelection('last_year')}>Last Year</button>
                  <button className={`btn ${selectedFilter === "last_week" ? "btn-theme" : "btn-theme-outline"} me-2 my-2 fw-bold`} onClick={() => handleDateSelection('last_week')}>Last Week</button>
                  <button className={`btn ${selectedFilter === "last_month" ? "btn-theme" : "btn-theme-outline"} my-2 	fw-bold`} onClick={() => handleDateSelection('last_month')}>Last Month</button>
                </div>
              </div>

              {/* MAIN STATS DASHBOARD SHOW ONLY IF EITHER USER IS ADMIN OR HAVE PERMISSION*/}

              {
                isAdmin || authPermissions?.dashboard_stats_data === true ? <MainStats startDate={startDate ?? firstDateOfYear} endDate={endDate ?? lastDate} /> : ""
              }

              {/* PROPERTY TYPES CHART FOR AUTHORIZED USER */}
              <div className="row ">
                {
                  isAdmin || authPermissions?.dashboard_property_type === true ? <div className="col-xl-6 my-2">
                    <PropertyTypesChart startDate={startDate ?? firstDateOfYear} endDate={endDate ?? lastDate} />
                  </div> : ""

                }
                {/* USER COUNT CHART CHART FOR AUTHORIZED USER */}
                {
                  isAdmin || authPermissions?.dashboard_users === true ? <div className="col-xl-6 my-2">
                    <UsersCountChart startDate={startDate ?? firstDateOfYear} endDate={endDate ?? lastDate} />
                  </div> : ""

                }
                {/* PROPERTIES BY YEAR CHART FOR AUTHORIZED USER */}
                {
                  isAdmin || authPermissions?.dashboard_properties_by_year === true ? <div className="col-xl-6 my-2">
                    <PropertiesChart />
                  </div> : ""

                }
                {/* USERS BY YEAR CHART FOR AUTHORIZED USER */}
                {
                  isAdmin || authPermissions?.dashboard_users_by_year === true ? <div className="col-xl-6 my-2">
                    <UsersChart />
                  </div> : ""
                }
              </div>
            </Card>
        }
      </div>
    </div>
  );
};
