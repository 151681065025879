import { axios } from "./axios"
import{
    GET_GOVERNORATE_LIST_API,
    EDIT_GOVERNORATE_API,
} from "../constants/paths" 


/******
 * Get governorate list api call
 * @param  data
 * @returns {Promise<any>}
 */
export const getGovernorateListAPI = (params)=> {
    return axios.get(`${GET_GOVERNORATE_LIST_API}`,{params})  
}

// GET Directorate BY ID
export const getGovernorateById = async (id) => {
    const response = await axios.get(`${GET_GOVERNORATE_LIST_API}/${id}`);
    return response?.data
}

// update directorate
export const updateGovernorateAPI = data => {
    return axios.put(EDIT_GOVERNORATE_API, data)  
}

