import React, { useEffect } from "react";
import { Formik } from "formik";
import { Button, Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Link, useNavigate } from "react-router-dom";
import { AuthHeader } from "../authHeader/AuthHeader";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Cookies from "universal-cookie";

import { useState } from "react";
import Loader from "../../../components/common/Loader";
import storage from "../../../helpers/storage";
import { toast } from "react-toastify";
import { login } from "../../../services/authService";
import InputText from "../../../components/common/InputText";
import { nameBasedRoutes } from "../../../router/public";
import { nameBasedProtectedRoutes } from "../../../router/protected";
import { getAdminInfo } from "../../../services/userService";
import { setAdmin } from "../../../redux/adminSlices";
import { useDispatch } from "react-redux";
import LoaderMu from "../../../components/common/LoaderMu";
import { LoginSchema } from "../../../validations/Auth";
import { getUserRoleDetails } from "../../../services/permissionService";


export const Login = () => {
  const cookies = new Cookies();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // REMEMBER ME STATES
  const rememberMeEmail = cookies.get("admin_email");
  const rememberMePassword = cookies.get("admin_password");
  const alreadyChecked = cookies.get("admin_checked");
  const [rememberMe, setRemembeMe] = useState(!!alreadyChecked);

  const [loading, setLoading] = useState(false);

  // STATE FOR PASSWORD VISIBLE TOGGLE
  const [showEye, setShowEye] = useState(false);

  // FORM INITIAL VALUES
  const initialValues = {
    email: rememberMeEmail ? rememberMeEmail : "",
    password: rememberMePassword ? rememberMePassword : "",
    captcha: "",
    rememberCheck: false,
  };


  // FOR REMEMBER DETAILS
  useEffect(() => {
    if (rememberMeEmail && rememberMePassword) {
      setTimeout(() => { }, 100);
      setLoading(true);
    }
    setLoading(false);
  }, []);

  // FORM SUBMIT
  const handleSubmit = async (email, password) => {

    setLoading(true);

    // IF USER NOT MARKED REMEMBER ME
    if (rememberMe === false) {
      cookies.remove("admin_email");
      cookies.remove("admin_password");
      cookies.remove("admin_checked");
    }
    // FORM DATA FOR API
    const data = {
      username: email,
      password: password,
      panel: "ADMIN",
      rememberMe: rememberMe,
      deviceType: "BROWSER",
    };
    //CALLING LOGIN API SERVICE
    login(data)
      .then((response) => {

        setLoading(false);
        // IF USER MARKED REMEMBER ME TRUE ON LOGIN THEN SET DATA IN COOKIES
        if (rememberMe === true) {
          cookies.set("admin_email", data.username);
          cookies.set("admin_password", password);
          cookies.set("admin_checked", rememberMe);
        }
        // SET USER TOKEN IN LOCAL STORAGE
        storage.setToken(response?.id_token);
        storage.setRefreshToken(response?.id_token);
        storage.setData("refresh-token", response?.id_token);
        // API SERVICE FOR LOGGED IN USER DATA
        getAdminInfo()
          .then((adminData) => {
            setLoading(false);
            // UPDATE REDUX STATE FOR ADMIN DATA
            if (adminData?.data?.authorities?.includes("ROLE_SUB_ADMIN") && adminData?.data?.roles[0]?.id) {
              getUserRoleDetails(adminData?.data?.roles[0]?.id).then(roleData => {
                dispatch(
                  setAdmin({ adminData: adminData?.data, rolesData: roleData?.data, token: response?.id_token })
                );
              }).catch((error) => {
                setLoading(false);
                toast.error(
                  error?.error_description
                  ? error?.error_description
                  : error?.message
                );
              })
            } else {
              dispatch(
                setAdmin({ adminData: adminData?.data, rolesData: null, token: response?.id_token })
              );
            }

            // NAVIGATE TO HOME PAGE
            navigate(nameBasedProtectedRoutes?.userDashboard?.path);
          })
          // IF ANY ERROR OCURRED IN GET USER DATA API SERVICE
          .catch((error) => {
            setLoading(false);
            toast.error(
              error?.message
            );
          });
      }) // IF ANY ERROR OCURRED IN LOGIN API SERVICE
      .catch((error) => {
        setLoading(false);
        toast.error(
          error?.error_description
            ? error?.error_description
            : error?.details ? error?.details : error?.message
        );
      });
  };
  // TOGGLE EYE BUTTON FOR PASSWORD
  const showToggle = (event) => {
    setShowEye(event);
  };
  return (
    <div>
      {/* LOADER */}
      <LoaderMu loading={loading} />
      <div className="d-flex flex-column p-3 min-vh-100 w-100 theme-body-bg">
        <div className="mw-450 mx-auto my-4 py-md-5 text-center w-100">
          <Formik
            validationSchema={LoginSchema}
            initialValues={initialValues}
            onSubmit={(values, actions) => {
              actions.setSubmitting(false);
              handleSubmit(values?.email, values?.password);
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
            }) => (
              <form className="bg-white d-inline-block mt-3 p-4 rounded-3 text-start w-100 shadow-sm">
                <div className="fw-normal text-center mb-3">
                  <AuthHeader />
                </div>
                {/* EMAIL INPUT FIELD */}
                <InputText
                  autoFocus={true}
                  controlId="email"
                  label="Email Address *"
                  type="text"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  errorsField={errors.email}
                  touched={touched.email}
                  className="w-100 fs-16 mb-4 custom-text-field"
                  InputLabelProps={{
                    className: "fs-15 grey-color",
                  }}
                  value={values.email}
                  name="email"
                />
                <div
                  className={`position-relative ${showEye ? "form-right-icon" : ""
                    }`}
                >
                  {/* PASSWORD INPUT FIELD */}
                  <InputText
                    controlId="password"
                    label="Password *"
                    type={showEye ? "text" : "password"}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    errorsField={errors.password}
                    touched={touched.password}
                    value={values.password}
                    name="password"
                    multiline={false}
                    className="w-100 fs-16 custom-text-field"
                    InputLabelProps={{
                      className: "fs-15 grey-color",
                    }}
                  />
                  {/* PASSWORD EYE TOGGLE BUTTON */}
                  <div onClick={() => setShowEye(!showEye)}>
                    {showEye ? (
                      <FaEye
                        size="22"
                        className="eyePosition"
                        onClick={() => showToggle(true)}
                      />
                    ) : (
                      <FaEyeSlash
                        size="22"
                        className="eyePosition"
                        onClick={() => showToggle(true)}
                      />
                    )}
                  </div>
                </div>
                <Form.Group className="mt-4 pt-2 mb-3">
                  <Row
                    xs="auto"
                    className="align-items-center justify-content-between gx-0"
                  >
                    {/* REMEMBER ME CHECK BUTTON */}
                    <Col>
                      <Form.Check
                        id="rememberCheck"
                        className="fs-14 lh-lg mh-auto mb-0 user-select-none customCheck"
                        type="checkbox"
                        label="Remember me"
                        checked={rememberMe}
                        onChange={(e) => setRemembeMe(e.target.checked)}
                      //checked={isChecked}
                      //onChange={onChangeCheckbox}
                      />
                    </Col>
                    <Col>
                      {/* LINK FOR NAVIGATE TO FORGOT PASSWORD */}
                      <Link
                        className="fs-14 text-decoration-none fw-bold"
                        to={nameBasedRoutes.forgotPassword.path}
                      >
                        Forgot Password?
                      </Link>
                    </Col>
                  </Row>
                </Form.Group>

                {/* BUTTON FOR FORM SUBMIT */}
                <Button
                  className="fw-semibold fs-18 text-white min-h-48 mt-2 w-100"
                  variant="primary"
                  onClick={handleSubmit}
                  type="submit"
                >
                  Login
                </Button>
              </form>
            )}</Formik>
        </div>
      </div>
    </div>
  );
};
